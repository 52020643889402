import { useGetAnalyticsQuery } from 'app/features/whatsappStats'
import { toast } from 'components/ui/use-toast'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { WhatsappStatsProps } from '../index'
import Loader from 'components/shared/loader'

export const WhatsappAnalytics = ({
  startDate,
  endDate,
  whatsappAccount,
  setLoading,
}: WhatsappStatsProps) => {
  const [whatsAppAnalyticsGranularity, setWhatsAppAnalyticsGranularity] =
    useState('DAY')
  const [
    whatsAppAnalyticsGranularityItems,
    setWhatsAppAnalyticsGranularityItems,
  ] = useState([
    {
      value: 'HALF_HOUR',
      label: 'Half Hour',
    },
    {
      value: 'DAY',
      label: 'Day',
    },
    {
      value: 'MONTH',
      label: 'Month',
    },
  ])

  /**
   * Determines whether the analytics query should be skipped based on the
   * selected granularity and the time difference between the start and end dates.
   *
   * @returns true if the time gap between startDate and endDate
   * is less than the defined granularity duration, indicating that the query should be skipped.
   * Otherwise, returns false.
   *
   * The function checks the granularity setting (HALF_HOUR, DAY, MONTH) and calculates
   * the time difference accordingly. If no granularity is set, it defaults to not skipping the query.
   */
  const shouldSkipAnalyticsQuery = useMemo(() => {
    if (!whatsAppAnalyticsGranularity) return false

    const granularitySecondsItems = {
      HALF_HOUR: 1800,
      DAY: 86400,
      MONTH: 2592000,
    }

    const granularitySeconds =
      granularitySecondsItems[
        whatsAppAnalyticsGranularity as keyof typeof granularitySecondsItems
      ]

    const start = dayjs(startDate || dayjs().subtract(1, 'month').toISOString())

    const end = dayjs(endDate || dayjs().toISOString())

    if (whatsAppAnalyticsGranularity === 'HALF_HOUR') {
      const gap = end.diff(start, 'second')

      return gap < granularitySeconds
    } else if (whatsAppAnalyticsGranularity === 'DAY') {
      const gap = end.diff(start, 'second')

      return gap < granularitySeconds
    } else if (whatsAppAnalyticsGranularity === 'MONTH') {
      const gap = end.diff(start, 'second')

      return gap < granularitySeconds
    }
    return false
  }, [startDate, endDate, whatsAppAnalyticsGranularity])

  const { refetch, isLoading, isFetching, data, isError } =
    useGetAnalyticsQuery(
      {
        whatsappAccount: whatsappAccount,
        ...(startDate && {
          from: dayjs(startDate).toISOString(),
        }),
        ...(endDate && {
          to: dayjs(endDate).toISOString(),
        }),
        granularity: whatsAppAnalyticsGranularity,
      },
      {
        skip: shouldSkipAnalyticsQuery,
      }
    )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching analytics',
      })
    }
  }, [isError])

  const formatXAxisAnalytics = (tick: any) => {
    return dayjs(tick).format('DD')
  }

  /**
   * Custom tooltip for WhatsApp analytics chart.
   *
   * @param {Object} param
   * @param {boolean} param.active - Whether the tooltip is active.
   * @param {Array} param.payload - Data payload for the tooltip.
   * @param {string} param.label - Label for the tooltip.
   *
   * @returns {ReactNode} - JSX for the tooltip.
   */
  const customAnalyticsTooltip = ({ active, payload, label }: any) => {
    const g = whatsAppAnalyticsGranularity
    const addedPeriod = g == 'HALF_HOUR' ? 30 : g == 'DAY' ? 1 : 30
    const addedType = g == 'HALF_HOUR' ? 'minute' : g == 'DAY' ? 'day' : 'day'

    if (active && payload && payload.length) {
      return (
        <div className="card bg-white dark:bg-gray-900 p-2 shadow">
          <p>
            {dayjs(label).format('YYYY/MM/DD HH:mm')}
            &nbsp;to&nbsp;
            {dayjs(label).add(addedPeriod, addedType).format('MM/DD HH:mm')}
          </p>
          <p style={{ color: '#8884d8' }}>Sent messages: {payload[0].value}</p>
          <p style={{ color: '#82ca9d' }}>
            Delivered messages: {payload[1].value}
          </p>
        </div>
      )
    }

    return null
  }

  useEffect(() => {
    setLoading(isLoading || isFetching)
  }, [isLoading, isFetching])

  return (
    <>
      <h1 className="text-2xl font-bold mt-10 mb-4">WhatsApp Analytics </h1>
      <div className="text-sm mb-2">
        This chart visualizes the number of sent messages and delivered over the
        date range selected by filters.
      </div>

      <div style={{ position: 'relative' }}>
        <div className={shouldSkipAnalyticsQuery ? 'blur-md' : ''}>
          <BarChart
            width={1200}
            height={200}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="start" tickFormatter={formatXAxisAnalytics} />
            <YAxis interval={1} />
            <Tooltip content={customAnalyticsTooltip} />
            <Legend />
            <Bar dataKey="sent" fill="#8884d8" name="Messages Sent" />
            <Bar dataKey="delivered" fill="#82ca9d" name="Messages Delivered" />
          </BarChart>
        </div>
        {shouldSkipAnalyticsQuery && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center ">
            <p className="text-sm mt-4">
              No data is not available for the selected date range
            </p>
          </div>
        )}
        {(isLoading || isFetching) && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </>
  )
}
