import {
  useAddMessageSourceMatchingMutation,
  useGetAccountsQuery,
  useGetMessageSourceMatchingsQuery,
  useUpdateMessageSourceMatchingMutation,
} from 'app/features/whatsapp'
import { CustomInput } from 'components/shared/custom-input'
import { CustomSelect } from 'components/shared/custom-select'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { useEffect, useState } from 'react'
import { TbEdit } from 'react-icons/tb'
import { SelectAccount } from './select-account'
import { MAX_WHATSAPP_ACCOUNTS_NUMBER } from './whatsapp-login'

export default function WhatsappMessageSourceMatching() {
  const { sources } = useApp()
  const [error, setError] = useState('')
  const [data, setData] = useState({ message: '', sourceId: '' })
  const [selectedAccount, setSelectedAccount] = useState<any>()
  const [selectedMatching, setSelectedMatching] = useState('')
  const [mode, setMode] = useState('add')

  const { data: accounts, isLoading: isLoadingAccounts } = useGetAccountsQuery()

  const {
    data: matchings,
    isError,
    isLoading: dataLoading,
  } = useGetMessageSourceMatchingsQuery(selectedAccount?.id)
  const [addMatching, { isLoading: createLoading, isError: createError }] =
    useAddMessageSourceMatchingMutation()
  const [updateMatching, { isLoading: updateLoading, isError: updateError }] =
    useUpdateMessageSourceMatchingMutation()

  const isLoading = dataLoading || createLoading || updateLoading

  useEffect(() => {
    if (MAX_WHATSAPP_ACCOUNTS_NUMBER == 1 && accounts?.length! > 0)
      setSelectedAccount(accounts![0])
  }, [accounts])

  useEffect(() => {
    if (createError || updateError) setError('Matching is already exist')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setData({ message: '', sourceId: '' })
      setSelectedMatching('')
      setError('')
      setMode('add')
    }
  }, [mode])
  useEffect(() => {
    setMode('add')
    setData({ message: '', sourceId: '' })
    setSelectedMatching('')
    setError('')
  }, [selectedAccount])

  const handleMatching = async (e: any) => {
    e.preventDefault()
    setError('')
    if (!data.message || data.message.trim() == '')
      return setError('Message is required')
    if (data.sourceId === '') return setError('Source is required')

    if (mode === 'add')
      await addMatching({
        matching: { ...data },
        accountId: selectedAccount?.id,
      })
    else
      await updateMatching({
        matching: {
          id: selectedMatching,
          data: { ...data },
        },
        accountId: selectedAccount?.id,
      })
    setData({ message: '', sourceId: '' })

    setMode('add')
  }

  if (isLoading || isLoadingAccounts)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      {accounts?.length! > 0 && MAX_WHATSAPP_ACCOUNTS_NUMBER > 1 && (
        <SelectAccount
          accounts={accounts}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      )}
      {accounts?.length! > 0 && !selectedAccount && (
        <div className="p-20 grid w-full h-full place-content-center">
          Please select account
        </div>
      )}
      {accounts?.length! == 0 && (
        <div className="p-20 grid w-full h-full place-content-center">
          Please add an account
        </div>
      )}

      {selectedAccount && (
        <>
          {/* Form */}
          <form
            className="flex flex-col  gap-3 mt-5 max-md:flex-col"
            onSubmit={handleMatching}
          >
            <div className="flex items-center gap-4">
              <CustomInput
                label={'Message'}
                value={data.message}
                required={true}
                setValue={(e: any) =>
                  setData({ ...data, message: e.target.value })
                }
                type="text"
                placeholder="Message"
                disabled={isLoading || createLoading}
              />
              <CustomSelect
                label="Source"
                list={sources}
                value={data.sourceId}
                setValue={(value: any) => setData({ ...data, sourceId: value })}
                required
              />
            </div>
            <div className="flex w-full  justify-end">
              <Button
                type="submit"
                disabled={isLoading || createLoading}
                size="sm"
                className="h-9"
              >
                {mode === 'add' ? 'Add Matching' : 'Update Matching'}
              </Button>
              {mode === 'update' && (
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => setMode('add')}
                >
                  Cancel update
                </Button>
              )}
            </div>
          </form>
          {error !== '' && (
            <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
          )}
          {/* List */}
          <div className="mt-5 flex flex-col gap-3  min-h-[100px] max-h-[250px] overflow-y-scroll border rounded-md p-2">
            {matchings?.length == 0 && (
              <div className="flex items-center justify-center p-10 w-full">
                No matchings added
              </div>
            )}
            {matchings?.map((matching: any) => (
              <div
                key={matching.id}
                className="flex items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800"
              >
                <div className="flex items-center gap-2">
                  {matching.message} - <span>{matching.source?.name}</span>
                </div>

                <div className="flex-1" />
                <div className="flex items-center gap-3">
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      setMode('update')
                      setData({
                        message: matching.message,
                        sourceId: matching.sourceId,
                      })
                      setSelectedMatching(matching.id)
                    }}
                  >
                    <TbEdit size={18} />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
