import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { useGetTemplatesQuery } from 'app/features/whatsapp'
import { Button } from 'components/ui/button'
import { CustomInput } from 'components/shared/custom-input'
import PhoneInput from 'components/shared/phone-input'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'
import SendTemplate from './send-template'
import { useToast } from 'components/ui/use-toast'
import { SelectTemplate } from './actions/select-template'
import useApp from 'hooks/useApp'

const initialLead = {
  firstName: '',
  phone: '',
  sourceId: '',
}
export default function InitConversation({
  selectedAccountId,
  open,
  setOpen,
  startMessagingFirstName,
  startMessagingWhatsappNumber,
  startMessagingLeadId,
}: any) {
  const [data, setData] = useState<any>({
    ...initialLead,
    firstName: startMessagingFirstName || '',
    phone: startMessagingWhatsappNumber || '',
  })

  const { toast } = useToast()
  const { setRefetchWhatsappContacts } = useApp()
  //const [open, setOpen] = useState(false)
  const [error, setError] = useState('')
  const [openSendTemplate, setOpenSendTemplate] = useState(false)

  useEffect(() => {
    setData({
      ...initialLead,
      firstName: startMessagingFirstName || '',
      phone: startMessagingWhatsappNumber || '',
    })
    setTemplate(null)
    setError('')
  }, [open])

  const [template, setTemplate] = useState<any>(null)

  const sendTemplateClick = () => {
    toast({
      title: 'Contact added successfully!',
      description:
        'new contact added successfully, please select the conversation and start messaging',
    })

    setData(initialLead)
    setRefetchWhatsappContacts(true)
    setTemplate(null)
    setOpen(false)
  }

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const openSendTemplateHandler = () => {
    setError('')
    if (data?.firstName == '') {
      return setError('Name is required')
    }
    if (data?.phone == '') {
      return setError('Phone is required')
    }
    if (!template) {
      return setError('Please select a template')
    }
    setOpenSendTemplate(true)
  }
  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
          <div className="h-full w-full">
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  New Contact
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>
              <div className="w-full space-y-3 mt-5 px-1">
                <div className="w-full flex items-center gap-3">
                  <CustomInput
                    name="firstName"
                    label="First Name"
                    value={data.firstName}
                    setValue={handleChange}
                    type="text"
                    required
                  />
                  <PhoneInput
                    label="Phone Number"
                    id="phone"
                    handleChange={(value: any) =>
                      setData({
                        ...data,
                        phone: value,
                        whatsappNumber: value,
                      })
                    }
                    value={data.phone}
                    required
                  />
                </div>
              </div>
              <div className="w-full space-y-3 mt-5 px-1">
                <div className="w-full flex items-center gap-3">
                  <SelectTemplate
                    selectedAccountId={selectedAccountId}
                    selectTemplate={(template: any) => {
                      setTemplate(template)
                    }}
                    selectedTemplate={template}
                    displayLabel={false}
                  />
                </div>
              </div>
              {error !== '' && (
                <div className="text-rose-600 text-sm pt-2">{error}</div>
              )}
              <div className="flex items-center gap-3 justify-end">
                <Button onClick={() => openSendTemplateHandler()} size="sm">
                  Next
                </Button>
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
      <SendTemplate
        selectedAccountId={selectedAccountId}
        open={openSendTemplate}
        setOpen={setOpenSendTemplate}
        template={template}
        phoneNumber={data.phone}
        lead={{ firstName: data.firstName }}
        startMessagingLeadId={startMessagingLeadId}
        displaySentTemplate={sendTemplateClick}
      />
    </>
  )
}
