import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import Categories from './edit-categories'
import Answers from './edit-answers'

export default function KnowledgeBank() {
  return (
    <>
      <div className="flex flex-col p-6">
        <div className="flex justify-between mb-6">
          <div>
            <h1 className="text-lg font-medium">Knowledge Bank</h1>
            <p className="text-xs text-gray-500">
              View and edit categories in knowledge bank.
            </p>
          </div>
        </div>

        <div className="w-full">
          <Tabs defaultValue="categories" className="w-full">
            <TabsList className=" w-full bg-white px-1 h-10 border ">
              <TabsTrigger
                className="w-full font-semi  text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
                value="categories"
              >
                Categories
              </TabsTrigger>
              <TabsTrigger
                className="w-full font-semi  text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
                value="data"
              >
                Data
              </TabsTrigger>
            </TabsList>
            <TabsContent value="categories">
              <Categories />
            </TabsContent>
            <TabsContent value="data">
              <Answers />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  )
}
