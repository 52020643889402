import { createSlice } from '@reduxjs/toolkit'

interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  verified: boolean
  disabled: boolean
  updated: boolean
  role: string
  accessPeriodId: string
  groupId: string
  branchIDs: string[]
  departmentIDs: string[]
  specialistIDs: string[]
  isSystemUser: boolean
  createdAt: string
  updatedAt: string
  disabledAt: string | null
  accessPeriod: {
    id: string
    duration: string
    value: string
  }
  group: {
    id: string
    name: string
    tabs: string[]
    roles: string[]
    createdAt: string
    updatedAt: string
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null as User | null,
    token: null,
    verifyUser: null,
    socketServerToken: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.socketServerToken = action.payload.socketServerToken
    },
    setUser: (state, action) => {
      state.verifyUser = action.payload
    },
    removeCredentials: (state) => {
      state.user = null
      state.token = null
    },
    updateUser: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { setCredentials, removeCredentials, setUser, updateUser } =
  userSlice.actions
export default userSlice.reducer
