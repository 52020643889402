import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import Stat from 'components/shared/dashboard-stat/dashboard-stat'
import { useGetResponseTimeReportQuery } from 'app/features/whatsappStats'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'components/ui/use-toast'
import { TbAlertTriangle, TbClock, TbRocket } from 'react-icons/tb'
import { WhatsappStatsProps } from '../index'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import Loader from 'components/shared/loader'

export const ResponseReport = ({
  startDate,
  endDate,
  whatsappAccount,
  setLoading,
}: WhatsappStatsProps) => {
  const {
    refetch,
    isLoading,
    isFetching,
    data,
    isError: isError,
  } = useGetResponseTimeReportQuery({
    whatsappAccount,
    ...(startDate && {
      from: dayjs(startDate).toISOString(),
    }),
    ...(endDate && {
      to: dayjs(endDate).toISOString(),
    }),
  })

  const timeUnits = ['Second', 'Minute', 'Hour', 'Day']

  const [selectedTimeUnit, setSelectedTimeUnit] = useState<
    'Second' | 'Minute' | 'Hour' | 'Day'
  >('Second')

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching response time report',
      })
    }
  }, [isError])

  /**
   * Converts a duration from seconds into the specified time unit.
   *
   * @param seconds the duration in seconds
   * @param to the time unit to convert the duration to. Should be one of 'm' (minutes), 'h' (hours), or 'd' (days)
   * @returns the duration in the specified time unit
   */
  const convertFromSeconds = (seconds: number, to: 'm' | 'h' | 'd') => {
    switch (to) {
      case 'm':
        return Number(dayjs.duration({ seconds }).asMinutes().toFixed(1))
      case 'h':
        return Number(dayjs.duration({ seconds }).asHours().toFixed(1))
      case 'd':
        return Number(dayjs.duration({ seconds }).asDays().toFixed(1))
      default:
        return seconds
    }
  }

  const getInitialsOfName = (name: string) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('.')
  }

  /**
   * Converts the response time report data to the selected time unit
   */
  const responseTimeComputedData = useMemo(() => {
    let agents
    const system = {
      avgFirstResponseTime: '-',
      avgOverallResponseTime: '-',
      missedMessages: data?.system.missedMessages,
    }

    switch (selectedTimeUnit) {
      case 'Minute':
        system.avgFirstResponseTime = convertFromSeconds(
          data?.system.avgFirstResponseTime || 0,
          'm'
        ).toString()

        system.avgOverallResponseTime = convertFromSeconds(
          data?.system.avgOverallResponseTime || 0,
          'm'
        ).toString()
        agents = data?.agents.map((agent: any) => ({
          ...agent,
          avgFirstResponseTime: convertFromSeconds(
            agent.avgFirstResponseTime,
            'm'
          ),
          avgOverallResponseTime: convertFromSeconds(
            agent.avgOverallResponseTime,
            'm'
          ),
        }))
        break

      case 'Hour':
        system.avgFirstResponseTime = convertFromSeconds(
          data?.system.avgFirstResponseTime || 0,
          'h'
        ).toString()
        system.avgOverallResponseTime = convertFromSeconds(
          data?.system.avgOverallResponseTime || 0,
          'h'
        ).toString()
        agents = data?.agents.map((agent: any) => ({
          ...agent,
          avgFirstResponseTime: convertFromSeconds(
            agent.avgFirstResponseTime,
            'h'
          ),
          avgOverallResponseTime: convertFromSeconds(
            agent.avgOverallResponseTime,
            'h'
          ),
        }))
        break

      case 'Day':
        system.avgFirstResponseTime = convertFromSeconds(
          data?.system.avgFirstResponseTime || 0,
          'd'
        ).toString()
        system.avgOverallResponseTime = convertFromSeconds(
          data?.system.avgOverallResponseTime || 0,
          'd'
        ).toString()
        agents = data?.agents.map((agent: any) => ({
          ...agent,
          avgFirstResponseTime: convertFromSeconds(
            agent.avgFirstResponseTime,
            'd'
          ),
          avgOverallResponseTime: convertFromSeconds(
            agent.avgOverallResponseTime,
            'd'
          ),
        }))
        break

      default:
        system.avgFirstResponseTime = (
          data?.system.avgFirstResponseTime || '-'
        ).toString()
        system.avgOverallResponseTime = (
          data?.system.avgOverallResponseTime || '-'
        ).toString()
        agents = data?.agents.map((agent: any) => ({
          ...agent,
          avgFirstResponseTime: agent.avgFirstResponseTime,
          avgOverallResponseTime: agent.avgOverallResponseTime,
        }))
        break
    }

    return {
      system,
      agents,
    }
  }, [data, selectedTimeUnit])

  /**
   * Custom tooltip for displaying WhatsApp response analytics.
   *
   * @param param
   * @param param.active - Whether the tooltip is active.
   * @param param.payload - Data payload for the tooltip containing response times.
   * @param param.label - Label for the tooltip indicating the time period.
   *
   * @returns {ReactNode|null} - JSX for the tooltip displaying average response times
   *                             or null if inactive or no payload.
   */
  const customResponseTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="card bg-white dark:bg-gray-900 p-2 shadow">
          <p>{label}</p>
          <p style={{ color: '#8884d8' }}>
            Avg. First Response Time: {payload[0].value} {selectedTimeUnit}
          </p>
          <p style={{ color: '#82ca9d' }}>
            Avg. Overall Response Time: {payload[1].value} {selectedTimeUnit}
          </p>
        </div>
      )
    }
  }

  useEffect(() => {
    setLoading(isLoading || isFetching)
  }, [isLoading, isFetching])

  return (
    <>
      <h1 className="text-2xl font-bold mb-2">Response Analyze</h1>
      <div className="text-sm mb-8">
        Tracking the response time of conversations, this chart shows the
        efficiency of active agents in delivering prompt responses.
        <ul className="list-disc list-inside mt-2 ml-4">
          <li>
            The 'First Response Time' represents the time it takes for an agent
            to respond to the first message in a conversation.
          </li>
          <li>
            The 'Overall Response Time' shows the average time it takes for an
            agent to respond to all messages in a conversation.
          </li>
          <li>
            The 'Missed Messages' indicator indicates the number of times an
            agent missed a message in a conversation 24 hours (or more) before
            the message and 24 hours (or more) after the message.
          </li>
        </ul>
      </div>

      <div className="flex justify-between">
        <div className="grid grid-cols-3 gap-2">
          <Stat
            number={responseTimeComputedData.system.avgFirstResponseTime}
            title="Avg. first"
            icon={<TbClock size={26} color="gray" />}
            description="The average time it takes for an agent to respond to the first message in a conversation."
          />
          <Stat
            number={responseTimeComputedData.system.avgOverallResponseTime}
            title="Avg. All"
            icon={<TbRocket size={26} color="gray" />}
            description="The average time it takes for an agent to respond to all messages in a conversation."
          />
          <Stat
            number={responseTimeComputedData.system.missedMessages}
            title="Missed"
            icon={<TbAlertTriangle size={26} color="gray" />}
            description="The number of messages that were not responded to by an agent within 24h time frame."
          />
        </div>
        <div>
          <div className="text-sm text-gray-500 mb-2">Time unit:</div>
          <Select
            key="select-component"
            value={selectedTimeUnit}
            onValueChange={(value) => {
              setSelectedTimeUnit(value as 'Second' | 'Minute' | 'Hour' | 'Day')
            }}
          >
            <SelectTrigger className="w-full ">
              <SelectValue>{}</SelectValue>
            </SelectTrigger>
            <SelectContent className=" w-40  ">
              <SelectGroup>
                {timeUnits?.map((item) => (
                  <SelectItem key={item} value={item}>
                    <div>{item}</div>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="flex justify-between relative">
        <div>
          <BarChart
            width={1000}
            height={200}
            data={responseTimeComputedData?.agents}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tickFormatter={(tick) => getInitialsOfName(tick)}
            />
            <YAxis allowDecimals={false} />
            <Tooltip content={customResponseTooltip} />
            <Legend />
            <Bar
              dataKey="avgFirstResponseTime"
              fill="#8884d8"
              name="Avg. First"
            />
            <Bar
              dataKey="avgOverallResponseTime"
              fill="#82ca9d"
              name="Avg. All"
            />
          </BarChart>
        </div>

        {(isLoading || isFetching) && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <Loader />
          </div>
        )}

        {!isLoading &&
          !isFetching &&
          responseTimeComputedData.agents?.length === 0 && (
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center ">
              <p className="text-sm mt-4">
                No data is not available for the selected date range.
              </p>
            </div>
          )}
      </div>
    </>
  )
}
