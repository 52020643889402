import { api } from '../api'

type Account = {
  id: string
  phoneNumber: string
}

type AccountResponse = Account[]

type Lead = {
  id: string
  firstName: string
  lastName?: string
  phone?: string
  email?: string
  nationality?: string
  whatsappNumber?: string
}

type Contact = {
  id: string
  phoneNumber: string
  leads: Lead[]
  name: string
  messages: Message[]
}

type Message = {
  content: string
  receiverId: string
  senderId: string
  sender: { firstName: string }
}

type Conversation = {
  messages: Message[]
  createdAt: Date
  leads: any
  phoneNumber: string
  name: string
  tags: any[]
  id: string
}

type MediaResponse = {
  media: string
}

type ContactResponse = Contact[]

type ProfileResponse = {
  profile_picture_url: string
}

type Template = {
  name: string
  status: string
  category: string
  id: string
  language: string
}

type TemplateResponse = {
  data: Template[]
}

type FailedMessagesResponse = {
  items: {
    id: string
    conversation: {
      name: string
      phoneNumber: string
      id: string
    }
    content: string
    createdAt: string
  }[]
  count: number
}

type FailedMessagesArguments = {
  whatsappAccount: string
  take: number
  skip: number
  from?: string
  to?: string
}

export const whatsappApi = api.injectEndpoints({
  endpoints: (builder) => ({
    /** Accounts */

    getAccounts: builder.query<AccountResponse, void>({
      query: () => `/whatsapp/accounts`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Accounts' as const,
                id,
              })),
              { type: 'Accounts', id: 'LIST' },
            ]
          : [{ type: 'Accounts', id: 'LIST' }],
    }),
    getAccount: builder.query<any, string>({
      query: (id: string) => `/whatsapp/accounts/${id}`,
      providesTags: [{ type: 'Accounts', id: 'LIST' }],
    }),

    initAccount: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/accounts`,
        method: 'POST',
        body: data,
      }),
    }),
    updateAccount: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/account`,
        method: 'PUT',
        body: data,
      }),
    }),
    updateAccountSettings: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/accounts/account-settings`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
    }),

    /** Profile */

    getProfile: builder.query<ProfileResponse, string>({
      query: (accountId) => `/whatsapp/profile?accountId=${accountId}`,
      providesTags: ['WhatsappProfile'],
    }),
    updateProfile: builder.mutation({
      query: ({ data, accountId }) => ({
        url: `/whatsapp/profile?accountId=${accountId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'WhatsappProfile' }],
    }),

    /** Conversations */

    getContacts: builder.query<
      ContactResponse,
      {
        keyword: string
        accountId: string
        skip: number
        take: number
        tags: string[]
        agents: string[]
        read: any
        campaign: any
      }
    >({
      query: ({
        keyword,
        accountId,
        skip,
        take,
        tags,
        read,
        campaign,
        agents,
      }) =>
        `/whatsapp?accountId=${accountId}&filters=${JSON.stringify({ skip, take, keyword, tags, agents, campaign, read })}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Contacts' as const,
                id,
              })),
              { type: 'Contacts', id: 'LIST' },
            ]
          : [{ type: 'Contacts', id: 'LIST' }],
    }),

    getContactByPhoneNumber: builder.query<
      Contact,
      { phoneNumber: string; accountId: string }
    >({
      query: ({ phoneNumber, accountId }) =>
        `/whatsapp/by-phone-number?phone=${phoneNumber}&accountId=${accountId}`,
      providesTags: (result) => [
        'Conversations',
        'Contacts',
        { type: 'Contacts', id: 'LIST' },
      ],
    }),

    getUnreadConversationsCount: builder.query<number, string>({
      query: (accountId) =>
        `/whatsapp/unread-conversations-count?accountId=${accountId}`,
      providesTags: () => [
        'Conversations',
        'Contacts',
        { type: 'Contacts', id: 'LIST' },
      ],
    }),
    getAllConversationsCount: builder.query<any, void>({
      query: () => `/whatsapp/all-unread-conversations-count`,
      providesTags: () => [
        'Conversations',
        'Contacts',
        'Accounts',
        { type: 'Contacts', id: 'LIST' },
      ],
    }),

    getConversation: builder.query<Conversation, string | undefined>({
      query: (id) => `/whatsapp/${id}`,
      providesTags: (result) => [
        'Conversations',
        { type: 'Conversations', id: result?.id },
      ],
    }),
    updateConversation: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/${data?.id}`,
        method: 'PUT',
        body: { name: data?.name },
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),
    markWhatsappConversationAsRead: builder.mutation({
      query: (id) => ({
        url: `/whatsapp/mark-as-read/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),
    markWhatsappConversationAsUnRead: builder.mutation({
      query: (id) => ({
        url: `/whatsapp/mark-as-unread/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),

    /** Session file and Media */

    getMedia: builder.query<
      MediaResponse,
      { mediaId: string; accountId: string }
    >({
      query: ({ mediaId, accountId }) =>
        `/whatsapp/message/${mediaId}?accountId=${accountId}`,
    }),

    sessionUploadFile: builder.mutation({
      query: ({ formData, accountId }) => ({
        url: `/whatsapp/session-upload-file?accountId=${accountId}`,
        method: 'POST',
        body: formData,
        formData: true,
        Accept: '*/*',
      }),
    }),
    mediaFile: builder.mutation({
      query: ({ formData, accountId }) => ({
        url: `/whatsapp/media-file?accountId=${accountId}`,
        method: 'POST',
        body: formData,
        formData: true,
        Accept: '*/*',
      }),
    }),

    /** Send messages */

    sendMessage: builder.mutation({
      query: ({ data, accountId }) => ({
        url: `/whatsapp/message?accountId=${accountId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),

    sendMedia: builder.mutation({
      query: ({ formData, accountId }) => ({
        url: `/whatsapp/media?accountId=${accountId}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),

    /** Templates */

    createTemplate: builder.mutation({
      query: ({ data, accountId }) => ({
        url: `/whatsapp/template?accountId=${accountId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Templates' }],
    }),

    updateTemplate: builder.mutation({
      query: ({ data, templateId, accountId }) => ({
        url: `/whatsapp/template/${templateId}?accountId=${accountId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [{ type: 'Templates' }],
    }),
    getTemplates: builder.query<TemplateResponse, string>({
      query: (accountId) => `/whatsapp/template?accountId=${accountId}`,
      providesTags: ['Templates'],
    }),
    sendTemplate: builder.mutation({
      query: ({ data, accountId }) => ({
        url: `/whatsapp/send-template?accountId=${accountId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Conversations' },
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    deleteTemplate: builder.mutation({
      query: ({ data, accountId }) => ({
        url: `/whatsapp/template?accountId=${accountId}`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Templates'],
    }),

    /** Campaigns */

    sendCampaign: builder.mutation({
      query: ({ data, accountId }) => ({
        url: `/whatsapp/send-campaign?accountId=${accountId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Conversations' },
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Campaigns',
      ],
    }),
    sendBulkCampaign: builder.mutation({
      query: ({ data, accountId }) => ({
        url: `/whatsapp/send-bulk-campaign?accountId=${accountId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Conversations' },
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Campaigns',
      ],
    }),
    getCampaigns: builder.query<any, any>({
      query: (filters: any) =>
        `/whatsapp/campaigns?filters=${JSON.stringify(filters)}`,
      providesTags: ['Conversations', 'Campaigns'],
    }),

    /** Conversation tags */

    getConversationTags: builder.query<any, string>({
      query: (accountId) =>
        `/whatsapp/conversation-tags?accountId=${accountId}`,
      providesTags: ['Conversations'],
    }),
    addConversationTag: builder.mutation({
      query: ({ accountId, tag }) => ({
        url: `/whatsapp/conversation-tags?accountId=${accountId}`,
        method: 'POST',
        body: {
          ...tag,
        },
      }),
      invalidatesTags: [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    updateConversationTag: builder.mutation({
      query: ({ accountId, tag }) => ({
        url: `/whatsapp/conversation-tags/${tag.id}?accountId=${accountId}`,
        method: 'PUT',
        body: {
          ...tag.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    updateAssignedConversationTags: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/update-assigned-tags/${data.conversationId}`,
        method: 'PUT',
        body: {
          tags: data.tags,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),

    /** Source matching */

    getMessageSourceMatchings: builder.query<any, string>({
      query: (accountId) =>
        `/whatsapp/message-source-matchings?accountId=${accountId}`,
      providesTags: ['Conversations'],
    }),
    addMessageSourceMatching: builder.mutation({
      query: ({ accountId, matching }) => ({
        url: `/whatsapp/message-source-matchings?accountId=${accountId}`,
        method: 'POST',
        body: {
          ...matching,
        },
      }),
      invalidatesTags: [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    updateMessageSourceMatching: builder.mutation({
      query: ({ accountId, matching }) => ({
        url: `/whatsapp/message-source-matchings/${matching.id}?accountId=${accountId}`,
        method: 'PUT',
        body: {
          ...matching.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),

    /** Response report */

    getResponsesReport: builder.query<any, any>({
      query: (filters: any) =>
        `/whatsapp/responses-report?filters=${JSON.stringify(filters)}`,
    }),

    getUnsubscribedNumbers: builder.query<
      { number: string; name: string; leadId: string }[],
      void
    >({
      query: () => '/whatsapp/unsubscribed-numbers',
    }),
    getFailedMessages: builder.query<
      FailedMessagesResponse,
      FailedMessagesArguments
    >({
      query: (filters: any) => {
        const searchParams = new URLSearchParams()

        searchParams.set('whatsappAccount', filters.whatsappAccount)
        searchParams.set('take', filters.take.toString())
        searchParams.set('skip', filters.skip.toString())
        if (filters.from) searchParams.set('from', filters.from)
        if (filters.to) searchParams.set('to', filters.to)
        const queryString = searchParams.toString()

        return `/whatsapp/messages/failed?${queryString}`
      },
    }),
  }),
})

export const {
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetContactByPhoneNumberQuery,
  useLazyGetContactByPhoneNumberQuery,
  useGetUnreadConversationsCountQuery,
  useGetAllConversationsCountQuery,
  useGetConversationQuery,
  useUpdateConversationMutation,
  useSendMessageMutation,
  useMarkWhatsappConversationAsReadMutation,
  useMarkWhatsappConversationAsUnReadMutation,
  useGetMediaQuery,
  useSendMediaMutation,
  useGetProfileQuery,
  useGetResponsesReportQuery,
  useLazyGetResponsesReportQuery,
  useUpdateProfileMutation,
  useInitAccountMutation,
  useUpdateAccountSettingsMutation,
  useGetAccountsQuery,
  useGetAccountQuery,
  useLazyGetAccountsQuery,
  useUpdateAccountMutation,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useGetTemplatesQuery,
  useSendTemplateMutation,
  useSessionUploadFileMutation,
  useMediaFileMutation,
  useSendCampaignMutation,
  useSendBulkCampaignMutation,
  useGetCampaignsQuery,
  useAddConversationTagMutation,
  useGetConversationTagsQuery,
  useUpdateConversationTagMutation,
  useUpdateAssignedConversationTagsMutation,
  useGetMessageSourceMatchingsQuery,
  useUpdateMessageSourceMatchingMutation,
  useAddMessageSourceMatchingMutation,
  useGetUnsubscribedNumbersQuery,
  useGetFailedMessagesQuery,
} = whatsappApi
