import { Actions } from '../components/actions'
import MobileSidebar from './mobile-sidebar'
import Sidebar from './sidebar'

export default function SocialSidebar({
  activeTab,
  selectedAccountId,
  selectedConversation,
  setSelectedConversation,
  selectedLead,
  setSelectedLead,
  conversationLeads,
  setConversationLeads,

  refreshContacts,
}: any) {
  return (
    <>
      <div className="flex items-center pt-2 pl-2 sm:hidden">
        <MobileSidebar
          activeTab={activeTab}
          selectedAccountId={selectedAccountId}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          conversationLeads={conversationLeads}
          setConversationLeads={setConversationLeads}
          refreshContacts={refreshContacts}
        />
        <Actions selectedAccountId={selectedAccountId} mobileView={true} />
      </div>
    </>
  )
}
