import { useGetCustomersQuery } from 'app/features/customers'
import Pagination from 'components/table/pagination'
import { useToast } from 'components/ui/use-toast'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'
import dayjs from 'lib/dayjs'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import ViewCustomer from './view-customer'
import { Button } from 'components/ui/button'
import { TbWindowMaximize } from 'react-icons/tb'
import Loader from 'components/shared/loader'

/**
 * A component to view customers
 * @returns a JSX element
 */
export default function Customers() {
  const { toast } = useToast()

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { data, isLoading, isFetching, isError } = useGetCustomersQuery({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
  })

  const [viewDialog, setViewDialog] = useState<{
    visible: boolean
    id?: string
  }>({
    visible: false,
    id: undefined,
  })

  /**
   * Show error toast if isError is true
   */
  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error',
        description: 'Error fetching data',
        variant: 'destructive',
      })
    }
  }, [isError, toast])

  return (
    <div className="flex flex-col p-6">
      <div className="flex justify-between mb-6">
        <div>
          <h1 className="text-lg font-medium">Customers</h1>
          <p className="text-xs text-gray-500">
            View customers list. The list contains unique contacts. Each contact
            may contain 1 or more leads.
          </p>
        </div>
        <div className="flex"></div>
      </div>

      <div className="border rounded-md bg-white overflow-x-scroll mb-2">
        <table
          className={cn(
            isLoading ? 'text-gray-400' : 'text-gray-800',
            'text-sm w-full border-collapse text-[13px] table-fixed'
          )}
        >
          <thead>
            <tr className="text-center border-b w-full ">
              <th className="lg:w-20 ">Name</th>
              <th className="lg:w-40 ">Phones</th>
              <th className="lg:w-40 ">Leads</th>
              <th className="lg:w-40 ">Created on</th>
              <th className="lg:w-40 "></th>
            </tr>
          </thead>
          <tbody>
            {(isLoading || isFetching) && (
              <tr>
                <td colSpan={4} className="">
                  <div className="w-full flex justify-center my-8">
                    <Loader />
                  </div>
                </td>
              </tr>
            )}

            {!isLoading && !isFetching && !data?.count && (
              <tr>
                <td colSpan={4} className="text-center">
                  No data found
                </td>
              </tr>
            )}

            {!isLoading && !isFetching && data?.count && (
              <>
                {data?.items?.map((customer) => (
                  <tr key={customer.id} className="border-t">
                    <td className="text-center" style={{ whiteSpace: 'wrap' }}>
                      {customer.firstName || ''} {customer.lastName || ''}
                    </td>
                    <td className="text-center">
                      {customer.phones?.join(', ') || ''}
                    </td>
                    <td className="text-center">
                      {customer.leads?.length ? customer.leads?.length : ''}
                    </td>
                    <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                      {dayjs(customer.createdAt).format('DD/MM/YYYY')}
                    </td>
                    <td className="text-center">
                      <Button
                        variant={'ghost'}
                        size={'icon'}
                        onClick={() =>
                          setViewDialog({ visible: true, id: customer.id })
                        }
                      >
                        <TbWindowMaximize size={20} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div>
        {data && data?.count > 0 && (
          <Pagination
            count={data?.count}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>

      <AlertDialog
        open={viewDialog.visible}
        onOpenChange={() => setViewDialog({ visible: false })}
      >
        <AlertDialogContent className="max-w-[1000px] bg-white dark:bg-gray-900 pr-3">
          <ViewCustomer
            id={viewDialog.id!}
            closeDialog={() => setViewDialog({ visible: false })}
          />
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
