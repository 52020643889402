import { api } from '../api'

type MetaAdsResponse = {
  items: {
    referral: {
      source_id: string
      source_url: string
      source_type: string
      body: string
      headline: string
    }
    leadCount: number
  }[]
  count: number
}

type MetaAdsArguments = {
  take: number
  skip: number
  whatsappAccount: string
  from?: string
  to?: string
}

type ConversationsOfMetaAdArguments = {
  whatsappAccount: string
  sourceId: string
  take: number
  skip: number
}

type ConversationsOfMetaAdResponse = {
  items: {
    name: string
    phoneNumber: string
    leads: {
      firstName: string
      lastName: string
      followUps: {
        createdAt: string
      }[]
    }[]
  }[]
  count: number
}

export const whatsappApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMetaAds: builder.query<MetaAdsResponse, MetaAdsArguments>({
      query: (filters) => {
        const searchParams = new URLSearchParams()

        searchParams.set('whatsappAccount', filters.whatsappAccount)
        searchParams.set('take', filters.take.toString())
        searchParams.set('skip', filters.skip.toString())
        if (filters.from) searchParams.set('from', filters.from)
        if (filters.to) searchParams.set('to', filters.to)
        const queryString = searchParams.toString()

        return `/meta-ads?${queryString}`
      },
      providesTags: ['Leads', 'Conversations'],
    }),
    getConversationsOfMetaAd: builder.query<
      ConversationsOfMetaAdResponse,
      ConversationsOfMetaAdArguments
    >({
      query: (filters) => {
        const searchParams = new URLSearchParams()

        searchParams.set('whatsappAccount', filters.whatsappAccount)
        searchParams.set('sourceId', filters.sourceId)
        searchParams.set('take', filters.take.toString())
        searchParams.set('skip', filters.skip.toString())
        const queryString = searchParams.toString()

        return `/meta-ads/conversations?${queryString}`
      },
    }),
  }),
})

const { useGetMetaAdsQuery, useGetConversationsOfMetaAdQuery } = whatsappApi
export { useGetMetaAdsQuery, useGetConversationsOfMetaAdQuery }
