import {
  useGetAccountsQuery,
  useGetProfileQuery,
  useInitAccountMutation,
} from 'app/features/whatsapp'

import Loader from 'components/shared/loader'
import WhatsappProfileSettings from './whatsapp-profile-settings'
import { toast } from 'components/ui/use-toast'
import { useEffect, useState } from 'react'
import { SelectAccount } from './select-account'

export const MAX_WHATSAPP_ACCOUNTS_NUMBER = parseInt(
  process.env.REACT_APP_WHATSAPP_MAX_ACCOUNTS_NUMBER!
)

export default function WhatsappLogin() {
  const [initAccount] = useInitAccountMutation()
  const [selectedAccount, setSelectedAccount] = useState<any>()
  const {
    data: profile,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
  } = useGetProfileQuery(selectedAccount?.id!)

  const { data: accounts, isLoading: isLoadingAccounts } = useGetAccountsQuery()

  useEffect(() => {
    if (MAX_WHATSAPP_ACCOUNTS_NUMBER == 1 && accounts?.length! > 0)
      setSelectedAccount(accounts![0])
  }, [accounts])

  useEffect(() => {
    const handleIframeMessage = (event: any) => {
      // TODO: to be added later
      // const allowedOrigin = new RegExp(
      //   '^https?://([a-z0-9-]+\\.)?doctorna\\.com$'
      // )
      // if (!allowedOrigin.test(event.origin)) return
      if (event?.data?.type == 'success') {
        initAccount({ code: event.data.code })
      } else if (event?.data?.type == 'error') {
        toast({
          title: 'Error occurred',
          description: event?.data?.error,
        })
      }
    }

    window.addEventListener('message', handleIframeMessage)

    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  if (isLoadingAccounts)
    return (
      <div className="w-full h-[350px] grid place-content-center">
        <Loader />
      </div>
    )

  // TODO: to be added later
  // if (isError)
  //   return (
  //     <div className="w-full h-[350px] grid place-content-center">
  //       <Error message="Server error, contact administrator please" />
  //     </div>
  //   )

  return (
    <div className="flex flex-col space-y-4 items-left py-6">
      {accounts?.length! > 0 && MAX_WHATSAPP_ACCOUNTS_NUMBER > 1 && (
        <SelectAccount
          accounts={accounts}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      )}
      {accounts?.length! > 0 && !selectedAccount && (
        <div className="p-20 grid w-full h-full place-content-center">
          Please select account
        </div>
      )}
      {accounts?.length! == 0 && MAX_WHATSAPP_ACCOUNTS_NUMBER > 1 && (
        <div className="p-20 grid w-full h-full place-content-center">
          Please add an account account
        </div>
      )}

      {accounts?.length! == 0 && MAX_WHATSAPP_ACCOUNTS_NUMBER == 1 && (
        <iframe
          src={process.env.REACT_APP_WHATSAPP_LOGIN_DOMAIN}
          style={{ width: '100%', height: '500px', border: 'none' }}
          title="Whatsapp Login"
        />
      )}

      {isLoadingProfile && (
        <div className="h-40 grid place-content-center">
          <Loader />
        </div>
      )}
      {selectedAccount && !isLoadingProfile && (
        <>
          {profile && !isErrorProfile ? (
            <WhatsappProfileSettings
              profile={profile}
              selectedAccount={selectedAccount}
            />
          ) : (
            <>
              <iframe
                src={process.env.REACT_APP_WHATSAPP_LOGIN_DOMAIN}
                style={{ width: '100%', height: '500px', border: 'none' }}
                title="Whatsapp Login"
              />
            </>
          )}
        </>
      )}
    </div>
  )
}
