import { CaretSortIcon, CheckIcon, Cross1Icon } from '@radix-ui/react-icons'
import { useGetAccountsQuery } from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import TimeSelect from 'components/shared/time-select'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import { cn } from 'lib/utils'
import { MAX_WHATSAPP_ACCOUNTS_NUMBER } from 'pages/settings/components/social/whatsapp-login'
import { useEffect, useState } from 'react'
import { FaWhatsapp } from 'react-icons/fa'

export default function StartMessagingViaWhatsApp({
  open,
  setOpen,
  startMessagingViaWhatsApp,
}: any) {
  const { data: accounts, isLoading, isError } = useGetAccountsQuery()
  const [openSelect, setOpenSelect] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<any>(null)

  useEffect(() => {
    if (
      openSelect &&
      MAX_WHATSAPP_ACCOUNTS_NUMBER == 1 &&
      accounts?.length! > 0
    ) {
      setSelectedAccount(accounts![0])
      startMessagingViaWhatsApp(accounts![0].id)
      setOpenSelect(false)
    }
  }, [accounts, openSelect])

  return (
    <Popover open={openSelect} onOpenChange={setOpenSelect} modal={true}>
      <PopoverTrigger asChild className="">
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={openSelect}
          className=" w-full  whitespace-normal flex justify-between text-left  items-center    border-none rounded  p-2 py-4 h-14 border-gray-300 shadow-sm dark:border-gray-800  dark:bg-gray-900"
        >
          Start messaging via Whatsapp{' '}
          <FaWhatsapp size={16} className="text-gray-500" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        {isLoading && (
          <div className="h-40 grid place-content-center">
            <Loader />
          </div>
        )}
        {!isLoading && accounts?.length! == 0 && (
          <span className="text-black   h-[200px] w-[300px] grid justify-center items-center">
            please add an account
          </span>
        )}
        {!isLoading && accounts?.length! > 0 && (
          <Command className="w-[300px] overflow-y-auto ">
            <CommandInput placeholder={`Search Accounts`} className="h-9" />

            <CommandGroup>
              <ScrollArea className="h-52 w-full">
                {accounts?.map((item: any) => (
                  <>
                    <CommandItem
                      id={item.id}
                      key={`${item.id}`}
                      value={item}
                      onSelect={(currentValue) => {
                        setSelectedAccount(item)
                        startMessagingViaWhatsApp(item.id)
                        setOpenSelect(false)
                      }}
                      className="capitalize hover:bg-white"
                    >
                      <div className="flex flex-col w-full ">
                        <span className="flex justify-between items-center w-full">
                          {item.phoneNumber}
                          <CheckIcon
                            className={cn(
                              'ml-auto h-4 w-4',
                              selectedAccount?.id === item?.id
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                        </span>
                      </div>
                    </CommandItem>
                  </>
                ))}
              </ScrollArea>
            </CommandGroup>
          </Command>
        )}
      </PopoverContent>
    </Popover>
  )
}
