import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useSessionUploadFileMutation,
  useUpdateTemplateMutation,
} from 'app/features/whatsapp'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button, buttonVariants } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import {
  cn,
  deepEqual,
  extractPlaceholdersIntoArray,
  prepareMediaFormData,
  replacePlaceholdersByIndices,
} from 'lib/utils'
import { isPhone, isURL } from 'lib/validation'
import { useEffect, useRef, useState } from 'react'
import { GiReturnArrow } from 'react-icons/gi'
import { toast } from 'components/ui/use-toast'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { IoAddCircleOutline } from 'react-icons/io5'
import { HiOutlineEmojiHappy } from 'react-icons/hi'
import Picker from 'emoji-picker-react'
import PhoneInput from 'react-phone-input-2'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel'
import { MdDelete } from 'react-icons/md'
import { TEMPLATE_BUTTONS, TEMPLATE_VARIABLES } from './create-template'

const initCard = {
  headerMedia: 'IMAGE',
  headerMediaSampleFile: null,
  body: '',
  bodySample: {},
  buttons: [],
}

const initialCarouselData = {
  body: '',
  bodySample: {},
  carousel: [initCard],
}

const initialData = {
  header: 'none',
  headerText: '',
  headerTextSample: {},
  headerMedia: 'IMAGE',
  headerMediaSampleFile: null,
  body: '',
  bodySample: {},
  footer: '',
  buttons: [],
}

export default function UpdateTemplate({
  selectedAccountId,
  open,
  setOpen,
  template,
}: any) {
  const [updateTemplateFunction] = useUpdateTemplateMutation()
  const [sessionUploadFileFunction] = useSessionUploadFileMutation()
  const [type, setType] = useState(getType(template))
  const [data, setData] = useState<any>(getInitData(template))
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setData(getInitData(template))
    setType(getType(template))
    setError('')
  }, [open])

  const validateCarouselData = () => {
    /* body validation */
    if (data.body === '') {
      throw new Error('Body is required')
    }

    const carousel = data.carousel

    for (let i = 0; i < carousel.length; i++) {
      /* card body validation */
      if (carousel[i].body === '') {
        throw new Error(`Card-${i + 1} - Body is required`)
      }

      /* card header media sample validation */
      if (!carousel[i].headerMediaSampleFile) {
        throw new Error(`Card-${i + 1} - Media sample required`)
      }
      if (carousel[i].buttons.length < 1)
        throw new Error(`Card-${i + 1} -  Button Required`)
    }

    // Validate Buttons
  }

  const validateNormalData = () => {
    /* header media sample validation */
    if (data.header == 'media' && !data.headerMediaSampleFile) {
      throw new Error('Media sample required')
    }
    /* body validation */
    if (data.body === '') {
      throw new Error('Body is required')
    }
  }

  async function updateTemplate() {
    setLoading(true)
    setError('')

    if (type == 'Normal') await updateNormalTemplate()

    if (type == 'Carousel') await updateCarouselTemplate()
  }

  const updateNormalTemplate = async () => {
    try {
      validateNormalData()
    } catch (err: any) {
      setLoading(false)
      return setError(err.message)
    }

    const headerText = replacePlaceholdersByIndices(data['headerText'])
    const headerTextSample = extractPlaceholdersIntoArray(data['headerText'])

    const body = replacePlaceholdersByIndices(data['body'])
    const bodySample = extractPlaceholdersIntoArray(data['body'])

    try {
      /* Header media upload */
      let headerMediaSampleFile = null
      if (data.header == 'media') {
        const formData = prepareMediaFormData({
          my_file: data.headerMediaSampleFile,
        })

        const uploadResult: any = await sessionUploadFileFunction({
          formData,
          accountId: selectedAccountId,
        })
        if (uploadResult?.error) {
          setLoading(false)
          return setError('Failed to upload media, please try again')
        }
        headerMediaSampleFile = uploadResult?.data?.h
      }

      // Order buttons
      const quickReplyButtons = []
      const nonQuickReplyButtons = []

      for (let i = 0; i < data?.buttons?.length; i++) {
        if (data?.buttons[i].type == TEMPLATE_BUTTONS.QUICK_REPLY.type)
          quickReplyButtons.push(data.buttons[i])
        else nonQuickReplyButtons.push(data.buttons[i])
      }

      /* create template */
      const result: any = await updateTemplateFunction({
        data: {
          ...data,
          headerMediaSampleFile,
          headerText,
          headerTextSample,
          body,
          bodySample,
          buttons: [...quickReplyButtons, ...nonQuickReplyButtons],
        },
        templateId: template.id,
        accountId: selectedAccountId,
      })

      if (result?.error) {
        setLoading(false)
        return setError('Failed to update template, please try again')
      }
      toast({
        title: 'Template updated successfully!',
        description:
          'template updated successfully, it will be reviewed by Meta, if accepted, you can start use it',
      })
      setLoading(false)
      setData(initialData)
      setOpen(false)
    } catch (error) {
      setLoading(false)
      setData((prev: any) => {
        return {
          ...initialData,
          name: prev.name,
          language: prev.language,
          category: prev.category,
        }
      })
      return setError('Failed to update template, please try again')
    }
  }

  const updateCarouselTemplate = async () => {
    {
      try {
        validateCarouselData()
      } catch (err: any) {
        setLoading(false)
        return setError(err.message)
      }

      const body = replacePlaceholdersByIndices(data['body'])
      const bodySample = extractPlaceholdersIntoArray(data['body'])

      try {
        const carousel = data.carousel

        for (let i = 0; i < carousel.length; i++) {
          const body = replacePlaceholdersByIndices(carousel[i]['body'])
          const bodySample = extractPlaceholdersIntoArray(carousel[i]['body'])
          carousel[i]['body'] = body
          carousel[i]['bodySample'] = bodySample

          /* Card Header media upload */
          const formData = prepareMediaFormData({
            my_file: carousel[i].headerMediaSampleFile,
          })
          const uploadResult: any = await sessionUploadFileFunction({
            formData,
            accountId: selectedAccountId,
          })
          if (uploadResult?.error) {
            setLoading(false)
            return setError('Failed to upload media, please try again')
          }
          carousel[i].headerMediaSampleFile = uploadResult?.data?.h
        }

        /* create template */
        const result: any = await updateTemplateFunction({
          data: {
            ...data,
            carousel,
            body,
            bodySample,
          },
          templateId: template.id,
          accountId: selectedAccountId,
        })

        if (result?.error) {
          setLoading(false)
          return setError('Failed to update template, please try again')
        }
        toast({
          title: 'Template updated successfully!',
          description:
            'template updated successfully, it will be reviewed by Meta, if accepted, you can start use it',
        })
        setLoading(false)
        setData(initialCarouselData)
        setOpen(false)
      } catch (error) {
        setLoading(false)

        setData((prev: any) => {
          return {
            ...initialCarouselData,
            name: prev.name,
            language: prev.language,
            category: prev.category,
          }
        })
        return setError('Failed to update template, please try again')
      }
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
            <div className="flex w-full items-center justify-between">
              <h1 className="text-lg font-semibold capitalize">
                Update Template
              </h1>

              <Button
                variant="outline"
                size="icon"
                onClick={() => setOpen(false)}
              >
                <Cross1Icon className="h-3 w-3" />
              </Button>
            </div>

            <div className="space-y-3 mt-5 ">
              <div className="w-full space-y-2">
                <Label>Type</Label>
                <Select
                  value={type}
                  onValueChange={(value: any) => {
                    if (value == 'Normal')
                      setData((prev: any) => {
                        return {
                          ...initialData,
                        }
                      })
                    if (value == 'Carousel')
                      setData((prev: any) => {
                        return {
                          ...initialCarouselData,
                        }
                      })
                    setType(value)
                  }}
                >
                  <SelectTrigger>
                    <SelectValue>{type}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value={'Normal'}>Normal</SelectItem>
                      <SelectItem value={'Carousel'}>Carousel</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              {type == 'Normal' && (
                <div className="w-full space-y-3 mt-5 px-1">
                  <div className="w-full space-y-2  p-2 border-t">
                    <Label>Header</Label>
                    <Select
                      value={data.header}
                      onValueChange={(value: any) =>
                        setData({
                          ...data,
                          header: value,
                        })
                      }
                    >
                      <SelectTrigger className="w-1/3 ">
                        <SelectValue>{data.header}</SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="none">none</SelectItem>
                          <SelectItem value="text">text</SelectItem>
                          <SelectItem value="media">media</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {data?.header == 'text' && (
                      <DynamicTextArea
                        data={data}
                        setData={setData}
                        indexKey={'headerText'}
                        maxVars={1}
                      />
                    )}
                    {data.header == 'media' && (
                      <MediaSelect
                        data={data}
                        setData={setData}
                        indexKey="headerMedia"
                      />
                    )}
                  </div>
                  <div className="w-full space-y-2  p-2  ">
                    <Label>
                      Body <span className="ml-1 text-rose-500">*</span>
                    </Label>
                    <DynamicTextArea
                      data={data}
                      setData={setData}
                      indexKey={'body'}
                    />
                  </div>
                  {/* <div className="w-full flex items-center gap-3 p-2 ">
                      <CustomInput
                        name="footer"
                        label="Footer"
                        value={data.footer}
                        setValue={handleChange}
                        type="text"
                      />
                    </div> */}
                  <div className="w-full space-y-2  p-2  ">
                    <Label>
                      Buttons{' '}
                      {data?.buttons?.length > 0
                        ? `(${data?.buttons?.length})`
                        : ''}
                    </Label>

                    <Buttons
                      data={data}
                      setData={setData}
                      indexKey={'buttons'}
                    />
                  </div>
                </div>
              )}

              {type == 'Carousel' && (
                <div className="w-full space-y-1 mt-5 px-1 flex flex-col items-center">
                  <div className="w-full space-y-2  p-2  ">
                    <Label>
                      Body <span className="ml-1 text-rose-500">*</span>
                    </Label>
                    <DynamicTextArea
                      data={data}
                      setData={setData}
                      indexKey={'body'}
                    />
                  </div>

                  <Label className="w-full flex justify-start py-4">
                    Carousel <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <div className="flex flex-col w-full max-w-sm max-md:max-w-xs items-start">
                    <Button
                      onClick={() => {
                        setData((prev: any) => {
                          return {
                            ...prev,
                            carousel: [
                              ...prev.carousel,
                              {
                                ...initCard,
                              },
                            ],
                          }
                        })
                      }}
                      disabled={data?.carousel?.length === 10}
                      variant={'outline'}
                      className="text-xs  text-indigo-600 flex items-center gap-1 justify-center"
                    >
                      <IoAddCircleOutline size={16} />
                      Add Card
                    </Button>
                    {data?.carousel?.length >= 10 && (
                      <span className="text-xs text-gray-500 italic">
                        {' '}
                        You can add up to 10 Cards
                      </span>
                    )}
                  </div>
                  <Carousel className="w-full max-w-sm max-md:max-w-xs bg-slate-100 rounded-md  ">
                    <CarouselContent>
                      {data?.carousel?.map((item: any, i: any) => {
                        return (
                          <CarouselItem key={i}>
                            <div className="p-1 pt-0 w-full">
                              <CarouselCard
                                data={data}
                                setData={setData}
                                index={i}
                              />
                            </div>
                          </CarouselItem>
                        )
                      })}
                    </CarouselContent>
                    <CarouselPrevious className="text-indigo-600 hover:text-indigo-900" />
                    <CarouselNext className="text-indigo-600 hover:text-indigo-900" />
                  </Carousel>
                </div>
              )}
              {error !== '' && (
                <div className="text-rose-600 text-sm">{error}</div>
              )}
              <div className="flex items-center gap-3 justify-end">
                <Button size="sm" onClick={updateTemplate} disabled={loading}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
function CarouselCard({ data, setData, index }: any) {
  const [card, setCard] = useState<any>(data.carousel[index])

  useEffect(() => {
    setData((prev: any) => {
      const carousel = [...prev.carousel]
      carousel[index] = card
      return {
        ...prev,
        carousel,
      }
    })
  }, [card])
  useEffect(() => {
    if (data.carousel) {
      const dataCard = data?.carousel[index]
      if (!deepEqual(dataCard, card)) {
        setCard(dataCard)
      }
    }
  }, [data?.carousel])

  return (
    <div className="w-full space-y-3 p-0 mt-5 px-1">
      <div className="flex items-center justify-between ">
        <Label>Card-{index + 1}</Label>
        <Button
          onClick={() => {
            setData((prev: any) => {
              const newCarousel = prev.carousel
                .slice(0, index)
                .concat(prev.carousel.slice(index + 1))
              return {
                ...prev,
                carousel: newCarousel,
              }
            })
          }}
          disabled={data?.carousel?.length === 1}
          variant={'ghost'}
          className="text-xs  flex items-center gap-1 justify-center hover:opacity-70"
        >
          <MdDelete size={20} />
        </Button>
      </div>

      <div className="w-full space-y-2  p-2 border-t">
        <Label>
          Header <span className="ml-1 text-rose-500">*</span>
        </Label>
        <MediaSelect data={card} setData={setCard} indexKey="headerMedia" />
      </div>
      <div className="w-full space-y-2  p-2  ">
        <Label>
          Body <span className="ml-1 text-rose-500">*</span>
        </Label>
        <DynamicTextArea data={card} setData={setCard} indexKey={'body'} />
      </div>
      <div className="w-full space-y-2  p-2  ">
        <Label>
          Buttons <span className="ml-1 text-rose-500">*</span>{' '}
          {card.buttons.length > 0 ? `(${card.buttons.length})` : ''}
        </Label>

        <span className="text-xs  italic block">
          {' '}
          All Cards must have the same Buttons type and number!
        </span>

        <Buttons
          data={card}
          setData={setCard}
          indexKey={'buttons'}
          isCard={true}
        />
      </div>
    </div>
  )
}

function DynamicTextArea({ data, setData, indexKey, maxVars }: any) {
  const [lastIndex, setLastIndex] = useState(0)
  const sampleKey = indexKey + 'Sample'
  const [placeHolders, setPlaceHolders] = useState<any>([])
  const [newVariable, setNewVariable] = useState<any>()
  const [openDropdownVariables, setOpenDropdownVariables] = useState(false)
  const [showPicker, setShowPicker] = useState(false)

  useEffect(() => {
    const placeholders = Array.from(
      data[indexKey].matchAll(/{{(\d+)}}/g),
      (match: any) => match[1]
    )
    setPlaceHolders(placeholders)
  }, [data[indexKey]])

  useEffect(() => {
    setLastIndex(placeHolders.length)
    const variables = data[sampleKey]
    const filteredVariables = Object.keys(variables).reduce(
      (acc: any, key: string) => {
        if (placeHolders.includes(key)) {
          acc[key] = variables[key]
        }
        return acc
      },
      {}
    )
    setData((prev: any) => ({
      ...prev,
      [sampleKey]: filteredVariables,
    }))
  }, [placeHolders])

  const handleTextChange = (e: any) => {
    const newText = e.target.value
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: newText,
      }
    })
  }

  const addStaticVariable = (variable: string) => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]:
          prev[indexKey] +
          ' {{' +
          variable.toUpperCase().replace(' ', '_') +
          '}} ',
      }
    })
    setOpenDropdownVariables(false)
  }

  // TODO: to be deleted
  const handleVariableValueChange = (index: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [sampleKey]: { ...prev[sampleKey], [index]: value },
    }))
  }
  const resetHandler = () => {
    setLastIndex(0)
    setPlaceHolders([])
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: '',
        [sampleKey]: {},
      }
    })
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: prev[indexKey] + emojiObject.emoji,
      }
    })
    setShowPicker(false)
  }

  return (
    <div className="py-2">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center relative flex-1">
          <DropdownMenu open={openDropdownVariables}>
            <DropdownMenuTrigger
              onClick={() => {
                setOpenDropdownVariables(true)
              }}
            >
              <Button
                variant={'outline'}
                className="text-xs  w-32 flex items-center gap-1 justify-center text-indigo-600"
              >
                {' '}
                <IoAddCircleOutline size={16} />
                Variables
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-96 p-2 max-h-96 overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col gap-2 py-2">
                  <h1>Select variable</h1>
                  {maxVars && placeHolders.length == 0 && (
                    <span className=" text-xs italic text-gray-500 ml-2 mb-2">
                      You can add up to {maxVars} variable!
                    </span>
                  )}
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpenDropdownVariables(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <DropdownMenuSeparator />

              {TEMPLATE_VARIABLES.map((variable: string, index: number) => {
                return (
                  <div
                    className={`flex flex-col gap-2 border-b p-2 rounded-md hover:cursor-pointer hover:bg-slate-100 ${index == TEMPLATE_VARIABLES.length - 1 && 'border-b-0'}`}
                    onClick={() => {
                      addStaticVariable(variable)
                    }}
                  >
                    <h1 className="text-sm text-indigo-600">{`{{${variable}}}`}</h1>
                    <span className=" text-xs text-gray-500 ml-2 mb-2">
                      Add the lead's {variable.toLowerCase().replace('_', ' ')}
                    </span>
                  </div>
                )
              })}

              <DropdownMenuSeparator />
              <div className="flex flex-col gap-1 p-1">
                <h1 className="text-sm">Other</h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Add other variable to be filled when sending this template
                </span>

                <div className="flex items-center gap-3 ">
                  <Input
                    value={newVariable}
                    onChange={(e: any) => setNewVariable(e.target.value)}
                    type="text"
                    placeholder="e.g. DISCOUNT"
                  />
                  <Button
                    onClick={() => {
                      addStaticVariable(newVariable)
                      setNewVariable('')
                    }}
                    disabled={maxVars && placeHolders.length >= maxVars}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>

              {/* 
              // TODO: to be deleted
              <div className="flex flex-col gap-2 py-2">
              <Button
                onClick={addVariable}
                disabled={maxVars && placeHolders.length >= maxVars}
                variant={'outline'}
                className="text-xs w-20 flex items-center gap-1 justify-center"
              >
                Add
                <IoAddCircleOutline size={16} />
              </Button>
            </div>
            <span className="text-xs ml-2 italic text-gray-500 flex flex-col items-start gap-2">
              * fields contain /name/ will be prefilled with lead name.
            </span>
            <div className="flex flex-col gap-2 max-h-32 overflow-y-scroll p-2">
              {placeHolders.map((placeholder: any, index: any) => (
                <Input
                  type={'text'}
                  className="w-44 h-8 max-h-[2rem]  min-h-[2rem]"
                  placeholder={`Enter content for {{${placeholder}}}`}
                  value={data[sampleKey][placeholder] || ''}
                  onChange={(e) =>
                    handleVariableValueChange(placeholder, e.target.value)
                  }
                />
              ))}
            </div> */}
            </DropdownMenuContent>
          </DropdownMenu>

          <Button
            variant="ghost"
            size="icon"
            onClick={() => setShowPicker((val) => !val)}
          >
            <HiOutlineEmojiHappy
              size={20}
              className="emoji-icon hover:cursor-pointer text-indigo-600"
            />
          </Button>

          <div className="picker-container  absolute">
            {showPicker && (
              <Picker
                pickerStyle={{ width: '100%' }}
                onEmojiClick={onEmojiClick}
              />
            )}
          </div>
        </div>

        <Button
          onClick={resetHandler}
          variant={'outline'}
          className="text-xs font-medium flex items-center"
        >
          reset
          <GiReturnArrow
            size={10}
            className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
          />
        </Button>
      </div>
      <div className=" py-2 px-4 mt-2 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <textarea
          className="px-0 w-full  mb-3 text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
          value={data[indexKey]}
          placeholder="Add a text..."
          onChange={handleTextChange}
          rows={4}
        ></textarea>
      </div>{' '}
    </div>
  )
}

function MediaSelect({ setData, data, indexKey }: any) {
  const fileRef = useRef<any>()
  const sampleKey = indexKey + 'SampleFile'

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setData((prev: any) => {
      return { ...prev, [sampleKey]: null }
    })
  }

  const handleMediaFileChange = (event: any) => {
    const file = event.target.files[0]
    setData((prev: any) => {
      return { ...prev, [sampleKey]: file }
    })
  }
  return (
    <div>
      <div className=" flex items-start my-4">
        <Button
          className={` transition-all w-20 h-8 rounded-none ${data[indexKey] == 'IMAGE' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
          onClick={() => {
            replaceFileHandler()
            setData((prev: any) => {
              return {
                ...prev,
                [indexKey]: 'IMAGE',
              }
            })
          }}
        >
          Image
        </Button>
        <Button
          className={` transition-all w-20 h-8 rounded-none ${data[indexKey] == 'VIDEO' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
          onClick={() => {
            replaceFileHandler()
            setData((prev: any) => {
              return {
                ...prev,
                [indexKey]: 'VIDEO',
              }
            })
          }}
        >
          Video
        </Button>
        <Button
          className={` transition-all w-20 h-8 rounded-none ${data[indexKey] == 'DOCUMENT' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
          onClick={() => {
            replaceFileHandler()
            setData((prev: any) => {
              return {
                ...prev,
                [indexKey]: 'DOCUMENT',
              }
            })
          }}
        >
          Document
        </Button>
      </div>

      <div className="flex mt-2  flex-col gap-2">
        <div className="w-full items-center flex justify-between">
          <label
            className={cn(
              buttonVariants({
                variant: 'ghost',
                size: 'icon',
              }),
              'h-9 w-32 border text-left text-xs',
              'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
            )}
          >
            Add Sample
            <input
              type="file"
              ref={fileRef}
              accept={
                data[indexKey] == 'IMAGE'
                  ? 'image/*'
                  : data[indexKey] == 'VIDEO'
                    ? 'video/*'
                    : '*'
              }
              style={{ display: 'none' }}
              className="cursor-pointer"
              onChange={handleMediaFileChange}
            />
          </label>

          <Button
            onClick={replaceFileHandler}
            variant={'outline'}
            className="text-xs font-medium flex items-center"
          >
            reset
            <GiReturnArrow
              size={10}
              className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
            />
          </Button>
        </div>
        <span className="text-xs">{data[sampleKey]?.name}</span>
      </div>
    </div>
  )
}

function Buttons({ data, setData, indexKey, isCard }: any) {
  const [openAddButton, setOpenAddButton] = useState(false)

  const [quickReplyText, setQuickReplyText] = useState(
    TEMPLATE_BUTTONS.QUICK_REPLY.text
  )
  const [quickReplyError, setQuickReplyError] = useState('')
  const isQuickReplyDisabled = data[indexKey]?.find(
    (item: any) =>
      item.type == TEMPLATE_BUTTONS.QUICK_REPLY.type &&
      item.text == TEMPLATE_BUTTONS.QUICK_REPLY.text
  )

  const [urlText, setUrlText] = useState('')
  const [urlValue, setUrlValue] = useState('')
  const [urlError, setUrlError] = useState('')
  const isUrlDisabled = data[indexKey]?.find(
    (item: any) => item.type == TEMPLATE_BUTTONS.URL.type
  )
  const [phoneNumberText, setPhoneNumberText] = useState('')
  const [phoneNumberValue, setPhoneNumberValue] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState('')
  const isPhoneNumberDisabled = data[indexKey]?.find(
    (item: any) => item.type == TEMPLATE_BUTTONS.PHONE_NUMBER.type
  )

  const [customText, setCustomText] = useState('')
  const [customError, setCustomError] = useState('')
  const [addedCustomTexts, setAddedCustomTexts] = useState<any>([])

  useEffect(() => {
    if (data[indexKey]) {
      const url = data[indexKey]?.find(
        (item: any) => item.type == TEMPLATE_BUTTONS.URL.type
      )
      setUrlValue(url?.url || '')
      setUrlText(url?.text || '')

      const phoneNumber = data[indexKey]?.find(
        (item: any) => item.type == TEMPLATE_BUTTONS.PHONE_NUMBER.type
      )
      setPhoneNumberValue(phoneNumber?.phone_number || '')
      setPhoneNumberText(phoneNumber?.text || '')
      const customTexts: any = []
      for (let i = 0; i < data[indexKey]?.length; i++) {
        if (
          data[indexKey][i].type == TEMPLATE_BUTTONS.QUICK_REPLY.type &&
          data[indexKey][i].text != TEMPLATE_BUTTONS.QUICK_REPLY.text
        )
          customTexts.push(data[indexKey][i].text)
      }
      setAddedCustomTexts([...customTexts])
    }
  }, [data[indexKey]])

  const addButton = (button: {
    type: string
    text: string
    url?: string
    phone_number?: string
  }) => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: [...prev[indexKey], { ...button }],
      }
    })
    setOpenAddButton(false)
  }
  const resetHandler = () => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: [],
      }
    })
    setQuickReplyError('')
    // setQuickReplyText('')
    setPhoneNumberError('')
    setPhoneNumberText('')
    setPhoneNumberValue('')
    setUrlError('')
    setUrlText('')
    setUrlValue('')
    setCustomText('')
    setCustomError('')
  }

  return (
    <div className="py-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2  relative flex-1">
          <DropdownMenu open={openAddButton}>
            <DropdownMenuTrigger
              className=" disabled:opacity-60"
              disabled={
                isCard
                  ? data?.buttons?.length >= 2
                  : data?.buttons?.length >= 10
              }
              onClick={() => {
                setOpenAddButton(true)
              }}
            >
              <Button
                variant={'outline'}
                disabled={
                  isCard
                    ? data?.buttons?.length >= 2
                    : data?.buttons?.length >= 10
                }
                className="text-xs  w-32 flex items-center gap-1 justify-center text-indigo-600"
              >
                {' '}
                <IoAddCircleOutline size={16} />
                Button
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="w-96 p-2 max-h-[30rem] overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col gap-2 py-2">
                  <h1>Select button</h1>
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpenAddButton(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <DropdownMenuSeparator />

              <div
                className={`flex flex-col gap-1 border-b p-2 rounded-md  ${isQuickReplyDisabled ? 'opacity-60' : ''} `}
              >
                <h1 className="text-sm text-indigo-600">
                  {TEMPLATE_BUTTONS.QUICK_REPLY.label}
                </h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Give lead the ability to unsubscribing from receiving messages
                  from this system
                </span>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Displayed <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <Input
                    value={quickReplyText}
                    onChange={(e: any) => setQuickReplyText(e.target.value)}
                    placeholder="e.g. Stop Receiving Messages"
                    disabled={true}
                  />
                </div>
                <div className="flex justify-between">
                  {quickReplyError ? (
                    <span className=" text-xs text-red-500">
                      {quickReplyError}
                    </span>
                  ) : (
                    <div />
                  )}{' '}
                  <Button
                    onClick={() => {
                      if (!quickReplyText)
                        return setQuickReplyError('displayed text is required')

                      setQuickReplyError('')
                      addButton({
                        type: TEMPLATE_BUTTONS.QUICK_REPLY.type,
                        text: quickReplyText,
                      })
                    }}
                    disabled={isQuickReplyDisabled}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>

              <div
                className={`flex flex-col gap-2 border-b p-2 rounded-md  ${isUrlDisabled ? 'opacity-60' : ' '} `}
              >
                <h1 className="text-sm text-indigo-600">
                  {TEMPLATE_BUTTONS.URL.label}
                </h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Give lead the ability to navigate to some URL
                </span>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Displayed <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <Input
                    value={urlText}
                    onChange={(e: any) => setUrlText(e.target.value)}
                    placeholder="e.g. Visit Website"
                    disabled={isUrlDisabled}
                  />
                </div>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Value <span className="ml-1 text-rose-500">*</span>
                  </Label>

                  <Input
                    value={urlValue}
                    onChange={(e: any) => setUrlValue(e.target.value)}
                    placeholder="e.g. https://doctorna.com"
                    disabled={isUrlDisabled}
                  />
                </div>
                <div className="flex justify-between">
                  {urlError ? (
                    <span className=" text-xs text-red-500">{urlError}</span>
                  ) : (
                    <div />
                  )}
                  <Button
                    onClick={() => {
                      if (!urlText)
                        return setUrlError('displayed text is required')
                      if (!isURL(urlValue))
                        return setUrlError('valid url is required')

                      setUrlError('')
                      addButton({
                        type: TEMPLATE_BUTTONS.URL.type,
                        text: urlText,
                        url: urlValue,
                      })
                    }}
                    disabled={isUrlDisabled}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>

              <div
                className={`flex flex-col gap-2 border-b p-2 rounded-md  ${isPhoneNumberDisabled ? 'opacity-60' : ' '} `}
              >
                <h1 className="text-sm text-indigo-600">
                  {TEMPLATE_BUTTONS.PHONE_NUMBER.label}
                </h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Give lead the ability to Call some Phone Number
                </span>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Displayed <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <Input
                    value={phoneNumberText}
                    onChange={(e: any) => setPhoneNumberText(e.target.value)}
                    placeholder="e.g. Call"
                    disabled={isPhoneNumberDisabled}
                  />
                </div>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Value <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <PhoneInput
                    country={'ae'}
                    value={phoneNumberValue}
                    inputProps={{
                      id: { id: phoneNumberValue },
                      name: { id: phoneNumberValue },
                      required: true,
                    }}
                    disabled={isPhoneNumberDisabled}
                    onChange={(value: any) => setPhoneNumberValue(value)}
                  />
                </div>
                <div className="flex justify-between">
                  {phoneNumberError ? (
                    <span className=" text-xs text-red-500">
                      {phoneNumberError}
                    </span>
                  ) : (
                    <div />
                  )}
                  <Button
                    onClick={() => {
                      if (!phoneNumberText)
                        return setPhoneNumberError('displayed text is required')
                      if (!isPhone(phoneNumberValue))
                        return setPhoneNumberError(
                          'valid phone number is required'
                        )

                      setPhoneNumberError('')
                      addButton({
                        type: TEMPLATE_BUTTONS.PHONE_NUMBER.type,
                        text: phoneNumberText,
                        phone_number: phoneNumberValue,
                      })
                    }}
                    disabled={isPhoneNumberDisabled}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>

              <div className={`flex flex-col gap-2 border-b p-2 rounded-md   `}>
                <h1 className="text-sm text-indigo-600">Custom</h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Give lead the ability to Click on a custom quick reply button
                </span>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Displayed <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <Input
                    value={customText}
                    onChange={(e: any) => {
                      if (e.target.value != TEMPLATE_BUTTONS.QUICK_REPLY.text)
                        setCustomText(e.target.value)
                    }}
                    placeholder="e.g. Confirm"
                  />
                </div>

                <div className="flex justify-between">
                  {customError ? (
                    <span className=" text-xs text-red-500">{customError}</span>
                  ) : (
                    <div />
                  )}
                  <Button
                    onClick={() => {
                      if (!customText)
                        return setCustomError('displayed text is required')

                      setCustomError('')
                      addButton({
                        type: TEMPLATE_BUTTONS.QUICK_REPLY.type,
                        text: customText,
                      })
                      setAddedCustomTexts((prev: any) => [...prev, customText])
                      setCustomText('')
                    }}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
                <div className="flex flex-col gap-1 p-2">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Added Custom{' '}
                  </Label>
                  <div className="flex  flex-wrap gap-1 p-2">
                    {addedCustomTexts?.map((item: any) => {
                      return (
                        <span className="ml-2 text-xs px-2 py-1 rounded-md border ">
                          {item}
                        </span>
                      )
                    })}
                  </div>
                </div>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
          {isCard && data?.buttons?.length >= 2 && (
            <span className="text-xs text-gray-500 italic">
              {' '}
              You can add up to 2 Buttons
            </span>
          )}
          {!isCard && data?.buttons?.length >= 10 && (
            <span className="text-xs text-gray-500 italic">
              {' '}
              You can add up to 10 Buttons
            </span>
          )}
        </div>

        <Button
          onClick={resetHandler}
          variant={'outline'}
          className="text-xs font-medium flex items-center"
        >
          reset
          <GiReturnArrow
            size={10}
            className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
          />
        </Button>
      </div>
      <div className="flex "></div>
    </div>
  )
}

/* Utils methods to get init data from template */

const replacePlaceHolders = (str: string, values: any = {}) => {
  return str.replace(
    /{{(\d+)}}/g,
    (_: any, num: any) => `{{${values[+num - 1] || ''}}}`
  )
}

const getInitCard = (template: any) => {
  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )
  const buttonsComponent = template?.components?.find(
    (component: any) => component.type == 'BUTTONS'
  )
  const headerFormat = headerComponent?.format
  const bodyVariables = bodyComponent?.example?.body_text?.[0]

  return {
    headerMedia: headerFormat,
    headerMediaSampleFile: null,
    body: bodyComponent?.text
      ? replacePlaceHolders(bodyComponent.text, bodyVariables)
      : '',
    bodySample: {},
    buttons:
      buttonsComponent?.buttons?.map((button: any) => {
        return {
          type: button.type,
          text: button.text,
          ...(button.url && { url: button.url }),
          ...(button.phone_number && { phone_number: button.phone_number }),
        }
      }) || [],
  }
}

const getInitData = (template: any) => {
  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )
  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )
  const carouselComponent = template?.components?.find(
    (component: any) => component.type == 'CAROUSEL'
  )
  const headerFormat = headerComponent?.format
  const headerTextVariables = headerComponent?.example?.header_text
  const bodyVariables = bodyComponent?.example?.body_text?.[0]
  const buttonsComponent = template?.components?.find(
    (component: any) => component.type == 'BUTTONS'
  )

  return {
    header:
      headerFormat == 'TEXT'
        ? 'text'
        : headerFormat === 'IMAGE' ||
            headerFormat == 'VIDEO' ||
            headerFormat == 'DOCUMENT'
          ? 'media'
          : 'none',
    headerText: headerComponent?.text
      ? replacePlaceHolders(headerComponent.text, headerTextVariables)
      : '',
    headerTextSample: {},
    headerMedia:
      !headerFormat || headerFormat == 'TEXT' ? 'IMAGE' : headerFormat,
    headerMediaSampleFile: null,
    body: bodyComponent?.text
      ? replacePlaceHolders(bodyComponent.text, bodyVariables)
      : '',
    bodySample: {},
    buttons:
      buttonsComponent?.buttons?.map((button: any) => {
        return {
          type: button.type,
          text: button.text,
          ...(button.url && { url: button.url }),
          ...(button.phone_number && { phone_number: button.phone_number }),
        }
      }) || [],
    ...(carouselComponent && {
      carousel: carouselComponent.cards.map((card: any) => {
        return getInitCard(card)
      }),
    }),
  }
}

const getType = (template: any) => {
  return template?.components?.find(
    (component: any) => component.type == 'CAROUSEL'
  )
    ? 'Carousel'
    : 'Normal'
}
