import { Button } from 'components/ui/button'
import { isURL } from 'lib/validation'

const defaultImage = '/logo.jpeg'

export default function ViewAnswer({
  answer,
  close,
}: {
  answer: KBAnswer | null
  close: () => void
}) {
  const formatValue = (value: string) => {
    if (isURL(value))
      return (
        <a href={value} target="_blank" rel="noreferrer">
          {value}
        </a>
      )
    else return value
  }

  return (
    <>
      <div className="">
        <div className="grid grid-cols-2">
          <div className="flex justify-center items-center">
            <img
              src={
                isURL(answer?.fields[0]?.value ?? '')
                  ? answer?.fields[0].value
                  : defaultImage
              }
              style={{
                width: '450px',
                height: '450px',
                objectFit: 'contain',
              }}
            />
          </div>
          <div className="max-h-[470px] pl-2 overflow-y-scroll">
            {answer?.fields.slice(1).map((field, index) => (
              <div className="" key={index}>
                <p className="mb-2">
                  <span className="font-bold">{field.key}: </span>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {formatValue(field.value)}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-end mt-2">
          <Button variant={'outline'} onClick={close}>
            Close
          </Button>
        </div>
      </div>
    </>
  )
}
