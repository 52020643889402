import { Avatar, AvatarImage } from 'components/ui/avatar'

export default function MetaProfile({ profile }: any) {
  return (
    <div className="h-full w-full">
      <div className="w-full space-y-3  px-1">
        <div className="flex w-full items-center justify-between">
          <div className="w-full flex items-center gap-3">
            <span>
              <label htmlFor="imageUpload">
                <Avatar className="flex">
                  <AvatarImage
                    src={
                      profile.picture.data.url ||
                      'https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg'
                    }
                    alt="@shadcn"
                    width={6}
                    height={6}
                    className="w-10 h-10 "
                  />
                </Avatar>
              </label>
            </span>
            <h1 className="text-lg font-semibold capitalize">{profile.name}</h1>
          </div>
        </div>
        <div className="w-full flex items-center gap-3"></div>
      </div>
    </div>
  )
}
