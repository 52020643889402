import { ca } from 'date-fns/locale'
import { api } from '../api'

type GroupedAnswersResponse = {
  category: KBCategory
  categoryId: string
  answers: KBAnswer[]
}[]

export const KBApi = api.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Categories
     */
    getCategories: builder.query<KBCategory[], void>({
      query: () => '/kb/categories',
      providesTags: ['KB'],
    }),
    createCategory: builder.mutation({
      query: (date: { name: string; fields: string[] }) => ({
        url: '/kb/categories',
        method: 'POST',
        body: date,
      }),
      invalidatesTags: ['KB'],
    }),
    updateCategory: builder.mutation({
      query: (date: { id: string; name: string; fields: string[] }) => ({
        url: `/kb/categories/${date.id}`,
        method: 'PUT',
        body: {
          name: date.name,
          fields: date.fields,
        },
      }),
      invalidatesTags: ['KB'],
    }),
    deleteCategory: builder.mutation({
      query: (id: string) => ({
        url: `/kb/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['KB'],
    }),
    /**
     * Answers
     */
    getAnswers: builder.query<KBAnswer[], void>({
      query: () => `/kb/answers`,
      providesTags: ['KB'],
    }),
    getAnswersGrouped: builder.query<GroupedAnswersResponse, void>({
      query: () => `/kb/answers/grouped`,
      providesTags: ['KB'],
    }),
    createAnswer: builder.mutation({
      query: (data: {
        category: string
        fields: { key: string; value: string }[]
      }) => ({
        url: '/kb/answers',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['KB'],
    }),
    updateAnswer: builder.mutation({
      query: (data: {
        id: string
        category?: string
        fields: { key: string; value: string }[]
      }) => ({
        url: `/kb/answers/${data.id}`,
        method: 'PUT',
        body: {
          category: data.category,
          fields: data.fields,
        },
      }),
      invalidatesTags: ['KB'],
    }),
    deleteAnswer: builder.mutation({
      query: (id: string) => ({
        url: `/kb/answers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['KB'],
    }),
    uploadImage: builder.mutation({
      query: (data: { formData: FormData }) => ({
        url: '/kb/answers/image',
        method: 'POST',
        formData: true,
        Accept: '*/*',
        body: data.formData,
      }),
      invalidatesTags: ['KB'],
    }),
  }),
})

export const {
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,

  useCreateAnswerMutation,
  useUpdateAnswerMutation,
  useDeleteAnswerMutation,
  useGetAnswersQuery,
  useGetAnswersGroupedQuery,

  useUploadImageMutation,
} = KBApi
