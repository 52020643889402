import { Input } from 'components/ui/input'
import { useState } from 'react'
import Messenger from '../contacts-list/messenger'
import WhatsApp from '../contacts-list/whats-app'
import { Actions } from '../components/actions'
import Filters from '../contacts-list/filter'
import { useDebounce } from 'use-debounce'

export default function Sidebar({
  activeTab,
  selectedAccountId,
  selectedConversation,
  setSelectedConversation,
  selectedLead,
  setSelectedLead,
  conversationLeads,
  setConversationLeads,
  refreshContacts,
  mobileView,
}: any) {
  const [keyword, setKeyword] = useState('')
  const [debouncedKeyword] = useDebounce(keyword, 700)

  const [filters, setFilters] = useState<{
    read: any
    campaign: any
    tags: any[]
    agents: any[]
  }>({ campaign: 'Not_Campaign', read: 'All', tags: [], agents: [] })

  return (
    <div className="overflow-y-hidden  overflow-x-hidden md:min-w-fit flex flex-col gap-3 pl-1 md:pl-0">
      <div
        className={`flex justify-between items-center gap-2 px-1 ${mobileView && 'mr-6 mt-2'}`}
      >
        <Input
          type="text"
          placeholder="Search, at least 2 chars..."
          value={keyword}
          className="bg-white shadow focus-visible:ring-1 w-full min-h-[2rem]"
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Filters
          setKeyword={setKeyword}
          setFilters={setFilters}
          selectedAccountId={selectedAccountId}
        />
        {!mobileView && (
          <Actions
            selectedAccountId={selectedAccountId}
            mobileView={mobileView}
          />
        )}
      </div>

      {activeTab === 'WhatsApp' ? (
        <WhatsApp
          keyword={debouncedKeyword}
          filters={filters}
          refreshContacts={refreshContacts}
          selectedAccountId={selectedAccountId}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          conversationLeads={conversationLeads}
          setConversationLeads={setConversationLeads}
        />
      ) : activeTab === 'Messenger' || activeTab === 'Instagram' ? (
        <Messenger
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
        />
      ) : (
        <div className="w-full h-full grid place-content-center p-4">
          No contacts
        </div>
      )}
    </div>
  )
}
