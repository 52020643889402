import { useMarkWhatsappConversationAsUnReadMutation } from 'app/features/whatsapp'

import { toast } from 'components/ui/use-toast'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'

export const WhatsappMarkConversationAsUnread = ({
  open,
  setOpen,
  conversationId,
  setReadConversations,
  setRefreshContacts,
}: any) => {
  const [markAsRead, { isLoading }] =
    useMarkWhatsappConversationAsUnReadMutation()

  const handleMarkAsUnReadConversation = async () => {
    try {
      const result: any = await markAsRead(conversationId)
      if (result?.error) {
        toast({
          title: 'Error occur!',
          description: 'Failed to mark conversation as unread',
        })
        return
      }
      setRefreshContacts((prev: any) => !prev)
      toast({
        title: 'Updated successfully',
        description: 'Conversation updated successfully',
      })

      setOpen(false)
    } catch (err) {
      toast({
        title: 'Error occur!',
        description: 'Failed to mark conversation as unread',
      })
    }
  }

  useEffect(() => {
    handleMarkAsUnReadConversation()
  }, [])

  return <></>
}
