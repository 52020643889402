enum SettingScreenName {
  sources = 'sources',
  groups = 'groups',
  followUp = 'follow-up',
  leadForm = 'leadForm',
  notQualifiedReasons = 'not-qualified-reasons',
  brand = 'brand',
  activeTabs = 'active-tabs',
  insurance = 'insurance',
  social = 'social',
  hierarchy = 'hierarchy',
  offers = 'offers',
  knowledgeBank = 'knowledge-bank',
}

export default SettingScreenName
