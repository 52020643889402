import { useGetMetaAdsQuery } from 'app/features/meta-ads'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import { useEffect, useState } from 'react'
import { DateFilters } from './date-filters'
import { useParams } from 'react-router-dom'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import Pagination from 'components/table/pagination'
import ViewLeadsOfMetaAd from './view-conversations'
import { Button } from 'components/ui/button'
import {
  TbAd,
  TbChevronLeft,
  TbFileTypeXls,
  TbUsers,
  TbWindowMaximize,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'components/ui/use-toast'
import axios from 'lib/axios'
import dayjs from 'lib/dayjs'
import Stat from 'components/shared/dashboard-stat/dashboard-stat'
import { useGetStatisticsQuery } from 'app/features/whatsappStats'

export default function MetaAds() {
  const navigate = useNavigate()
  const params = useParams()

  const whatsappAccount = params.accountId || ''
  const [totalMetaAdMessages, setTotalMetaAdMessages] = useState<number>()
  const [totalLeadsViaMetaAds, setTotalLeadsViaMetaAds] = useState<number>()

  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [loadingExcel, setLoadingExcel] = useState(false)

  const [viewDialog, setViewDialog] = useState<{
    visible: boolean
    sourceId?: string | undefined
  }>({
    visible: false,
    sourceId: undefined,
  })

  const { data, isLoading, isFetching, isError } = useGetMetaAdsQuery({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
    whatsappAccount,
    ...(startDate && {
      from: startDate,
    }),
    ...(endDate && {
      to: endDate,
    }),
  })

  const { data: statsData } = useGetStatisticsQuery({
    whatsappAccount: whatsappAccount,
  })

  useEffect(() => {
    if (statsData) {
      const { totalMetaAdMessages, totalLeadsViaMetaAds } = statsData
      setTotalMetaAdMessages(totalMetaAdMessages)
      setTotalLeadsViaMetaAds(totalLeadsViaMetaAds)
    }
  }, [statsData])

  const getURLType = (url: string) => {
    if (url.includes('fb.me') || url.includes('facebook')) {
      return 'Facebook'
    } else if (url.includes('instagram.com')) {
      return 'Instagram'
    } else return ''
  }

  /**
   * Generates an Excel file and download it
   */
  const exportToExcel = async () => {
    if (loadingExcel) return

    setLoadingExcel(true)

    const { dismiss } = toast({
      title: 'Generating Excel',
      description:
        'Please wait, Excel is being generated. This may take a few seconds.',
      duration: 30 * 1000,
    })

    try {
      const res = await axios.get('/meta-ads/export', {
        params: {
          from: startDate,
          to: endDate,
          whatsappAccount: whatsappAccount,
        },
        responseType: 'blob',
      })

      downloadFile(res.data)

      toast({
        title: 'Success',
        description: 'File generated successfully',
      })

      dismiss()
    } catch (error) {
      toast({
        title: 'Error occurred',
        description: 'An error occurred while generating file',
      })
    }

    setLoadingExcel(false)
  }

  /**
   * Downloads the given data as a file
   * @param data The  data to download
   */
  function downloadFile(data: string) {
    const blob = new Blob([data], { type: 'application/xlsx' })

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    link.href = url
    link.download = `Doctorna - Meta Ads ${dayjs().format('YYYYMMDD HH:mm')}.xlsx`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <div className="p-6">
        <div className="flex justify-between mb-2">
          <div>
            <Button variant={'outline'} onClick={() => navigate(-1)}>
              <TbChevronLeft />
            </Button>
          </div>
        </div>

        <div className="flex justify-between mb-6">
          <div>
            <h1 className="text-lg font-semibold capitalize">Meta Ads</h1>
            <div className="text-sm mt-2">View list of meta ads</div>
          </div>
          <div className="flex items-center">
            <DateFilters setStartDate={setStartDate} setEndDate={setEndDate} />
            <Button onClick={exportToExcel} className="mx-2 mt-2">
              <TbFileTypeXls size={20} className="mr-2" />
              Export
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-8 gap-4 m-4 max-lg:grid-cols-4 max-md:grid-cols-2">
          <Stat
            number={totalMetaAdMessages}
            title="Ads"
            icon={<TbAd size={24} color="gray" />}
            description="Total number of Meta Ads clicked by users"
          />
          <Stat
            number={totalLeadsViaMetaAds}
            title="Leads"
            icon={<TbUsers size={24} color="gray" />}
            description="Total leads via Meta Ads"
          />
        </div>

        {isError && (
          <div className="h-40 grid place-content-center">
            <Error message="Server error, contact administrator please" />
          </div>
        )}

        <div className="h-full w-full">
          <div className="mt-8">
            <div className="w-full overflow-hidden overflow-x-scroll mb-10">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="text-center border-b w-full ">
                    <th className="text-sm">Source</th>
                    <th className="text-sm">URL</th>
                    <th className="text-sm">Source type</th>
                    <th className="text-sm">Caption</th>
                    <th className="text-sm">Lead count</th>
                    <th className="text-sm">View</th>
                  </tr>
                </thead>
                <tbody>
                  {(isFetching || isLoading) && (
                    <tr>
                      <td colSpan={6}>
                        <div className="w-full flex justify-center my-10">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                  {!isError && !isLoading && !isFetching && data?.items && (
                    <>
                      {data?.items?.map((el, index) => (
                        <tr
                          key={index}
                          className="w-full border-b border-gray-100 last:border-b-0 py-1"
                        >
                          <td className="text-sm text-center">
                            {getURLType(el.referral.source_url)}
                          </td>
                          <td className="text-sm text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                            <a
                              href={el?.referral.source_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {el?.referral.source_url}
                            </a>
                            {el?.referral.source_url}
                          </td>
                          <td className="text-sm text-center ">
                            {el?.referral.source_type}
                          </td>
                          <td>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                                    {el?.referral.body}
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p style={{ whiteSpace: 'pre-line' }}>
                                    {el.referral.body}
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </td>
                          <td className="text-sm text-center">
                            {el?.leadCount}
                          </td>
                          <td className="flex justify-center">
                            <Button
                              variant={'ghost'}
                              size={'icon'}
                              onClick={() => {
                                setViewDialog({
                                  visible: true,
                                  sourceId: el?.referral.source_id,
                                })
                              }}
                            >
                              <TbWindowMaximize />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
              {!isLoading && !isFetching && !data?.items.length && (
                <div className="h-40 w-full grid place-content-center text-sm">
                  No data found
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          {data && data?.count > 0 && (
            <Pagination
              count={data?.count}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>

        <AlertDialog
          open={viewDialog.visible}
          onOpenChange={() => setViewDialog({ visible: false })}
        >
          <AlertDialogContent className="sm:max-w-[900px] md:max-w-[900px] bg-white dark:bg-gray-900 pr-3">
            <ViewLeadsOfMetaAd
              whatsappAccount={whatsappAccount}
              sourceId={viewDialog.sourceId}
              closeDialog={() =>
                setViewDialog({ visible: false, sourceId: undefined })
              }
            />
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  )
}
