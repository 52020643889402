import { useUpdateStatusMutation } from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Switch } from 'components/ui/switch'
import { Textarea } from 'components/ui/textarea'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useOutstandingAmountQuery } from 'app/features/sales'
import dayjs from 'lib/dayjs'
import { TbShoppingBag } from 'react-icons/tb'

export default function Status({ lead, open, setOpen }: any) {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [isPaid, setIsPaid] = useState(true)
  const [amount, setAmount] = useState(0)
  const [isShowComment, setIsShowComment] = useState('')
  const [error, setError] = useState('')
  const [updateStatus, { isLoading }] = useUpdateStatusMutation()

  const { data: outstandingData } = useOutstandingAmountQuery(lead.id, {
    refetchOnFocus: true,
  })

  async function onUpdateStatus() {
    if (!status) return setError('Status is required')
    if (status == 'SHOW' && isPaid && !amount) {
      return setError('Amount is required')
    }

    if (outstandingData?.sale) {
      const outstanding = outstandingData?.outstanding || 0

      if (outstanding && amount > outstanding) {
        setError(
          'Amount should be less or equal to outstanding amount (' +
            outstanding +
            ')'
        )
        return
      }
    }

    setLoading(true)
    setError('')
    try {
      await updateStatus({
        id: lead.id,
        data: { status, isPaid, ...(isPaid && { amount }), isShowComment },
      }).unwrap()

      setOpen(false)
    } catch (error) {
      if (error && [404, 406, 409].includes((error as any).status)) {
        setError((error as any).data)
      } else setError('Error occurred during saving')
    }
    setLoading(false)
  }

  useEffect(() => {
    setError('')
    setIsPaid(true)
    setAmount(0)
  }, [status])

  useEffect(() => {
    if (!isPaid) setAmount(0)
  }, [isPaid])

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="outline">Send WhatsApp</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <>
          {!loading || !isLoading ? (
            <>
              <AlertDialogHeader>
                <AlertDialogTitle>Change lead status</AlertDialogTitle>
                <div className="flex gap-3 pb-4">
                  <div
                    onClick={() => setStatus('SHOW')}
                    className={cn(
                      status === 'SHOW' ? 'border-indigo-600' : 'border',
                      'px-3 py-1 rounded-md cursor-pointer border-2'
                    )}
                  >
                    Show
                  </div>
                  <div
                    onClick={() => setStatus('NO_SHOW')}
                    className={cn(
                      status === 'NO_SHOW' ? 'border-indigo-600' : 'border',
                      'px-3 py-1 rounded-md cursor-pointer border-2'
                    )}
                  >
                    No Show
                  </div>
                </div>
                {status == 'SHOW' && (
                  <>
                    <div
                      className="flex p-4 pt-2 justify-between"
                      style={{ width: '100%' }}
                    >
                      <div className="flex gap-2 items-center">
                        <Label htmlFor="paid">Paid</Label>

                        <Switch
                          id="paid"
                          checked={isPaid}
                          onCheckedChange={setIsPaid}
                          aria-readonly
                        />
                      </div>
                      <div className="flex gap-4 items-center">
                        <Label htmlFor="amount">
                          Amount
                          <span className="ml-1 text-rose-500">*</span>
                        </Label>
                        <div className=" items-center flex relative">
                          <Input
                            placeholder="Enter amount"
                            name="amount"
                            type="number"
                            min={1}
                            max={outstandingData?.outstanding || 10000000}
                            value={amount}
                            onChange={(e: any) => {
                              setAmount(parseInt(e.target.value))
                            }}
                            className="w-full"
                            required
                            disabled={!isPaid}
                          />
                          <span className="text-gray-400 text-xs hover:cursor-pointer hover:text-gray-900 absolute right-8">
                            AED&nbsp;
                          </span>
                        </div>
                      </div>
                    </div>
                    {outstandingData?.sale && (
                      <>
                        <Alert>
                          <AlertTitle className="flex justify-between">
                            <div>Offer sale</div>
                            <TbShoppingBag
                              size={20}
                              className=" text-gray-500"
                            />
                          </AlertTitle>
                          <AlertDescription>
                            <div className="gap-2 text-xs">
                              <div className=" mt-6 mb-2">
                                This booking is linked to a sale of&nbsp;
                                {outstandingData?.sale?.offer?.name} offer
                                on&nbsp;
                                {dayjs(outstandingData?.sale?.date).format(
                                  'DD-MM HH:mm'
                                )}
                              </div>
                              <div className="flex justify-between">
                                <div>
                                  Sessions: {outstandingData.sale.sessionsMade}{' '}
                                  /{' '}
                                  {outstandingData?.sale?.offer?.sessionsNumber}
                                </div>
                                <div className="">
                                  Offer price:&nbsp;
                                  {outstandingData?.sale?.offer?.price} AED
                                </div>
                                <div className="">
                                  Outstanding:&nbsp;
                                  {outstandingData?.outstanding} AED
                                </div>
                              </div>
                            </div>

                            <div className="mt-8 mb-2">Payments history:</div>
                            <table
                              className={cn(
                                'text-sm w-full border-collapse text-[13px] table-fixed'
                              )}
                            >
                              <thead>
                                <tr className="text-center border-b w-full ">
                                  <th className="w-2/4 ">Amount</th>
                                  <th className="w-2/4 ">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {outstandingData?.sale?.payments?.map(
                                  (payment: any) => (
                                    <tr
                                      key={payment.id}
                                      className="text-center border-b w-full"
                                    >
                                      <td>
                                        <div className="xl:w-20 ">
                                          {payment.amount} AED
                                        </div>
                                      </td>
                                      <td>
                                        {dayjs(payment.date).format(
                                          'DD/MM/YYYY HH:mm'
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                                {outstandingData?.sale?.payments &&
                                  outstandingData?.sale?.payments?.length ===
                                    0 && (
                                    <tr>
                                      <td
                                        colSpan={3}
                                        className="text-center text-gray-500"
                                      >
                                        No payments found
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </AlertDescription>
                        </Alert>
                      </>
                    )}
                  </>
                )}
                <Textarea
                  className="mt-1O"
                  value={isShowComment}
                  onChange={(e: any) => setIsShowComment(e.target.value)}
                  placeholder="Add comment"
                />
              </AlertDialogHeader>

              <AlertDialogFooter className="flex flex-row justify-between items-center w-full  ">
                <div className=" mr-auto">
                  {error !== '' && (
                    <p className="text-rose-500 text-sm">{error}</p>
                  )}
                </div>
                <div className="flex justify-center items-center gap-2">
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button onClick={onUpdateStatus}>Update</Button>
                </div>
              </AlertDialogFooter>
            </>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </>
      </AlertDialogContent>
    </AlertDialog>
  )
}
