import { toast } from 'components/ui/use-toast'

export const checkPaymentError = (error: any) => {
  if (error?.status == 402) {
    toast({
      title: 'Business eligibility payment issue!',
      description: 'Please check your business account payment issue',
      duration: 10000,
    })
  }
}
