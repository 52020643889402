import { api } from '../api'

type ConversationData = {
  data: {
    id: string
    link: string
    updated_time: Date
  }[]
}

type Conversation = {
  id: string
  userId: string
  name: string
  leadId: string
  metaId: string
  messages: Message[]
}

type Message = {
  id: string
  metaId: string
  created_time: Date
  senderId: string
  text: string
  type: string
  mediaURL: string
}

type Contact = {
  id: string
  name: string
  metaId: string
}

type ContactResponse = Contact[]

type MetaMessage = {
  id: string
  to: {
    data: [
      {
        username: string
        email: string
        id: string
      },
    ]
  }
  from: {
    username: string
    email: string
    id: string
  }
  message: string
}

type ProfileResponse = {
  name: string
  picture: string
}

export const metaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMetaContacts: builder.query<ContactResponse, void>({
      query: () => '/meta',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'MetaContacts' as const,
                id,
              })),
              { type: 'MetaContacts', id: 'LIST' },
            ]
          : [{ type: 'MetaContacts', id: 'LIST' }],
    }),
    getMetaConversations: builder.query<ConversationData, void>({
      query: () => `/meta/facebook-messages`,
      providesTags: ['MetaConversations'],
    }),
    getMetaConversation: builder.query<Conversation, string | undefined>({
      query: (id) => `/meta/${id}`,
      providesTags: ['MetaConversations'],
    }),
    getMetaMessage: builder.query<MetaMessage, string | undefined>({
      query: (id) => `/meta/facebook-message/${id}`,
    }),
    sendMetaMessage: builder.mutation({
      query: (message) => ({
        url: '/meta/message',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [
        { type: 'MetaContacts', id: 'LIST' },
        'MetaContacts',
        'MetaConversations',
      ],
    }),
    initMetaAccount: builder.mutation({
      query: (data) => ({
        url: `/meta/account`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['MetaProfile'],
    }),
    sendMetaMedia: builder.mutation({
      query: (message) => ({
        url: '/meta/media',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [
        { type: 'MetaContacts', id: 'LIST' },
        'MetaContacts',
        'MetaConversations',
      ],
    }),
    getMetaProfile: builder.query<ProfileResponse, void>({
      query: () => `/meta/profile`,
      providesTags: ['MetaProfile'],
    }),
    getUnreadInstagramConversationsCount: builder.query<number, void>({
      query: () => `/meta/unread-conversations-count?platform=instagram`,
      providesTags: () => [
        'MetaConversations',
        'MetaContacts',
        { type: 'MetaContacts', id: 'LIST' },
      ],
    }),
    markMetaConversationAsRead: builder.mutation({
      query: (id) => ({
        url: `/meta/mark-as-read/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        { type: 'MetaContacts', id: 'LIST' },
        'MetaContacts',
        'MetaConversations',
      ],
    }),
  }),
})

export const {
  useGetMetaContactsQuery,
  useGetMetaConversationQuery,
  useGetMetaMessageQuery,
  useGetMetaConversationsQuery,
  useSendMetaMessageMutation,
  useInitMetaAccountMutation,
  useSendMetaMediaMutation,
  useGetMetaProfileQuery,
  useGetUnreadInstagramConversationsCountQuery,
  useMarkMetaConversationAsReadMutation,
} = metaApi
