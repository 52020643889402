import 'intersection-observer'
import { cn } from 'lib/utils'
import { useMarkWhatsappConversationAsReadMutation } from 'app/features/whatsapp'
import dayjs from 'dayjs'
import { useEffect, useRef } from 'react'
import Loader from 'components/shared/loader'
import { useMarkMetaConversationAsReadMutation } from 'app/features/meta'

export default function List({
  contacts,
  selectedConversation,
  setSelectedConversation,
  loading,
  fetchData,
  selectedAccountId,
  hasMore,
  readConversations,
  setReadConversations,
}: any) {
  const observer = useRef<IntersectionObserver | null>(null)
  const loadMoreRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [markAsRead] = useMarkWhatsappConversationAsReadMutation()
  const [markAsReadMeta] = useMarkMetaConversationAsReadMutation()

  /* Infinite scroll */

  useEffect(() => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && selectedAccountId && hasMore) {
        fetchData()
      }
    })
    if (loadMoreRef.current) {
      observer.current.observe(loadMoreRef.current)
    }
    return () => {
      if (observer.current) observer.current.disconnect()
    }
  }, [fetchData, selectedAccountId])

  // Scroll to the end when new data fetched
  useEffect(() => {
    if (containerRef.current) {
      if (contacts?.length <= 10) {
        containerRef.current.scrollTop = 0
      } else {
        const prevScrollHeight = containerRef.current.scrollHeight
        containerRef.current.scrollTop +=
          containerRef.current.scrollHeight - prevScrollHeight
      }
    }
  }, [contacts])

  if (loading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    )
  }

  return (
    <div
      className="flex flex-col gap-2 text-sm animate-smooth-appear overflow-y-scroll   max-h-[min(36rem,calc(100vh-10rem))] max-md:max-h-[min(34rem,calc(100vh-12rem))]  "
      ref={containerRef}
    >
      {contacts?.map((contact: any, index: number) => (
        <div
          key={`${contact.id}` + `${index}`}
          className={cn(
            contact?.id === selectedConversation?.id
              ? 'bg-gray-300'
              : 'bg-gray-50 hover:bg-gray-200 border',
            'cursor-pointer flex gap-2  rounded-xl p-2 w-auto relative min-h-[3.5rem] '
          )}
          onClick={() => {
            setSelectedConversation(contact)
            setReadConversations((prev: any) => {
              if (prev.includes(contact.id)) return [...prev]
              else return [...prev, contact.id]
            })
            markAsRead(contact.id)
            markAsReadMeta(contact.id)
          }}
        >
          <div className="flex flex-col justify-start gap-1 text-[12px] md:text-xs w-full px-2">
            <div className="flex justify-between w-full items-center">
              <span className="whitespace-nowrap font-medium max-md:max-w-[8rem] max-sm:max-w-[5rem] overflow-hidden text-ellipsis">
                {contact?.name?.trim(20)}
              </span>

              <span className="text-[10px] text-gray-500 font-medium">
                {dayjs(contact?.updatedAt).format('HH:mm')}
              </span>
            </div>
            <div className="flex justify-between items-center w-full  ">
              <div className="flex items-center gap-1  flex-wrap">
                {contact?.tags?.map((item: any, i: number) => {
                  if (i < 2)
                    return (
                      <span
                        className={`rounded-md px-2 py-[2px] max-w-[6rem] max-md:max-w-[3.8rem] max-sm:max-w-[3rem] whitespace-nowrap text-ellipsis overflow-hidden text-white text-xs ${i % 2 === 0 ? 'bg-customBlue' : 'bg-customGreen'}  `}
                        key={item.id + i}
                      >
                        {item.name}
                      </span>
                    )
                })}
              </div>
              {!readConversations?.includes(contact.id) && !contact?.read && (
                <>
                  {contact?.messages?.length == 0 && (
                    <div className="grid place-content-center z-10 text-[9px] w-4 h-4 rounded-full bg-rose-600 text-white"></div>
                  )}
                  {contact?.messages?.length > 0 && (
                    <div className="grid place-content-center z-10 text-[9px] w-4 h-4 rounded-full bg-rose-600 text-white">
                      {contact?.messages?.length > 99
                        ? '99+'
                        : contact?.messages?.length}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ))}
      <div ref={loadMoreRef} className="min-h-[4rem] "></div>
    </div>
  )
}
