import React from 'react'

import { Button } from 'components/ui/button'

import { Sheet, SheetContent, SheetTrigger } from 'components/ui/sheet'
import Sidebar from './sidebar'
import { TbMenu2, TbMessage } from 'react-icons/tb'

export default function MobileSidebar({
  activeTab,
  selectedAccountId,
  selectedConversation,
  setSelectedConversation,
  selectedLead,
  setSelectedLead,
  conversationLeads,
  setConversationLeads,
  refreshContacts,
}: any) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size={'icon'}>
          <TbMessage size={18} />
        </Button>
      </SheetTrigger>
      <SheetContent
        className="pr-0 mt-14 sm:mt-0 pb-12  overflow-auto "
        side={'left'}
      >
        <Sidebar
          activeTab={activeTab}
          selectedAccountId={selectedAccountId}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          conversationLeads={conversationLeads}
          setConversationLeads={setConversationLeads}
          refreshContacts={refreshContacts}
          mobileView={true}
        />
      </SheetContent>
    </Sheet>
  )
}
