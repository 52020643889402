import { useGetFailedMessagesQuery } from 'app/features/whatsapp'
import Pagination from 'components/table/pagination'
import { useToast } from 'components/ui/use-toast'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'
import { WhatsappStatsProps } from '..'
import dayjs from 'lib/dayjs'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip'
import { Button } from 'components/ui/button'
import { TbX } from 'react-icons/tb'

export default function FailedMessages({
  startDate,
  endDate,
  whatsappAccount,
  closeDialog,
}: WhatsappStatsProps & { closeDialog: () => void }) {
  const { toast } = useToast()

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { data, isLoading, isFetching, isError } = useGetFailedMessagesQuery({
    whatsappAccount,
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
    ...(startDate && {
      from: dayjs(startDate).toISOString(),
    }),
    ...(endDate && {
      to: dayjs(endDate).toISOString(),
    }),
  })

  /**
   * Show error toast if isError is true
   */
  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error',
        description: 'Error fetching offers',
        variant: 'destructive',
      })
    }
  }, [isError])

  return (
    <div className="flex flex-col p-6 w-[780px]">
      <div className="flex justify-between mb-6">
        <div>
          <h1 className="text-lg font-medium">Failed messages</h1>
        </div>
        <div>
          <Button variant="outline" onClick={closeDialog}>
            <TbX />
          </Button>
        </div>
      </div>

      <div style={{ minHeight: '50vh' }}>
        <table
          className={cn(
            'text-sm w-full border-collapse text-[13px] table-fixed'
          )}
        >
          <thead>
            <tr className="text-center border-b w-full ">
              <th className="lg:w-20 ">Name</th>
              <th className="lg:w-40 ">Phone number</th>
              <th className="lg:w-20 ">Content</th>
              <th className="lg:w-20 ">Date</th>
            </tr>
          </thead>
          <tbody>
            {isLoading || isFetching ? (
              <tr>
                <td colSpan={4} className="text-center pt-8">
                  Loading...
                </td>
              </tr>
            ) : (
              ''
            )}

            {!isLoading && !isFetching && data?.items?.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  No data found
                </td>
              </tr>
            )}

            {data?.items?.map((item) => (
              <tr key={item.id}>
                <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {item.conversation?.name}
                </td>
                <td className="text-center">
                  {item.conversation?.phoneNumber}
                </td>
                <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                          {item.content}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p style={{ whiteSpace: 'pre-line' }}>{item.content}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </td>
                <td className="text-center">
                  {dayjs(item.createdAt).format('MMM DD HH:mm')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-2">
        {data && data?.count > 0 && (
          <Pagination
            count={data?.count}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
    </div>
  )
}
