import {
  useCreateAnswerMutation,
  useUpdateAnswerMutation,
  useDeleteAnswerMutation,
  useGetAnswersGroupedQuery,
} from 'app/features/kb'
import { useToast } from 'components/ui/use-toast'
import { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import { Button } from 'components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import {
  TbCircleDot,
  TbEdit,
  TbPdf,
  TbPlus,
  TbSitemap,
  TbTrash,
} from 'react-icons/tb'
import { Dialog, DialogContent } from 'components/ui/dialog'
import EditAnswer from './edit-answer'
import { isURL } from 'lib/validation'
import axios from '../../../lib/axios'
import dayjs from 'lib/dayjs'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'

type GroupedAnswersResponse = {
  category: KBCategory
  categoryId: string
  answers: KBAnswer[]
}[]

export default function Answers() {
  const { toast } = useToast()

  const [deleteDialog, setDeleteDialog] = useState<{
    id: string | null
    open: boolean
  }>({
    id: null,
    open: false,
  })

  const [groupedAnswers, setGroupedAnswers] = useState<GroupedAnswersResponse>(
    []
  )
  const [editDialogOpen, setEditDialogOpen] = useState<{
    selectedCategory: string | null
    open: boolean
  }>({
    selectedCategory: null,
    open: false,
  })
  const [editExistingAnswer, setEditExistingAnswer] = useState<
    | {
        index: number
        id: string
        fields: { key: string; value: string }[]
      }
    | undefined
  >()

  const {
    data: answersData,
    isLoading,
    isError: isErrorLoading,
  } = useGetAnswersGroupedQuery()

  const [
    createAnswer,
    { isSuccess: isCategoryCreated, isError: isCreateError },
  ] = useCreateAnswerMutation()

  const [
    updateAnswer,
    { isSuccess: isCategoryUpdated, isError: isUpdateError },
  ] = useUpdateAnswerMutation()

  const [
    deleteAnswer,
    { isSuccess: isCategoryDeleted, isError: isDeleteError },
  ] = useDeleteAnswerMutation()

  /**
   * Updates the list of categories when the structure data is received.
   */
  useEffect(() => {
    if (answersData) {
      setGroupedAnswers(answersData)
    }
  }, [answersData])

  /**
   * Displays an error message if there was an error saving the structure.
   */
  useEffect(() => {
    if (isCreateError) {
      toast({
        title: 'Error occurred',
        description: isCreateError,
      })
    }
  }, [isCreateError, isErrorLoading])

  /**
   * Adds a new category to the existing list of categories and updates the structure.
   * @param data - The category data to be added, which includes a name and an array of fields.
   */
  const onAddNewAnswer = async (data: {
    category: string
    fields: { key: string; value: string }[]
  }) => {
    if (data.category && data.fields) {
      try {
        await createAnswer({
          category: data.category,
          fields: data.fields,
        }).unwrap()

        setEditDialogOpen({
          selectedCategory: null,
          open: false,
        })
      } catch (error) {
        console.log(error)
        toast({
          title: 'Error occurred',
          description: 'An error occurred while saving data',
        })
      }
    }
  }

  /**
   * Updates an existing category in the list of categories and saves the updated structure.
   * @param data - An object containing the category details to be updated, including:
   */
  const onUpdateAnswer = async (
    index: number,
    id: string,
    data: {
      fields: { key: string; value: string }[]
    }
  ) => {
    if (data.fields) {
      try {
        await updateAnswer({
          id,
          fields: data.fields,
        }).unwrap()

        setEditDialogOpen({
          selectedCategory: null,
          open: false,
        })
        setEditExistingAnswer(undefined)
      } catch (error) {
        console.log(error)
        toast({
          title: 'Error occurred',
          description: 'An error occurred while updating the data',
        })
      }
    }
  }

  /**
   * Opens the delete dialog for the answer with the given id.
   * @param id - The id of the answer to be deleted.
   */
  const onClickDelete = async (id: string) => {
    setDeleteDialog({ id, open: true })
  }

  /**
   * Deletes an answer with the given id.
   * If the answer is deleted successfully, the dialog is closed.
   * If an error occurs, a toast notification is shown.
   * @param id - The id of the answer to be deleted.
   */
  const confirmDelete = async () => {
    const { id } = deleteDialog

    try {
      await deleteAnswer(id!).unwrap()

      setDeleteDialog({ id: null, open: false })
    } catch (error) {
      console.log(error)
      toast({
        title: 'Error occurred',
        description: 'An error occurred while deleting the data',
      })
    }
  }

  const defaultImage = '/logo.jpeg'

  return (
    <>
      <div className="flex flex-col p-6">
        <div className="">
          {groupedAnswers && groupedAnswers.length > 0 && !isLoading && (
            <Tabs
              orientation={'vertical'}
              defaultValue={
                groupedAnswers.length ? groupedAnswers[0].categoryId : ''
              }
            >
              <TabsList className="flex items-center justify-start flex-wrap h-auto space-y-1">
                {groupedAnswers?.map((el) => (
                  <TabsTrigger value={el.categoryId}>
                    {el.category.name}
                  </TabsTrigger>
                ))}
              </TabsList>
              {groupedAnswers?.map((group, index) => (
                <TabsContent value={group.categoryId}>
                  <div className="w-full flex justify-end mb-4">
                    <Button
                      variant="outline"
                      className="mr-2"
                      onClick={() =>
                        setEditDialogOpen({
                          selectedCategory: group.categoryId,
                          open: true,
                        })
                      }
                    >
                      <TbPlus className="mr-2" />
                      Add Category Data
                    </Button>
                  </div>
                  <div className="flex flex-wrap">
                    {group.answers.map((answer) => (
                      <Card className="m-2">
                        <CardHeader className="p-2">
                          <img
                            src={
                              isURL(answer.fields[0]?.value)
                                ? answer.fields[0].value
                                : defaultImage
                            }
                            style={{
                              width: 200,
                              height: 200,
                              objectFit: 'contain',
                            }}
                          />
                        </CardHeader>
                        <CardContent>
                          <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {answer.fields[1]?.value ?? ''}
                          </CardDescription>
                          <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {answer.fields[2]?.value ?? ''}
                          </CardDescription>
                          <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {answer.fields[3]?.value ?? ''}
                          </CardDescription>
                          <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {answer.fields[4]?.value ?? ''}
                          </CardDescription>
                        </CardContent>
                        <CardFooter className="flex justify-end p-1">
                          <Button
                            variant={'ghost'}
                            size="icon"
                            className="mr-2"
                            onClick={() => {
                              setEditExistingAnswer({
                                index,
                                id: answer.id,
                                fields: answer.fields,
                              })
                              setEditDialogOpen({
                                selectedCategory: null,
                                open: true,
                              })
                            }}
                          >
                            <TbEdit className="h-4 w-4" />
                          </Button>
                          <Button
                            variant={'ghost'}
                            size="icon"
                            onClick={() => onClickDelete(answer.id)}
                          >
                            <TbTrash className="h-4 w-4" />
                          </Button>
                        </CardFooter>
                      </Card>
                    ))}
                  </div>
                </TabsContent>
              ))}
            </Tabs>
          )}

          {(!groupedAnswers || groupedAnswers.length === 0) && !isLoading && (
            <div className="flex flex-col items-center justify-center h-80">
              <p className="text-center text-gray-400">
                No data found. You can start by adding a new category.
              </p>
            </div>
          )}
        </div>

        <Dialog open={editDialogOpen.open}>
          <DialogContent className="sm:max-w-[525px] [&>button]:hidden">
            <EditAnswer
              addNewAnswer={onAddNewAnswer}
              updateAnswer={onUpdateAnswer}
              existingAnswerData={editExistingAnswer}
              onClose={() => {
                setEditDialogOpen({
                  selectedCategory: null,
                  open: false,
                })
                setEditExistingAnswer(undefined)
              }}
              selectedCategory={editDialogOpen.selectedCategory}
            />
          </DialogContent>
        </Dialog>

        <AlertDialog open={deleteDialog.open}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This will permanently delete the data
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => setDeleteDialog({ id: null, open: false })}
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction onClick={confirmDelete}>
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  )
}
