import { Button, buttonVariants } from 'components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Textarea } from 'components/ui/textarea'
import { AnimatePresence, motion } from 'framer-motion'
import { cn, isTimestampPastThan } from 'lib/utils'
import { useEffect, useRef, useState } from 'react'
import { TbFile, TbPhoto, TbPlus, TbVideo } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import RecordMessage from './record-message'
import TemplateList from './template-list'
import Loader from 'components/shared/loader'
import { useToast } from 'components/ui/use-toast'
import { FaT } from 'react-icons/fa6'

interface ChatBottomBarProps {
  selectedAccountId?: any
  phoneNumber?: string
  lead: any
  isNewMessageEnabled: boolean
  sendMessage: (newMessage: any) => void
  sendMedia: (newMessage: any) => void
  sendTemplate: (template: any) => void
}

export default function ChatBottomBar({
  selectedAccountId,
  phoneNumber,
  lead,
  isNewMessageEnabled,
  sendMessage,
  sendMedia,
  sendTemplate,
}: ChatBottomBarProps) {
  const { toast } = useToast()

  const [message, setMessage] = useState('')
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const documentAcceptedFileTypes = [
    'audio/aac',
    'audio/mp4',
    'audio/mpeg',
    'audio/amr',
    'audio/ogg',
    'audio/opus',
    'application/vnd.ms-powerpoint',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'text/plain',
    'application/vnd.ms-excel',
  ]

  const handleFileUpload = async (event: any) => {
    const files = event.target.files
    const filesArray = [...files]

    for (let i = 0; i < filesArray.length; i++) {
      if (!documentAcceptedFileTypes.includes(filesArray[i].type)) {
        toast({
          title: 'Error occurred',
          description: 'Unsupported file type',
        })
        return
      }
    }

    toast({
      title: 'Uploading media',
      description: 'File(s) are being uploaded',
    })

    try {
      await Promise.all(
        filesArray.map((file: File) => sendMedia({ file, type: 'DOCUMENT' }))
      )
    } catch (error) {
      console.log(error)
      toast({
        title: 'Error occurred',
        description: 'Failed to upload media',
      })
    }
  }

  const handleImageUpload = async (event: any) => {
    const files = event.target.files || []
    setIsUploading(true)
    const filesArray = [...files]

    toast({
      title: 'Uploading media',
      description: 'Image(s) are being uploaded',
    })

    try {
      await Promise.all(
        filesArray.map((file: File) => sendMedia({ file, type: 'IMAGE' }))
      )
    } catch (error) {
      console.log(error)
      toast({
        title: 'Error occurred',
        description: 'Failed to upload media',
      })
    }

    setIsUploading(false)
    setIsPopoverOpen(false)
  }

  const handleVideoUpload = async (event: any) => {
    const files = event.target.files

    const filesArray = [...files]

    toast({
      title: 'Uploading media',
      description: 'Video(s) are being uploaded',
    })

    try {
      await Promise.all(
        filesArray.map((file: File) => sendMedia({ file, type: 'VIDEO' }))
      )
    } catch (error) {
      console.log(error)
      toast({
        title: 'Error occurred',
        description: 'Failed to upload media',
      })
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }

  const handleSend = async () => {
    if (message.trim()) {
      const newMessage = message.trim()
      await sendMessage(newMessage)
      setMessage('')

      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSend()
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault()
      setMessage((prev) => prev + '\n')
    }
  }

  const onClickImageUpload = () => {
    document.getElementById('imageUpload')?.click()
  }

  const onClickVideoUpload = () => {
    document.getElementById('videoUpload')?.click()
  }

  const onClickDocumentUpload = () => {
    document.getElementById('fileUpload')?.click()
  }

  return (
    <div className="fixed md:relative bottom-0 right-0 left-0 p-3 bg-white border-t">
      <div className="w-full bg-white rounded-xl flex items-center gap-3 justify-center">
        {isNewMessageEnabled ? (
          <>
            <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
              <PopoverTrigger asChild>
                <Link
                  to="#"
                  className={cn(
                    buttonVariants({ variant: 'ghost', size: 'icon' }),
                    'h-9 w-9',
                    'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
                  )}
                >
                  {/* add spinner for loading */}
                  {isUploading ? (
                    <Loader />
                  ) : (
                    <TbPlus size={20} className="text-muted-foreground" />
                  )}
                </Link>
              </PopoverTrigger>
              <PopoverContent side="top" className="w-full p-1">
                <div style={{ minWidth: '100px' }}>
                  <div>
                    <Button
                      className="w-full justify-start"
                      variant="ghost"
                      onClick={onClickImageUpload}
                    >
                      <TbPhoto className="mr-2 text-blue-500 text-xl" />
                      <span>Images</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="w-full justify-start"
                      variant="ghost"
                      onClick={onClickVideoUpload}
                    >
                      <TbVideo className="mr-2 text-red-500 text-xl" />
                      <span>Videos</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="w-full justify-start"
                      variant="ghost"
                      onClick={onClickDocumentUpload}
                    >
                      <TbFile className="mr-2 text-green-500 text-xl" />
                      <span>Documents</span>
                    </Button>
                  </div>

                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                    multiple
                  />

                  <input
                    type="file"
                    accept="video/*"
                    id="videoUpload"
                    style={{ display: 'none' }}
                    onChange={handleVideoUpload}
                    multiple
                  />
                  <input
                    type="file"
                    id="fileUpload"
                    accept={documentAcceptedFileTypes.join(',')}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    multiple
                  />
                </div>
              </PopoverContent>
            </Popover>
            <TemplateList
              selectedAccountId={selectedAccountId}
              sendTemplate={sendTemplate}
              phoneNumber={phoneNumber}
              lead={lead}
              TriggerIcon={() => {
                return <FaT size={20} />
              }}
            />
            <AnimatePresence initial={false}>
              <motion.div
                key="input"
                className="w-full relative"
                layout
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1 }}
                transition={{
                  opacity: { duration: 0.05 },
                  layout: {
                    type: 'spring',
                    bounce: 0.15,
                  },
                }}
              >
                <Textarea
                  autoComplete="off"
                  value={message}
                  ref={inputRef}
                  onKeyDown={handleKeyPress}
                  onChange={handleInputChange}
                  name="message"
                  placeholder="Aa"
                  className="bg-white min-h-10"
                  rows={1}
                ></Textarea>
              </motion.div>
              {message.trim() && (
                <Link
                  to="#"
                  className={cn(
                    buttonVariants({ variant: 'ghost', size: 'icon' }),
                    'h-9 w-9',
                    'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white shrink-0'
                  )}
                  onClick={handleSend}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    width={24}
                    height={24}
                    strokeWidth={2}
                  >
                    <path d="M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z"></path>{' '}
                    <path d="M6.5 12h14.5"></path>{' '}
                  </svg>
                </Link>
              )}
            </AnimatePresence>
          </>
        ) : (
          <TemplateList
            selectedAccountId={selectedAccountId}
            sendTemplate={sendTemplate}
            phoneNumber={phoneNumber}
            lead={lead}
            TriggerIcon={() => {
              return <span>Send Template</span>
            }}
          />
        )}
      </div>
    </div>
  )
}
