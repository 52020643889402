import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { toast } from 'components/ui/use-toast'
import { useEffect, useState } from 'react'
import { TbDeviceFloppy, TbPlus, TbTrash, TbX } from 'react-icons/tb'

interface EditCategoryProps {
  existingCategoryData?: {
    index: number
    id: string
    name: string
    fields: string[]
  }
  addNewCategory: (data: { name: string; fields: string[] }) => void
  updateCategory: (
    index: number,
    id: string,
    data: { name: string; fields: string[] }
  ) => void
  onClose: () => void
}

/**
 * A modal dialog for adding or editing a category in the knowledge base.
 * @param existingCategoryData If provided, the category that is being edited.
 * @param addNewCategory A function that adds a new category to the knowledge base.
 * @param updateCategory A function that updates an existing category in the knowledge base.
 * @param onClose A function that is called when the dialog is closed.
 */
export default function EditCategory({
  existingCategoryData,
  addNewCategory,
  updateCategory,
  onClose,
}: EditCategoryProps) {
  const [category, setCategory] = useState('')
  const [fields, setFields] = useState<{ name: string; old?: boolean }[]>([
    {
      name: 'Cover image',
    },
    {
      name: '',
    },
  ])

  useEffect(() => {
    if (existingCategoryData) {
      setCategory(existingCategoryData.name)
      setFields(
        existingCategoryData.fields.map((field) => ({ name: field, old: true }))
      )
    }
  }, [existingCategoryData])

  /**
   * Validates the current state of the form and either adds a new category or updates an existing one.
   * If the form is invalid, a toast notification is shown.
   */
  const saveCategory = async () => {
    if (!category) {
      toast({
        title: 'Save failed',
        description: 'Category name is required',
      })
      return
    }

    if (!fields.length) {
      toast({
        title: 'Save failed',
        description: 'At least one field is required',
      })
      return
    }

    const nonEmptyFields = fields
      .filter((field) => field.name)
      .map((field) => field.name)

    if (existingCategoryData) {
      updateCategory(existingCategoryData.index, existingCategoryData.id, {
        name: category,
        fields: nonEmptyFields,
      })
    } else {
      addNewCategory({
        name: category,
        fields: nonEmptyFields,
      })
    }
  }

  return (
    <div className="grid gap-4">
      <div className="space-y-2">
        <div className="flex justify-between mb-4 ">
          <div>
            <h4 className="font-medium leading-none">
              {existingCategoryData ? 'Edit' : 'Add'} Category
            </h4>
          </div>
          <div>
            <Button variant={'ghost'} size="icon" onClick={onClose}>
              <TbX />
            </Button>
          </div>
        </div>
        <p className="text-sm text-muted-foreground">Type category name:</p>
        <Input
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="col-span-2 h-8"
          required
          maxLength={30}
        />
      </div>
      <div className="flex justify-between items-center">
        <p className="text-sm text-muted-foreground">Fields:</p>
      </div>
      <div className="grid gap-2 max-h-60 overflow-y-scroll">
        {fields.map((field, index) => (
          <div key={index} className="flex items-center ml-4 mb-1">
            <Input
              id={`field-${index}`}
              value={field.name}
              onChange={(e) =>
                setFields((prev) =>
                  prev.map((field, i) =>
                    i === index ? { name: e.target.value } : field
                  )
                )
              }
              className="col-span-2 h-8"
              disabled={index === 0 || field.old}
              required
              maxLength={30}
            />
            <Button
              variant={'destructive'}
              className="ml-2"
              size="icon"
              onClick={() =>
                setFields((prev) => prev.filter((_, i) => i !== index))
              }
              disabled={index === 0}
            >
              <TbTrash color="rose" />
            </Button>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <Button
          variant={'ghost'}
          onClick={() => setFields((prev) => [...prev, { name: '' }])}
        >
          <TbPlus className="mr-2" />
          Add field
        </Button>
      </div>
      <div className="flex justify-end">
        <Button onClick={saveCategory}>
          <TbDeviceFloppy className="mr-2" size={20} />
          Save Category
        </Button>
      </div>
    </div>
  )
}
