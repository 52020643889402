import { api } from '../api'

type CustomerResponse = Partial<Customer> & {
  history: any[]
  leads: any[]
  statusCounts: { [status: string]: number }
  sourceCounts: { [status: string]: number }
  branchesCounts: { [status: string]: number }
  departmentsCounts: { [status: string]: number }
  specialistsCounts: { [status: string]: number }
  bookingsAmount: number
}

export const apis = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<
      { items: Customer[]; count: number },
      { skip?: number; take?: number }
    >({
      query: (params: { skip?: number; take?: number }) => {
        const searchParams = new URLSearchParams()

        if (params.skip !== undefined)
          searchParams.set('skip', params.skip.toString())
        if (params.take !== undefined)
          searchParams.set('take', params.take.toString())

        const queryString = searchParams.toString()
        return `/customers?${queryString}`
      },
      providesTags: ['Customers'],
    }),
    getCustomerById: builder.query<CustomerResponse, string | undefined>({
      query: (id?: string) => (id ? `/customers/${id}` : ''),
      providesTags: ['Customers'],
    }),
  }),
})

const { useGetCustomersQuery, useGetCustomerByIdQuery } = apis

export { useGetCustomersQuery, useGetCustomerByIdQuery }
