import { useState } from 'react'
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'

import { cn } from 'lib/utils'
import { Button } from 'components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'

export default function MySelect({
  data,
  selected,
  setSelected,
  selectMeta,
  disabled,
  hasFree,
  isFree,
  setIsFree,
}: any) {
  const [open, setOpen] = useState(false)
  const selection = (value: any) => {
    switch (value) {
      case 'free': {
        setIsFree((prev: any) => !prev)
        setSelected([])
        break
      }
      default: {
        if (hasFree) setIsFree(false)
        if (selected.filter((item: any) => item.id === value.id).length > 0) {
          setSelected([...selected.filter((item: any) => item.id !== value.id)])
        } else {
          setSelected([...selected, value])
        }
      }
    }
  }

  return (
    // model = {true} on Popover
    // overflow-y-auto max-h-56 on Command
    // should be defined to solve the scroll with mouse issue
    // https://github.com/shadcn-ui/ui/issues/542

    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between border-gray-300 shadow-sm dark:border-gray-800 bg-white dark:bg-gray-900"
        >
          {isFree
            ? `No ${selectMeta}`
            : selected.length > 0
              ? selected.length > 1
                ? `${selected.length} ${selectMeta}s Selected`
                : `${selected.length} ${selectMeta} Selected`
              : `Search ${selectMeta}s`}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command className="w-[200px] overflow-y-auto ">
          <CommandInput placeholder={`Search ${selectMeta}s`} className="h-9" />
          <CommandEmpty>List is empty</CommandEmpty>
          <CommandGroup>
            <ScrollArea className="h-52 w-full">
              {data?.map((item: any) => (
                <CommandItem
                  id={item.id}
                  key={`${selectMeta}-${item.id}`}
                  value={item}
                  onSelect={(currentValue) => {
                    selection(item)
                    setOpen(false)
                  }}
                  className="capitalize hover:bg-white "
                >
                  <span className="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.name} {item.location ? ' - ' + item.location : ''}
                  </span>
                  <CheckIcon
                    className={cn(
                      'ml-auto h-4 w-4',
                      selected.filter((el: any) => el.id === item.id).length > 0
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                </CommandItem>
              ))}
              {hasFree && (
                <CommandItem
                  id={selectMeta + '-' + 'free'}
                  key={`${selectMeta}-free`}
                  value={'free'}
                  onSelect={(currentValue) => {
                    selection('free')
                    setOpen(false)
                  }}
                  className="capitalize hover:bg-white"
                >
                  {`no ${selectMeta}`}
                  <CheckIcon
                    className={cn(
                      'ml-auto h-4 w-4',
                      isFree ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </CommandItem>
              )}
            </ScrollArea>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
