import { useGetConversationsOfMetaAdQuery } from 'app/features/meta-ads'
import Loader from 'components/shared/loader'
import Pagination from 'components/table/pagination'
import { Button } from 'components/ui/button'
import { toast } from 'components/ui/use-toast'
import axios from 'lib/axios'
import dayjs from 'lib/dayjs'
import { useState } from 'react'
import { TbFileTypeXls, TbX } from 'react-icons/tb'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'

export default function ViewLeadsOfMetaAd({
  sourceId,
  whatsappAccount,
  closeDialog,
}: {
  sourceId: string | undefined
  whatsappAccount: string
  closeDialog: () => void
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { data, isLoading, isFetching, isError } =
    useGetConversationsOfMetaAdQuery({
      sourceId: sourceId!,
      whatsappAccount,
      skip: Math.abs((currentPage - 1) * rowsPerPage),
      take: rowsPerPage,
    })

  const [loadingExcel, setLoadingExcel] = useState(false)

  /**
   * Generates an Excel file and download it
   */
  const exportToExcel = async () => {
    if (loadingExcel) return

    setLoadingExcel(true)

    const { dismiss } = toast({
      title: 'Generating Excel',
      description:
        'Please wait, Excel is being generated. This may take a few seconds.',
      duration: 30 * 1000,
    })

    try {
      const res = await axios.get('/meta-ads/conversations/export', {
        params: {
          sourceId: sourceId!,
          whatsappAccount: whatsappAccount,
        },
        responseType: 'blob',
      })

      downloadFile(res.data)

      toast({
        title: 'Success',
        description: 'File generated successfully',
      })

      dismiss()
    } catch (error) {
      toast({
        title: 'Error occurred',
        description: 'An error occurred while generating file',
      })
    }

    setLoadingExcel(false)
  }

  /**
   * Downloads the given data as a file
   * @param data The  data to download
   */
  function downloadFile(data: string) {
    const blob = new Blob([data], { type: 'application/xlsx' })

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    link.href = url
    link.download = `Doctorna - Leads of Meta Ad ${dayjs().format('YYYYMMDD HH:mm')}.xlsx`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <div className="flex justify-between mb-6">
        <div>
          <h1 className="text-lg font-medium">View Meta ad</h1>
        </div>
        <div>
          <Button variant="outline" onClick={closeDialog}>
            <TbX />
          </Button>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          onClick={exportToExcel}
          variant={'outline'}
          className="mx-2 mt-2"
        >
          <TbFileTypeXls size={20} className="mr-2" />
          Export
        </Button>
      </div>

      {isError && <div>Error loading data</div>}

      {(isFetching || isLoading) && (
        <tr>
          <td colSpan={6}>
            <div className="w-full flex justify-center my-10">
              <Loader />
            </div>
          </td>
        </tr>
      )}

      <div className="max-h-[500px] overflow-scroll">
        {data && data.count > 0 && (
          <>
            <table className="w-full table-fixed">
              <thead>
                <tr className="text-center border-b w-full ">
                  <th className="text-sm">Name (WhatsApp)</th>
                  <th className="text-sm">Phone Number</th>
                  <th className="text-sm">Leads</th>
                </tr>
              </thead>
              <tbody>
                {!isError && !isLoading && !isFetching && data?.items && (
                  <>
                    {data?.items?.map((item, index) => (
                      <tr
                        key={index}
                        className="w-full border-b border-gray-100 last:border-b-0 py-1"
                      >
                        <td className="text-sm text-center">{item.name}</td>
                        <td className="text-sm text-center">
                          {item.phoneNumber}
                        </td>
                        <td className="text-sm text-center">
                          <Accordion
                            type="single"
                            collapsible
                            className="w-full"
                          >
                            {item.leads?.map((lead, i) => (
                              <AccordionItem
                                value={lead.firstName + lead.lastName + i}
                              >
                                <AccordionTrigger className="font-normal">
                                  {lead.firstName || ''} {lead.lastName || ''}
                                  {lead.followUps.length ? (
                                    <>({lead.followUps.length} follow-ups)</>
                                  ) : (
                                    ''
                                  )}
                                </AccordionTrigger>
                                <AccordionContent>
                                  <ul className="list-disc">
                                    {lead.followUps.map((followUp, j) => (
                                      <li key={j} className="text-sm">
                                        {dayjs(followUp.createdAt).format(
                                          'DD/MM/YYYY hh:mm A'
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                  {lead.followUps.length === 0 && (
                                    <div>
                                      <p className="text-center text-xs">
                                        No follow-ups
                                      </p>
                                    </div>
                                  )}
                                </AccordionContent>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {!isLoading && !isFetching && !data?.items.length && (
              <div className="h-40 w-full grid place-content-center text-sm">
                No data found
              </div>
            )}

            <div>
              {data && data?.count > 0 && (
                <Pagination
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
