import { Cross1Icon } from '@radix-ui/react-icons'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'

import { useEffect, useState } from 'react'
import { TbListDetails } from 'react-icons/tb'
import { MdOutlineQuestionMark } from 'react-icons/md'
import { useDeleteTemplateMutation } from 'app/features/whatsapp'

export default function DeleteTemplate({
  template,
  selectedAccountId,
  open,
  setOpen,
}: any) {
  const [deleteTemplate, { isLoading }] = useDeleteTemplateMutation()
  const [error, setError] = useState('')

  const onOpenChange = async () => {
    setOpen((prev: any) => !prev)
  }

  useEffect(() => {
    setError('')
  }, [open])
  const deleteHandler = async () => {
    try {
      const res: any = await deleteTemplate({
        data: {
          templateName: template.name,
        },
        accountId: selectedAccountId,
      })
      if (res.error) {
        return setError('Failed to delete template')
      }

      setOpen(false)
    } catch (error) {
      setError('Failed to delete template')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <div className="absolute right-3 top-3">
          <AlertDialogCancel className="p-0">
            <Button variant="ghost">
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </AlertDialogCancel>
        </div>

        <>
          <AlertDialogTitle>Delete template</AlertDialogTitle>

          <div>
            <div className="flex gap-3 flex-col  ">
              <div className="flex items-center gap-1">
                Are you sure
                <MdOutlineQuestionMark />
              </div>
              <div className="flex-1" />

              <div className=" flex items-center justify-between">
                {error !== '' && (
                  <p className="text-rose-500 text-sm">{error}</p>
                )}
                <div className="flex-1" />

                <Button onClick={deleteHandler} disabled={isLoading}>
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </>
      </AlertDialogContent>
    </AlertDialog>
  )
}
