import { useEffect, useState } from 'react'

import { useAddLeadMutation, useGetLeadQuery } from 'app/features/lead'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useApp from 'hooks/useApp'
import FollowUp from 'pages/leads/components/follow-up'
import Book from 'pages/leads/components/book'
import NotQualified from 'pages/leads/components/not-qualified'
import Status from 'pages/leads/components/status'
import UpdateBook from 'pages/leads/components/update-book'
import ClaimInsurance from 'pages/leads/components/claim-insurance'
import { useSelector } from 'react-redux'
import { useToast } from 'components/ui/use-toast'
import { shouldFollowUp } from 'lib/allowed'
import SendToInsurance from 'pages/leads/components/send-to-insurance'
import EndInsurance from 'pages/leads/components/end-insurance'
import AttachFile from 'pages/leads/components/attach-file'
import { Actions } from 'pages/lead/components/actions'
import CancelBook from 'pages/leads/components/cancel-book'

export default function ContactActions({ leadId }: any) {
  const {
    data: lead,
    isLoading: loadingLead,
    isError: isErrorLead,
    error: errorLead,
  } = useGetLeadQuery(leadId)
  const { user } = useSelector((state: any) => state.user)
  const navigate = useNavigate()
  const { toast } = useToast()
  const { settings } = useApp()

  const [openFollowUp, setOpenFollowUp] = useState(false)
  const [openBook, setOpenBook] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [openUpdateBook, setOpenUpdateBook] = useState(false)
  const [openCancelBook, setOpenCancelBook] = useState(false)

  const [openNotQualified, setOpenNotQualified] = useState(false)
  const [openClaimInsurance, setOpenClaimInsurance] = useState(false)
  const [openSendToInsurance, setOpenSendToInsurance] = useState(false)
  const [openEndInsurance, setOpenEndInsurance] = useState(false)
  const [openAttachFile, setOpenAttachFile] = useState(false)

  const [numberOfFollowUps, setNumberOfFollowUps] = useState(0)
  const [timeGap, setTimeGap] = useState(0)

  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfFollowUps(settings[0]?.numberOfFollowUps)
      setTimeGap(settings[0]?.followUpTimeGap)
    }
  }, [settings])

  const [
    addLead,
    { isLoading: isDuplicateLoading, isError: isDuplicateError },
  ] = useAddLeadMutation()

  async function duplicateLead() {
    const {
      firstName,
      lastName,
      email,
      phone,
      whatsappNumber,
      alternativePhone,
      nationality,
      gender,
      sourceId,
      branchIDs,
      departmentIDs,
      specialistIDs,
    } = lead
    const result: any = await addLead({
      firstName,
      lastName,
      email,
      phone,
      whatsappNumber,
      alternativePhone,
      nationality,
      gender,
      sourceId,
      branchIDs,
      departmentIDs,
      specialistIDs,
      status: 'NEW',
      userId: user.id,
    })
    if (result?.error) {
      toast({
        title: 'Error',
        description: 'Failed to duplicate lead',
      })
      return
    }

    const newLead = result?.data
    toast({
      title: 'Duplicated Successfully',
      description: 'Lead Duplicated successfully',
    })
    navigate(`/lead-details/${newLead.id}`)
  }

  return (
    <div className="mr-2 ">
      <Actions
        lead={lead}
        isDetailsPage={true}
        isLoading={loadingLead}
        id={lead?.id}
        navigate={navigate}
        user={user}
        settings={settings}
        duplicateLead={duplicateLead}
        isDuplicateLoading={isDuplicateLoading}
        setOpenStatus={setOpenStatus}
        setOpenBook={setOpenBook}
        numberOfFollowUps={numberOfFollowUps}
        setOpenFollowUp={setOpenFollowUp}
        timeGap={timeGap}
        setOpenUpdateBook={setOpenUpdateBook}
        setOpenCancelBook={setOpenCancelBook}
        setOpenClaimInsurance={setOpenClaimInsurance}
        setOpenNotQualified={setOpenNotQualified}
        setOpenSendToInsurance={setOpenSendToInsurance}
        setOpenEndInsurance={setOpenEndInsurance}
        setOpenAttachFile={setOpenAttachFile}
        displayStartMessagingViaWhatsapp={false}
      />

      {lead && openFollowUp && (
        <FollowUp
          status={shouldFollowUp(
            lead,
            lead?.followUps[lead.followUps.length - 1],
            numberOfFollowUps,
            timeGap
          )}
          leadId={lead.id}
          open={openFollowUp}
          setOpen={setOpenFollowUp}
          openBook={setOpenBook}
        />
      )}
      {lead && openBook && (
        <Book leadId={lead?.id} open={openBook} setOpen={setOpenBook} />
      )}
      {lead && openStatus && (
        <Status lead={lead} open={openStatus} setOpen={setOpenStatus} />
      )}
      {lead && openUpdateBook && (
        <UpdateBook
          leadId={lead?.id}
          open={openUpdateBook}
          setOpen={setOpenUpdateBook}
        />
      )}
      {lead && openCancelBook && (
        <CancelBook
          leadId={lead?.id}
          open={openCancelBook}
          setOpen={setOpenCancelBook}
        />
      )}
      {lead && openNotQualified && (
        <NotQualified
          lead={lead}
          open={openNotQualified}
          setOpen={setOpenNotQualified}
        />
      )}
      {lead && openClaimInsurance && (
        <ClaimInsurance
          leadId={lead.id}
          open={openClaimInsurance}
          setOpen={setOpenClaimInsurance}
        />
      )}
      {lead && openSendToInsurance && (
        <SendToInsurance
          leadId={lead.id}
          open={openSendToInsurance}
          setOpen={setOpenSendToInsurance}
        />
      )}
      {lead && openEndInsurance && (
        <EndInsurance
          leadId={lead.id}
          open={openEndInsurance}
          setOpen={setOpenEndInsurance}
        />
      )}
      {lead && openAttachFile && (
        <AttachFile
          lead={lead}
          open={openAttachFile}
          setOpen={setOpenAttachFile}
        />
      )}
    </div>
  )
}
