import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import dayjs from 'dayjs'
import { formatPrice } from 'lib/utils'
import { TbCloudDownload, TbUserScan } from 'react-icons/tb'
import {
  TbUserPlus,
  TbEdit,
  TbCalendarPlus,
  TbCalendarX,
  TbFileInvoice,
  TbFileCheck,
  TbShieldCheck,
  TbClipboardX,
  TbPaperclip,
  TbShoppingCart,
  TbCash,
  TbCheck,
} from 'react-icons/tb'
import { FcOvertime } from 'react-icons/fc'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

export const LeadHistory = ({ isLoading, loadingLead, loading, lead }: any) => {
  if (isLoading || loadingLead || loading) {
    return (
      <div className="h-screen grid place-content-center">
        <Loader />
      </div>
    )
  }

  /**
   * Downloads the attachment associated with the given action
   * @param {Object} action - Action containing the attachment URL and name
   * @returns {Promise<void>}
   */
  const downloadAttachment = async (action: any) => {
    try {
      const response = await fetch(action.attachmentUrl)
      if (!response.ok) throw new Error('Download failed')
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = action.attachmentName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Returns a JSX element representing an icon based on the given action string.
   *
   * This function maps specific action strings to corresponding icons for display.
   * If the action is not recognized, a default icon is returned.
   *
   * @param {string} action - The action string for which an icon is required.
   * @returns {JSX.Element} - The icon associated with the given action.
   */
  const getLeadActionIcon = (action: string) => {
    const actionIcons: Record<string, JSX.Element> = {
      Created: <TbUserPlus size={20} />,
      Updated: <TbEdit size={20} />,
      Follow_Up: <TbCalendarPlus size={20} />,
      Booked: <TbCalendarPlus size={20} />,
      Update_Book: <TbCalendarPlus size={20} />,
      Cancel_Book: <TbCalendarX size={20} />,
      Update_Book_Status: <TbFileInvoice size={20} />,
      Sent_To_Insurance: <TbShieldCheck size={20} />,
      End_Insurance: <TbClipboardX size={20} />,
      Insurance_Approval: <TbFileCheck size={20} />,
      Not_Qualified: <TbClipboardX size={20} />,
      Attachment: <TbPaperclip size={20} />,
      Offer_Payment: <TbShoppingCart size={20} />,
      Sell_Offer: <TbCash size={20} />,
      Insurance_Claimed: <TbCheck size={20} />,
    }

    return actionIcons[action] || <TbUserPlus size={20} /> // Default icon if not found
  }

  return (
    <div className="bg-gray-100 p-6 rounded-lg">
      {lead?.leadActions && (
        <VerticalTimeline layout="1-column-left">
          {lead.leadActions.map((action: any, index: number) => (
            <VerticalTimelineElement
              key={index}
              date={dayjs(action.createdAt).format('DD/MM/YYYY hh:mm A')}
              dateClassName="text-sm text-gray-400 font-thin"
              icon={getLeadActionIcon(action.action)}
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
              <h3 className="text-indigo-500 font-bold">
                {action.action.replaceAll('_', ' ')}
              </h3>
              <ul className="text-sm text-gray-600 space-y-2 mt-2 list-disc pl-5">
                {action.followUpComment && (
                  <li>Comment: {action.followUpComment}</li>
                )}
                {action.nextFollowUpTimeGap && (
                  <li>Next follow up time gap: {action.nextFollowUpTimeGap}</li>
                )}
                {action.bookDate && (
                  <li>
                    Date: {dayjs(action.bookDate).format('DD/MM/YYYY hh:mm A')}
                  </li>
                )}
                {action.bookBranch && <li>Branch: {action.bookBranch}</li>}
                {action.bookDepartment && (
                  <li>Department: {action.bookDepartment}</li>
                )}
                {action.bookSpecialist && (
                  <li>Specialist: {action.bookSpecialist}</li>
                )}
                {action.bookComment && <li>Comment: {action.bookComment}</li>}
                {action.cancelBookComment && (
                  <li>Cancel Comment: {action.cancelBookComment}</li>
                )}
                {action.bookStatusComment && (
                  <li>Comment: {action.bookStatusComment}</li>
                )}
                {action.action === 'Update_Book_Status' && (
                  <>
                    <li>
                      Status: {action.bookStatusIsShow ? 'Show' : 'No Show'}
                    </li>
                    {action.bookStatusIsShow && (
                      <>
                        <li>Paid: {action.bookStatusIsPaid ? 'Yes' : 'No'}</li>
                        {action.bookStatusIsPaid && (
                          <li>
                            Amount: {formatPrice(action.bookStatusAmount)}
                          </li>
                        )}
                      </>
                    )}
                  </>
                )}
                {action.insuranceClaimStatus && (
                  <li>Insurance Status: {action.insuranceClaimStatus}</li>
                )}
                {action.sendToInsuranceComment && (
                  <li>Comment: {action.sendToInsuranceComment}</li>
                )}
                {action.sendToInsuranceEndedComment && (
                  <li>Comment: {action.sendToInsuranceEndedComment}</li>
                )}
                {action.insuranceClaimExpiryDate && (
                  <li>
                    Expiry:{' '}
                    {dayjs(action.insuranceClaimExpiryDate).format(
                      'DD/MM/YYYY hh:mm A'
                    )}
                  </li>
                )}
                {action.insuranceClaimAuthorizationNumber && (
                  <li>Auth #: {action.insuranceClaimAuthorizationNumber}</li>
                )}
                {action.insuranceClaimApprovalAmount && (
                  <li>
                    Approved Amount:{' '}
                    {formatPrice(action.insuranceClaimApprovalAmount)}
                  </li>
                )}
                {action.insuranceClaimComment && (
                  <li>Comment: {action.insuranceClaimComment}</li>
                )}
                {action.notQualifiedReason && (
                  <li>Not Qualified Reason: {action.notQualifiedReason}</li>
                )}
                {action.attachmentUrl && (
                  <li>
                    <Button
                      className="flex gap-3 items-center h-8 text-white bg-indigo-500"
                      onClick={async () => downloadAttachment(action)}
                    >
                      <TbCloudDownload size={15} /> Download
                    </Button>
                  </li>
                )}
                {action.attachmentDescription && (
                  <li>Description: {action.attachmentDescription}</li>
                )}
                {action.saleData && (
                  <>
                    <li>Offer: {action.saleData.offer.name}</li>
                    <li>Value: {action.saleData.offer.price} AED</li>
                    <li>Note: {action.saleData.offer.note ?? '-'}</li>
                  </>
                )}
              </ul>

              <div className="text-sm text-gray-600 mt-4 ml-1">
                By: {action.user?.firstName || ''} {action.user?.lastName || ''}
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      )}
    </div>
  )
}
