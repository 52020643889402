import { useGetStatisticsQuery } from 'app/features/whatsappStats'
import { useEffect, useState } from 'react'
import {
  TbAlertTriangle,
  TbAsterisk,
  TbCheck,
  TbChecks,
  TbInfoHexagon,
  TbMessage,
  TbMessages,
  TbTemplate,
} from 'react-icons/tb'
import Stat from 'components/shared/dashboard-stat/dashboard-stat'
import { toast } from 'components/ui/use-toast'
import dayjs from 'dayjs'
import { WhatsappStatsProps } from '../index'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import FailedMessages from './failed-messages'

export const ConversationsStatistics = ({
  startDate,
  endDate,
  whatsappAccount,
  setLoading,
}: WhatsappStatsProps) => {
  const [totalConversations, setTotalConversations] = useState(0)
  const [totalNewConversations, setTotalNewConversations] = useState(0)
  const [totalOpenConversations, setTotalOpenConversations] = useState(0)
  const [totalEngagedConversations, setTotalEngagedConversations] = useState(0)
  const [totalSentMessages, setTotalSentMessages] = useState(0)
  const [totalDeliveredMessages, setTotalDeliveredMessages] = useState(0)
  const [totalReadMessages, setTotalReadMessages] = useState(0)
  const [totalFailedMessages, setTotalFailedMessages] = useState(0)
  const [totalTemplateMessages, setTotalTemplateMessages] = useState(0)

  const [failedMessagesDialogOpen, setFailedMessagesDialogOpen] =
    useState(false)

  const {
    refetch,
    isLoading,
    isFetching,
    data: data,
    isError: isError,
  } = useGetStatisticsQuery({
    whatsappAccount: whatsappAccount,
    ...(startDate && {
      from: dayjs(startDate).toISOString(),
    }),
    ...(endDate && {
      to: dayjs(endDate).toISOString(),
    }),
  })

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching statistics',
      })
    }
  }, [isError])

  useEffect(() => {
    if (data) {
      const {
        totalConversations,
        totalNewConversations,
        totalOpenConversations,
        totalEngagedConversations,
        totalSentMessages,
        totalDeliveredMessages,
        totalReadMessages,
        totalFailedMessages,
        totalTemplateMessages,
      } = data

      setTotalConversations(totalConversations)
      setTotalNewConversations(totalNewConversations)
      setTotalOpenConversations(totalOpenConversations)
      setTotalEngagedConversations(totalEngagedConversations)
      setTotalSentMessages(totalSentMessages)
      setTotalDeliveredMessages(totalDeliveredMessages)
      setTotalReadMessages(totalReadMessages)
      setTotalFailedMessages(totalFailedMessages)
      setTotalTemplateMessages(totalTemplateMessages)
    }
  }, [data])

  useEffect(() => {
    setLoading(isLoading || isFetching)
  }, [isLoading, isFetching])

  return (
    <>
      <h1 className="text-2xl font-bold">Conversations</h1>
      <div className="grid grid-cols-8 gap-4 m-4 max-lg:grid-cols-4 max-md:grid-cols-2">
        <Stat
          number={totalConversations}
          title="Total"
          icon={<TbMessage size={26} color="gray" />}
          description="Conversations count having all messages created within the date range filter."
        />
        <Stat
          number={totalNewConversations}
          title="New"
          icon={<TbAsterisk size={26} color="gray" />}
          description="Conversations count that have at least one lead (inquiry) that have been created within the date range filter."
        />
        <Stat
          number={totalOpenConversations}
          title="Open"
          icon={<TbInfoHexagon size={26} color="gray" />}
          description="Conversations count that have unread messages within the date range filter."
        />
        <Stat
          number={totalEngagedConversations}
          title="Engaged"
          icon={<TbMessages size={26} color="gray" />}
          description="Conversations count that have at least one message by lead within the date range filter."
        />
      </div>

      <h1 className="text-2xl font-bold">Messages </h1>
      <div className="grid grid-cols-8 gap-4 m-4 max-lg:grid-cols-4 max-md:grid-cols-2">
        <Stat
          number={totalSentMessages}
          title="Sent"
          icon={<TbCheck size={26} color="gray" />}
          description="Total number of sent messages"
        />
        <Stat
          number={totalDeliveredMessages}
          title="Delivered"
          icon={<TbChecks size={26} color="gray" />}
          description="Total number of sent messages"
        />
        <Stat
          number={totalReadMessages}
          title="Read"
          icon={<TbChecks size={26} />}
          description="Total number of read messages"
        />
        <Stat
          number={totalFailedMessages}
          title="Failed"
          icon={<TbAlertTriangle size={26} color="red" />}
          description="Total number of failed messages"
          onClick={() => setFailedMessagesDialogOpen(true)}
        />
        <Stat
          number={totalTemplateMessages}
          title="Template"
          icon={<TbTemplate size={24} color="gray" />}
          description="Total number of Template messages sent"
        />
      </div>

      <AlertDialog
        open={failedMessagesDialogOpen}
        onOpenChange={setFailedMessagesDialogOpen}
      >
        <AlertDialogContent className="sm:max-w-[800px] md:max-w-[800px] bg-white dark:bg-gray-900 pr-3">
          <FailedMessages
            startDate={startDate}
            endDate={endDate}
            whatsappAccount={whatsappAccount}
            closeDialog={() => setFailedMessagesDialogOpen(false)}
          />
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
