import { Cross1Icon } from '@radix-ui/react-icons'
import { useGetCampaignsQuery } from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import dayjs from 'dayjs'
import Pagination from 'components/table/pagination'
import { useEffect, useState } from 'react'

interface ListCampaignsProps {
  open: boolean
  setOpen: (open: any) => void
  whatsappAccount: string
}
export default function ListCampaigns({
  open,
  setOpen,
  whatsappAccount,
}: ListCampaignsProps) {
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
    whatsappId: whatsappAccount,
  })
  const { data, isLoading, isError } = useGetCampaignsQuery({ ...filters })

  useEffect(() => {
    setFilters({
      skip: Math.abs((currentPage - 1) * rowsPerPage),
      take: rowsPerPage,
      whatsappId: whatsappAccount,
    })
  }, [currentPage, rowsPerPage, whatsappAccount])

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild></AlertDialogTrigger>
        <AlertDialogContent className="sm:max-w-[1200px] md:max-w-[1200px] bg-white dark:bg-gray-900 pr-3">
          {isLoading && (
            <div className="h-40 grid place-content-center">
              <Loader />
            </div>
          )}
          {isError && (
            <div className="h-40 grid place-content-center">
              <Error message="Server error, contact administrator please" />
            </div>
          )}
          {!isError && !isLoading && (
            <div className="h-full w-full">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">Campaigns</h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="w-full max-h-[550px] overflow-hidden overflow-y-scroll mt-4">
                <div className="w-full overflow-hidden overflow-x-scroll mb-10">
                  <table className="w-full table-fixed">
                    <thead>
                      <tr className="text-left border-b w-full ">
                        <th className="text-center xl:w-52">Template</th>
                        <th className="text-center xl:w-40 ">Created By</th>
                        <th className="text-center xl:w-48">Date</th>
                        <th className="text-center xl:w-28">Pending</th>
                        <th className="text-center xl:w-28">Sent</th>
                        <th className="text-center xl:w-28">Delivered</th>
                        <th className="text-center xl:w-28">Read</th>
                        <th className="text-center xl:w-28">Failed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.items?.map((campaign: any, index: number) => (
                        <tr
                          key={campaign.id + index}
                          className="w-full border-b border-gray-100 last:border-b-0 py-1"
                        >
                          <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {campaign?.templateName?.replace('_', ' ')}
                          </td>
                          <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {campaign?.createdBy}
                          </td>
                          <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {dayjs(campaign?.createdAt).format(
                              'MM/DD/YYYY  hh:mm A'
                            )}
                          </td>
                          <td className="text-center">
                            {<Items mode={'Pending'} data={campaign.pending} />}
                          </td>
                          <td className="text-center">
                            {<Items mode={'Sent'} data={campaign.sent} />}
                          </td>
                          <td className="text-center">
                            {
                              <Items
                                mode={'Delivered'}
                                data={campaign.delivered}
                              />
                            }
                          </td>
                          <td className="text-center">
                            {<Items mode={'Read'} data={campaign.read} />}
                          </td>

                          <td className="text-center">
                            {<Items mode={'Failed'} data={campaign.failed} />}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {data?.campaigns?.length == 0 && (
                    <div className="h-40 w-full grid place-content-center">
                      No campaigns created
                    </div>
                  )}
                </div>
                {data && data?.count > 0 && (
                  <Pagination
                    count={data?.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                )}
              </div>
            </div>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

function Items({ data, mode }: any) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger>
          {data?.length > 0 && (
            <Button
              size={'sm'}
              variant={'outline'}
              className="w-20  max-sm:w-16"
            >
              {data.length} {mode}
            </Button>
          )}
        </AlertDialogTrigger>
        <AlertDialogContent className="max-w-[500px] bg-white dark:bg-gray-900 pr-3">
          <div className="h-full w-full">
            <div className="flex w-full items-center justify-between">
              <h1 className="text-lg font-semibold capitalize">{mode}</h1>

              <Button
                variant="outline"
                size="icon"
                onClick={() => setOpen(false)}
              >
                <Cross1Icon className="h-3 w-3" />
              </Button>
            </div>

            <div className="w-full max-h-[550px] overflow-hidden overflow-y-scroll mt-4">
              <div className="w-full overflow-hidden overflow-x-scroll mb-10">
                <table className="w-full table-fixed">
                  <thead>
                    <tr className="text-left border-b w-full ">
                      <th className="xl:w-60">Name</th>
                      <th className="xl:w-40 ">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item: any) => (
                      <tr
                        key={item.id}
                        className="w-full border-b border-gray-100 last:border-b-0 py-1"
                      >
                        <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                          {/* <Link
                            to={`/lead-details/${item.id}`}
                            className="text-blue-600 underline"
                          > */}
                          {item.name}
                          {/* </Link> */}
                        </td>
                        <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                          {item.phoneNumber}{' '}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {data?.length == 0 && (
                  <div className="h-40 w-full grid place-content-center">
                    No {mode} messages
                  </div>
                )}
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
