import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useMediaFileMutation,
  useSendTemplateMutation,
} from 'app/features/whatsapp'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button, buttonVariants } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

import {
  cn,
  containsSubstring,
  convertToCamelCase,
  convertToTitleCase,
  prepareMediaFormData,
} from 'lib/utils'
import { useEffect, useRef, useState } from 'react'
import { FcDataSheet } from 'react-icons/fc'
import { GiReturnArrow } from 'react-icons/gi'
import { TEMPLATE_VARIABLES } from './actions/manage-templates/create-template'
import PreviewTemplateMessage from './actions/preview-template-message'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel'
import { toast } from 'components/ui/use-toast'
import { checkPaymentError } from '../utils/check-payment-error'
import { MAX_FILE_SIZE } from './actions/create-campaign/bulk-upload'

interface SendTemplateProps {
  selectedAccountId: any
  template: any
  open: boolean
  lead?: any
  setOpen: (open: any) => void
  phoneNumber?: string
  startMessagingLeadId?: string
  displaySentTemplate: (template: any) => void
}

const initialData = {
  name: '',
  language: '',
  mediaSample: '',
  headerMediaSampleFile: null,
  headerMediaSampleFileContent: null,
  headerTextSample: {},
  bodySample: {},
  carousel: [],
}

export default function SendTemplate({
  selectedAccountId,
  template,
  open,
  setOpen,
  phoneNumber,
  lead,
  startMessagingLeadId,
  displaySentTemplate,
}: SendTemplateProps) {
  const [sendTemplateFunction] = useSendTemplateMutation()
  const [mediaFileFunction] = useMediaFileMutation()
  const [data, setData] = useState<any>(initialData)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )

  const carouselComponent = template?.components?.find(
    (component: any) => component.type == 'CAROUSEL'
  )

  const headerFormat = headerComponent?.format
  const headerTextVariables = headerComponent?.example?.header_text
  const headerTextVariablesCount = headerComponent?.example?.header_text?.length
  const bodyVariables = bodyComponent?.example?.body_text
  const bodyVariablesCount =
    bodyComponent?.example?.body_text?.length > 0
      ? bodyComponent?.example?.body_text[0].length
      : 0

  useEffect(() => {
    const headerTextSample: any = {}
    if (headerTextVariablesCount > 0) {
      for (let i = 0; i < headerTextVariablesCount; i++) {
        //headerTextSample[i + 1] = headerTextVariables[i]
        for (let j = 0; j < TEMPLATE_VARIABLES.length; j++) {
          if (
            containsSubstring(headerTextVariables[i], TEMPLATE_VARIABLES[j])
          ) {
            headerTextSample[i + 1] =
              lead[convertToCamelCase(TEMPLATE_VARIABLES[j])]
          }
        }
      }
    }

    const bodySample: any = {}
    if (bodyVariablesCount > 0) {
      for (let i = 0; i < bodyVariablesCount; i++) {
        //bodySample[i + 1] = bodyVariables[0][i]
        for (let j = 0; j < TEMPLATE_VARIABLES.length; j++) {
          if (containsSubstring(bodyVariables[0][i], TEMPLATE_VARIABLES[j])) {
            bodySample[i + 1] = lead[convertToCamelCase(TEMPLATE_VARIABLES[j])]
          }
        }
      }
    }
    setData({
      ...initialData,
      headerTextSample,
      bodySample,
    })
    setError('')
    setLoading(false)
  }, [open])

  const validateNormalData = () => {
    /* header text variables validation */

    const headerTextSample = Object.values(data.headerTextSample)
    if (
      headerTextSample.length < headerTextVariablesCount ||
      headerTextSample.some((element) => element == '' || !element)
    ) {
      {
        throw new Error('Header text variables required')
      }
    }

    /* header media sample validation */
    if (
      (headerFormat == 'VIDEO' ||
        headerFormat == 'IMAGE' ||
        headerFormat == 'DOCUMENT') &&
      !data.headerMediaSampleFile
    ) {
      throw new Error('Media sample required')
    }

    /* body variables validation */
    const bodySample = Object.values(data.bodySample)
    if (
      bodySample.length < bodyVariablesCount ||
      bodySample.some((element) => element == '' || !element)
    ) {
      {
        throw new Error('Body variables sample required')
      }
    }
  }

  const validateCarouselData = () => {
    /* body variables validation */
    const bodySample = Object.values(data.bodySample)
    if (
      bodySample.length < bodyVariablesCount ||
      bodySample.some((element) => element == '' || !element)
    ) {
      {
        throw new Error('Body variables sample required')
      }
    }

    for (let i = 0; i < data?.carousel?.length; i++) {
      /* card body variables validation */

      const bodySample = Object.values(data.carousel[i].bodySample)
      if (
        bodySample.length < data.carousel[i].bodyVariablesCount ||
        bodySample.some((element) => element == '' || !element)
      ) {
        {
          throw new Error(`Card-${i + 1} Body variables sample required`)
        }
      }

      /* card header media sample validation */
      if (!data.carousel[i].headerMediaSampleFile) {
        throw new Error(`Card-${i + 1} - Media sample required`)
      }
    }
  }

  async function sendTemplate() {
    setLoading(true)
    setError('')
    if (!carouselComponent) await sendNormalTemplate()

    if (carouselComponent) await sendCarouselTemplate()
  }

  const sendNormalTemplate = async () => {
    try {
      validateNormalData()
    } catch (err: any) {
      setLoading(false)
      return setError(err.message)
    }
    try {
      const headerTextSample = Object.values(data.headerTextSample)
      const bodySample = Object.values(data.bodySample)

      /* Header media upload */
      let headerMediaSampleFile = null

      const formData = prepareMediaFormData({
        my_file: data.headerMediaSampleFile,
      })

      if (
        headerFormat == 'VIDEO' ||
        headerFormat == 'IMAGE' ||
        headerFormat == 'DOCUMENT'
      ) {
        if (data.headerMediaSampleFile.size > MAX_FILE_SIZE) {
          setLoading(false)
          return setError('Too large file! File exceeded 10 mb limits')
        }
        const uploadResult: any = await mediaFileFunction({
          formData,
          accountId: selectedAccountId,
        })
        if (uploadResult?.error) {
          setLoading(false)
          return setError('Failed to upload media, please try again')
        }
        headerMediaSampleFile = uploadResult?.data?.id
      }

      /* create template */
      const result: any = await sendTemplateFunction({
        data: {
          messaging_product: 'whatsapp',
          to: phoneNumber,
          leadName: lead?.firstName,
          startMessagingLeadId,
          template: {
            name: template?.name,
            category: template?.category,
            language: template?.language,
            headerFormat,
            headerMediaSampleFile,
            headerTextSample,
            bodySample,
            components: template?.components,
          },
        },
        accountId: selectedAccountId,
      })

      if (result?.error) {
        checkPaymentError(result?.error)
        setLoading(false)
        return setError('Failed to send template, please try again')
      }

      displaySentTemplate({
        name: template?.name,
        language: template?.language,
        headerMediaSampleFile,
        headerTextSample,
        bodySample,
      })
      setOpen(false)
    } catch (error) {
      setLoading(false)
      return setError('Failed to send template, please try again')
    }
  }

  const sendCarouselTemplate = async () => {
    try {
      validateCarouselData()
    } catch (err: any) {
      setLoading(false)
      return setError(err.message)
    }

    try {
      const bodySample = Object.values(data.bodySample)
      const carousel: any = []
      for (let i = 0; i < data?.carousel?.length; i++) {
        /* Card Header media upload */
        const formData = prepareMediaFormData({
          my_file: data?.carousel[i].headerMediaSampleFile,
        })
        if (data?.carousel[i].headerMediaSampleFile.size > MAX_FILE_SIZE) {
          setLoading(false)
          return setError(
            `Card-${i + 1} Too large file! File exceeded 10 mb limits`
          )
        }
        const uploadResult: any = await mediaFileFunction({
          formData,
          accountId: selectedAccountId,
        })
        if (uploadResult?.error) {
          setLoading(false)
          return setError(
            `Card-${i + 1} Failed to upload media, please try again`
          )
        }

        const bodySample = Object.values(data.carousel[i].bodySample)

        const headerMediaSampleFile = uploadResult?.data?.id

        carousel.push({
          bodySample,
          headerMediaSampleFile,
          headerFormat: data.carousel[i].headerFormat,
        })
      }

      /* create template */
      const result: any = await sendTemplateFunction({
        data: {
          messaging_product: 'whatsapp',
          to: phoneNumber,
          leadName: lead?.firstName,
          startMessagingLeadId,
          template: {
            name: template?.name,
            category: template?.category,
            language: template?.language,
            bodySample,
            components: template?.components,
            carousel,
          },
        },
        accountId: selectedAccountId,
      })

      if (result?.error) {
        checkPaymentError(result?.error)
        setLoading(false)
        return setError('Failed to send template, please try again')
      }
      displaySentTemplate({
        name: template?.name,
        language: template?.language,

        bodySample,
      })
      setOpen(false)
    } catch (error) {
      setLoading(false)
      return setError('Failed to send template, please try again')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[500px] md:max-w-[850px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
            <div className="flex w-full items-center justify-between  pb-4 border-b">
              <h1 className="text-lg font-semibold capitalize">
                Send Template
              </h1>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setOpen(false)}
              >
                <Cross1Icon className="h-3 w-3" />
              </Button>
            </div>

            {!carouselComponent && (
              <div className="flex gap-2 max-md:flex-col items-center">
                {template && (
                  <PreviewTemplateMessage
                    template={template}
                    headerTextSample={Object.values(data.headerTextSample)}
                    bodySample={Object.values(data.bodySample)}
                    headerMediaSample={data.headerMediaSampleFileContent}
                  />
                )}
                <div className=" p-4">
                  {((headerFormat == 'TEXT' && headerTextVariablesCount > 0) ||
                    headerFormat == 'VIDEO' ||
                    headerFormat == 'IMAGE' ||
                    headerFormat == 'DOCUMENT' ||
                    bodyVariablesCount > 0) && (
                    <h1 className="flex items-center font-medium gap-1  ">
                      Fill dynamic information <FcDataSheet size={20} />
                    </h1>
                  )}
                  <div className="w-full space-y-3 mt-5 px-1">
                    {((headerFormat == 'TEXT' &&
                      headerTextVariablesCount > 0) ||
                      headerFormat == 'VIDEO' ||
                      headerFormat == 'IMAGE' ||
                      headerFormat == 'DOCUMENT') && (
                      <div className="w-full space-y-2  p-2 ">
                        <Label>Header</Label>
                        {headerFormat == 'TEXT' &&
                          headerTextVariablesCount > 0 && (
                            <DynamicTextArea
                              data={data}
                              setData={setData}
                              indexKey={'headerText'}
                              vars={headerTextVariablesCount}
                              samples={headerTextVariables}
                            />
                          )}
                        {(headerFormat == 'VIDEO' ||
                          headerFormat == 'IMAGE' ||
                          headerFormat == 'DOCUMENT') && (
                          <MediaSelect
                            data={data}
                            setData={setData}
                            indexKey="headerMedia"
                            format={headerFormat}
                          />
                        )}
                      </div>
                    )}
                    {bodyVariablesCount > 0 && (
                      <div className="w-full space-y-2  p-2 ">
                        <Label>Body</Label>

                        <DynamicTextArea
                          data={data}
                          setData={setData}
                          indexKey={'body'}
                          vars={bodyVariablesCount}
                          samples={bodyVariables[0]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {carouselComponent && (
              <div className="flex gap-2 max-md:flex-col items-center">
                {template && (
                  <PreviewTemplateMessage
                    template={template}
                    headerTextSample={Object.values(data.headerTextSample)}
                    bodySample={Object.values(data.bodySample)}
                    headerMediaSample={data.headerMediaSampleFileContent}
                    carousel={data.carousel?.map((card: any) => {
                      return {
                        bodySample: Object.values(card.bodySample || {}),
                        headerMediaSample: card.headerMediaSampleFileContent,
                      }
                    })}
                  />
                )}
                <div className=" p-4">
                  <h1 className="flex items-center font-medium gap-1  ">
                    Fill dynamic information <FcDataSheet size={20} />
                  </h1>

                  <div className="w-full space-y-3 mt-5 px-1">
                    {bodyVariablesCount > 0 && (
                      <div className="w-full space-y-2  p-2 ">
                        <Label>Body</Label>

                        <DynamicTextArea
                          data={data}
                          setData={setData}
                          indexKey={'body'}
                          vars={bodyVariablesCount}
                          samples={bodyVariables[0]}
                        />
                      </div>
                    )}
                    <Carousel className="w-full max-w-xs bg-slate-100 rounded-md  ">
                      <CarouselContent>
                        {carouselComponent?.cards?.map((card: any, i: any) => {
                          return (
                            <CarouselItem key={i}>
                              <div className="p-1 pt-0 w-full">
                                <FillCarouselCard
                                  data={data}
                                  setData={setData}
                                  index={i}
                                  open={open}
                                  template={card}
                                />
                              </div>
                            </CarouselItem>
                          )
                        })}
                      </CarouselContent>
                      <CarouselPrevious className="translate-x-10 text-indigo-600 hover:text-indigo-900" />
                      <CarouselNext className=" -translate-x-10 text-indigo-600 hover:text-indigo-900" />
                    </Carousel>
                  </div>
                </div>
              </div>
            )}

            {error !== '' && (
              <div className="text-rose-600 text-sm">{error}</div>
            )}
            <div className="flex items-center gap-3 justify-between">
              <div />
              {/**TOTO: preview button */}
              <Button size="sm" onClick={sendTemplate} disabled={loading}>
                Send
              </Button>
            </div>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function FillCarouselCard({ data, setData, template, open, lead, index }: any) {
  const [card, setCard] = useState<any>({})

  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )

  const headerFormat = headerComponent?.format

  const bodyVariables = bodyComponent?.example?.body_text
  const bodyVariablesCount =
    bodyComponent?.example?.body_text?.length > 0
      ? bodyComponent?.example?.body_text[0].length
      : 0

  useEffect(() => {
    const bodySample: any = {}
    if (bodyVariablesCount > 0) {
      for (let i = 0; i < bodyVariablesCount; i++) {
        //bodySample[i + 1] = bodyVariables[0][i]
        for (let j = 0; j < TEMPLATE_VARIABLES.length; j++) {
          if (containsSubstring(bodyVariables[0][i], TEMPLATE_VARIABLES[j])) {
            bodySample[i + 1] = lead[convertToCamelCase(TEMPLATE_VARIABLES[j])]
          }
        }
      }
    }
    setCard({
      bodySample,
      headerFormat,
      bodyVariablesCount,
    })
  }, [open])

  useEffect(() => {
    setData((prev: any) => {
      const carousel = [...prev.carousel]
      carousel[index] = card
      return {
        ...prev,
        carousel,
      }
    })
  }, [card])

  return (
    <div className="h-full w-full p-2">
      <Label className="p-2">Card-{index + 1}</Label>
      <div className="w-full space-y-3 mt-5 px-1">
        <div className="w-full space-y-2  p-2 ">
          <Label>Header</Label>

          <MediaSelect
            data={card}
            setData={setCard}
            indexKey="headerMedia"
            format={headerFormat}
          />
        </div>

        {bodyVariablesCount > 0 && (
          <div className="w-full space-y-2  p-2 ">
            <Label>Body</Label>

            <DynamicTextArea
              data={card}
              setData={setCard}
              indexKey={'body'}
              vars={bodyVariablesCount}
              samples={bodyVariables[0]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function DynamicTextArea({ data, setData, indexKey, vars, samples }: any) {
  const sampleKey = indexKey + 'Sample'

  const handleVariableValueChange = (index: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [sampleKey]: { ...prev[sampleKey], [index]: value },
    }))
  }

  return (
    <div className="py-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          {Array.from(Array(vars)).map((placeholder: any, index: any) => (
            <div className="flex  gap-1 items-center">
              <Label className="w-1/2 ">
                {convertToTitleCase(samples[index])}
                <span className="ml-1 text-rose-500 ">*</span>
              </Label>
              <Input
                type={'text'}
                className=""
                placeholder={samples[index]}
                value={data[sampleKey][index + 1] || ''}
                onChange={(e) =>
                  handleVariableValueChange(index + 1, e.target.value)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function MediaSelect({ setData, data, indexKey, format }: any) {
  const fileRef = useRef<any>()
  const sampleKey = indexKey + 'SampleFile'
  const contentKey = indexKey + 'SampleFileContent'

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setData((prev: any) => {
      return { ...prev, [sampleKey]: null, [contentKey]: null }
    })
  }

  const handleMediaFileChange = (event: any) => {
    const file = event.target.files[0]
    setData((prev: any) => {
      return { ...prev, [sampleKey]: file }
    })
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setData((prev: any) => {
          return { ...prev, [sampleKey]: file, [contentKey]: reader.result }
        })
      }
      reader.readAsDataURL(file)
    }
  }
  return (
    <div>
      <div className="flex mt-2  flex-col gap-2">
        <div className="w-full items-center flex justify-between">
          <Label className="w-1/3 ">
            Media
            <span className="ml-1 text-rose-500 ">*</span>
          </Label>
          <label
            className={cn(
              buttonVariants({
                variant: 'ghost',
                size: 'icon',
              }),
              'h-9 w-44 border text-left text-xs',
              'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
            )}
          >
            ADD {format}
            <input
              type="file"
              ref={fileRef}
              accept={
                format == 'IMAGE'
                  ? 'image/*'
                  : format == 'VIDEO'
                    ? 'video/*'
                    : '*'
              }
              style={{ display: 'none' }}
              className="cursor-pointer"
              onChange={handleMediaFileChange}
            />
          </label>

          <Button
            onClick={replaceFileHandler}
            variant={'outline'}
            className="text-xs font-medium flex items-center"
          >
            reset
            <GiReturnArrow
              size={10}
              className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
            />
          </Button>
        </div>
        <span className="text-xs">{data[sampleKey]?.name}</span>
      </div>
    </div>
  )
}
