import DateAndTime from 'components/shared/date-and-time'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { useEffect, useState } from 'react'
import dayjs from 'lib/dayjs'
import { toast } from 'components/ui/use-toast'

export type DateFiltersProps = {
  setStartDate: (startDate: string | undefined) => void
  setEndDate: (startDate: string | undefined) => void
  disabled?: boolean
}

type dateRange =
  | 'Last 30 days'
  | 'Last month'
  | 'This week'
  | 'Last week'
  | 'Today'
  | 'Yesterday'
  | 'Custom date'
  | 'All'

export const DateFilters = ({
  setStartDate,
  setEndDate,
  disabled,
}: DateFiltersProps) => {
  const [selectedRange, setSelectedRange] = useState<dateRange>('Last 30 days')

  const [localStartDate, setLocalStartDate] = useState<Date | undefined>()
  const [startHour, setStartHour] = useState<string>()
  const [startMinute, setStartMinute] = useState<string>()
  const [startAmpm, setStartAmpm] = useState<string>()
  const [localEndDate, setLocalEndDate] = useState<Date | undefined>()
  const [endHour, setEndHour] = useState<string>()
  const [endMinute, setEndMinute] = useState<string>()
  const [endAmpm, setEndAmpm] = useState<string>()

  useEffect(() => {
    let from, to

    switch (selectedRange) {
      case 'Last 30 days':
        from = dayjs().subtract(30, 'day').toDate()
        to = dayjs().toDate()
        break
      case 'Last month':
        from = dayjs().subtract(1, 'month').startOf('month').toDate()
        to = dayjs().subtract(1, 'month').endOf('month').toDate()
        break
      case 'This week':
        from = dayjs().startOf('week').toDate()
        to = dayjs().endOf('week').toDate()
        break
      case 'Last week':
        from = dayjs().subtract(1, 'week').startOf('week').toDate()
        to = dayjs().subtract(1, 'week').endOf('week').toDate()
        break
      case 'Today':
        from = dayjs().startOf('day').toDate()
        to = dayjs().endOf('day').toDate()
        break
      case 'Yesterday':
        from = dayjs().subtract(1, 'day').startOf('day').toDate()
        to = dayjs().subtract(1, 'day').endOf('day').toDate()
        break
      case 'All':
        from = undefined
        to = undefined
        break
      default:
        from = undefined
        to = undefined
        break
    }

    setLocalStartDate(from)
    setLocalEndDate(to)
  }, [selectedRange])

  useEffect(() => {
    handleApplyDateRange()
  }, [
    localStartDate,
    startHour,
    startMinute,
    startAmpm,
    localEndDate,
    endHour,
    endMinute,
    endAmpm,
  ])

  /**
   * Applies the selected date range by updating the start and end dates.
   * Converts the local start and end dates based on selected hours, minutes,
   * and AM/PM values, then updates the state with these new Date objects.
   */
  const handleApplyDateRange = () => {
    if (selectedRange === 'All') {
      setStartDate(undefined)
      setEndDate(undefined)
    } else {
      let start = dayjs(localStartDate)
      if (startHour !== undefined) start = start.hour(Number(startHour))
      if (startMinute !== undefined) start = start.minute(Number(startMinute))
      if (startAmpm !== undefined)
        start = start.set(
          'hour',
          Number(startHour) + (startAmpm === 'PM' ? 12 : 0)
        )

      let end = dayjs(localEndDate)
      if (endHour !== undefined) end = end.hour(Number(endHour))
      if (endMinute !== undefined) end = end.minute(Number(endMinute))
      if (endAmpm !== undefined)
        end = end.set('hour', Number(endHour) + (endAmpm === 'PM' ? 12 : 0))

      if (
        selectedRange === 'Custom date' &&
        localStartDate &&
        localEndDate &&
        end.isSameOrBefore(start)
      ) {
        toast({
          title: 'Invalid date range',
          description: '"End" date must be after "Start" date',
        })

        return
      }

      setStartDate(start.toISOString())
      setEndDate(end.toISOString())
    }
  }

  return (
    <div className="flex flex-col-3 gap-4 justify-end mb-4">
      <div className="space-y-2 flex flex-col">
        <Label className="text-xs text-gray-500">Date range:</Label>
        <Select
          value={selectedRange}
          onValueChange={(value: any) => setSelectedRange(value)}
          disabled={disabled}
        >
          <SelectTrigger className="w-40 bg-white">
            <SelectValue>{selectedRange}</SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="Last 30 days">Last 30 days</SelectItem>
              <SelectItem value="Last month">Last month</SelectItem>
              <SelectItem value="This week">This week</SelectItem>
              <SelectItem value="Last week">Last week</SelectItem>
              <SelectItem value="Today">Today</SelectItem>
              <SelectItem value="Yesterday">Yesterday</SelectItem>
              <SelectItem value="Custom date">Custom Date</SelectItem>
              <SelectItem value="All">All</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      {selectedRange === 'Custom date' ? (
        <>
          <div className="space-y-2 flex flex-col">
            <Label className="text-xs text-gray-500">Start Date:</Label>
            <DateAndTime
              date={localStartDate}
              setDate={setLocalStartDate}
              hour={startHour}
              setHour={setStartHour}
              minute={startMinute}
              setMinute={setStartMinute}
              ampm={startAmpm}
              setAmpm={setStartAmpm}
              disabled={disabled}
            />
          </div>
          <div className="space-y-2 flex flex-col">
            <Label className="text-xs text-gray-500">End Date:</Label>
            <DateAndTime
              date={localEndDate}
              setDate={setLocalEndDate}
              hour={endHour}
              setHour={setEndHour}
              minute={endMinute}
              setMinute={setEndMinute}
              ampm={endAmpm}
              setAmpm={setEndAmpm}
              beforeSelectedDate={localStartDate}
              disabled={disabled}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}
