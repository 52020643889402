import useApp from 'hooks/useApp'
import { allUsersTabs, systemTabs } from 'pages/settings/active-tabs'
import { TbError404 } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export const TabsWrapper = ({ children, tab }: any) => {
  const { user } = useSelector((state: any) => state.user)
  const params = useParams()
  const { activeTabs: settingsActiveTabs } = useApp()
  const activeTabs =
    settingsActiveTabs?.length > 0
      ? [...settingsActiveTabs[0].activeTabs, ...systemTabs]
      : [...systemTabs]

  let isAccessible =
    (user.role === 'ADMIN' ||
      user.role === 'SUPERADMIN' ||
      user.group?.tabs?.includes(tab) ||
      allUsersTabs.includes(tab)) &&
    activeTabs.includes(tab)

  if (tab == 'Social' && user.role == 'AGENT') {
    const accountId = params.accountId
    isAccessible =
      isAccessible && user.group?.socialAccountIDs?.includes(accountId)
  }

  return (
    <div className="w-full h-[100vh-14h] md:h-full">
      {isAccessible ? (
        <>{children}</>
      ) : (
        <div className="w-full h-full  flex items-center justify-center">
          <div className="text-xs font-medium flex flex-col justify-center items-center gap-3">
            <TbError404 className="text-rose-600" size={30} />
            Not Found
          </div>
        </div>
      )}
    </div>
  )
}
