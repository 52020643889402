import {
  useGetConversationQuery,
  useSendMediaMutation,
  useSendMessageMutation,
} from 'app/features/whatsapp'
import { prepareMediaFormData } from 'lib/utils'
import React, { useEffect } from 'react'
import WhatsappChatList from '../chat-list/whats-app'
import { useLocation } from 'react-router-dom'
import { checkPaymentError } from '../utils/check-payment-error'
import useApp from 'hooks/useApp'

interface ChatProps {
  selectedAccountId: any
  selectedConversationId: string
  setConversationLeads: any
  selectedLead: any
  setSelectedLead: any
  lastReply: any
  initialLead: any
  isDetailsPage: any
  metaConversationId: any
  setRefreshContacts: any
}

export default function WhatsApp({
  selectedAccountId,
  selectedConversationId,
  setConversationLeads,
  selectedLead,
  setSelectedLead,
  lastReply,
  initialLead,
  isDetailsPage,
  metaConversationId,
  setRefreshContacts,
}: ChatProps) {
  const { setRefetchWhatsappContacts } = useApp()
  const [messagesState, setMessages] = React.useState<any[]>([])
  const [sendMessage] = useSendMessageMutation()
  const [sendMedia] = useSendMediaMutation()
  const {
    data: conversation,
    isLoading,
    isFetching,
  } = useGetConversationQuery(selectedConversationId)

  useEffect(() => {
    if (conversation) {
      setConversationLeads(conversation?.leads)
      const isSelectedLead = selectedLead
        ? conversation?.leads?.find((item: any) => item.id == selectedLead.id)
        : null
      if (isSelectedLead) setSelectedLead(isSelectedLead)
      else setSelectedLead(conversation?.leads[0])
    }
  }, [conversation])

  const sendMessageClick = async (newMessage: any) => {
    const result: any = await sendMessage({
      data: {
        text: newMessage,
        messaging_product: 'whatsapp',
        to: conversation?.phoneNumber,
      },
      accountId: selectedAccountId,
    })
    if (result?.error) {
      checkPaymentError(result?.error)
    }
    setRefetchWhatsappContacts(true)
    setMessages([...messagesState, newMessage])
  }

  const sendMediaClick = async (newMessage: any) => {
    const formData = prepareMediaFormData({
      my_file: newMessage.file,
      type: newMessage.type,
      to: conversation?.phoneNumber || '',
    })
    await sendMedia({ formData, accountId: selectedAccountId })
    setRefetchWhatsappContacts(true)
    setMessages([...messagesState, newMessage])
  }

  const sendTemplateClick = async (template: any) => {
    // sendTemplateFunction({
    //   template: template,
    //   messaging_product: 'whatsapp',
    //   to: conversation?.phoneNumber,
    // })
    setRefetchWhatsappContacts(true)
    setMessages([...messagesState, template])
  }

  return (
    <WhatsappChatList
      selectedAccountId={selectedAccountId}
      messages={conversation?.messages}
      phoneNumber={conversation?.phoneNumber}
      selectedLeadId={selectedLead?.id}
      selectedLead={selectedLead}
      conversationLeads={conversation?.leads}
      setSelectedLead={setSelectedLead}
      tags={conversation?.tags}
      selectedConversationId={conversation?.id}
      selectedConversationName={conversation?.name}
      lastReply={lastReply}
      sendMessage={sendMessageClick}
      sendMedia={sendMediaClick}
      sendTemplate={sendTemplateClick}
      isFetching={isFetching}
      isLoading={isLoading}
      initialLead={initialLead}
      isDetailsPage={isDetailsPage}
      metaConversationId={metaConversationId}
      setRefreshContacts={setRefreshContacts}
    />
  )
}
