import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarShortcut,
  MenubarTrigger,
} from 'components/ui/menubar'
import CreateTemplate from './actions/manage-templates/create-template'
import CreateCampaign from './actions/create-campaign/create-campaign'
import InitConversation from './init-conversation'
import { useEffect, useState } from 'react'
import {
  MdContactEmergency,
  MdContacts,
  MdContactSupport,
  MdSpaceDashboard,
  MdGroupOff,
  MdAdsClick,
} from 'react-icons/md'
import ViewTemplates from './actions/manage-templates/view-templates'
import { TbDotsVertical } from 'react-icons/tb'
import { Button } from 'components/ui/button'
import { useSelector } from 'react-redux'
import ListCampaigns from './actions/list-campaigns'
import { useNavigate } from 'react-router-dom'
import { FaT } from 'react-icons/fa6'
import { useParams } from 'react-router-dom'
import ListUnsubscribedNUmbers from './actions/unsubscribed-numbers'

export const Actions = ({
  selectedAccountId,
  mobileView,
}: {
  selectedAccountId: any
  mobileView?: boolean
}) => {
  const navigate = useNavigate()
  const params = useParams()

  const { user } = useSelector((state: any) => state.user)
  const [openInitConversation, setOpenInitConversation] = useState(false)
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false)
  const [openCreateCampaign, setOpenCreateCampaign] = useState(false)
  const [openViewTemplates, setOpenViewTemplates] = useState(false)
  const [openListCampaigns, setOpenListCampaigns] = useState(false)
  const [showCreateCampaign, setShowCreateCampaign] = useState(true)
  const [showCreateTemplate, setShowCreateTemplate] = useState(true)
  const [unsubscribedNumbers, openUnsubscribedNumbers] = useState(false)

  /**
   * Navigates to the WhatsApp dashboard for the selected account.
   */
  const openWhatsappDashboard = () => {
    const accountId = params.accountId

    navigate(`/social/${accountId}/whatsapp-dashboard`)
  }

  useEffect(() => {
    if (user?.role === 'AGENT') {
      setShowCreateCampaign(user?.group?.roles?.includes('Create_campaign'))

      setShowCreateTemplate(user?.group?.roles?.includes('Create_template'))
    }
  }, [user])

  const navigateToMetaAds = () => {
    navigate(`/social/${params.accountId}/meta-ads`)
  }

  return (
    <>
      <Menubar
        className={`border-none h-10 w-8   z-10   ${mobileView && 'w-11'}`}
      >
        <MenubarMenu>
          <MenubarTrigger
            className={`disabled:text-gray-300 hover:cursor-pointer p-0 w-8  ${mobileView && 'w-9'}  `}
            disabled={!selectedAccountId}
          >
            <Button
              variant={mobileView ? 'outline' : 'ghost'}
              className={`flex h-8 w-8  p-0 data-[state=open]:bg-muted relative ${mobileView && 'w-9'}`}
              disabled={!selectedAccountId}
            >
              <TbDotsVertical size={18} />
            </Button>
          </MenubarTrigger>

          <MenubarContent className="bg-white border">
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenInitConversation(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              {/* <InitConversation />
               */}
              <span>New Contact</span>

              <MenubarShortcut>
                <MdContacts size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            {showCreateTemplate && (
              <MenubarItem
                onSelect={(e) => {
                  e.preventDefault()
                  setOpenCreateTemplate(true)
                }}
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
              >
                {/* <CreateTemplate />
                 */}
                <span>New Template</span>

                <MenubarShortcut>
                  <FaT size={15} className="text-gray-700" />
                </MenubarShortcut>
              </MenubarItem>
            )}
            {showCreateCampaign && (
              <MenubarItem
                onSelect={(e) => {
                  e.preventDefault()
                  setOpenCreateCampaign(true)
                }}
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
              >
                {' '}
                <span>New Campaign</span>
                <MenubarShortcut>
                  <MdContactEmergency size={15} className="text-gray-700" />
                </MenubarShortcut>
              </MenubarItem>
            )}

            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenViewTemplates(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              <span>All Templates</span>

              <MenubarShortcut>
                <FaT size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenListCampaigns(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              {' '}
              <span>All Campaigns</span>
              <MenubarShortcut>
                <MdContactEmergency size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                openUnsubscribedNumbers(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              <span>View Un-subscribed</span>

              <MenubarShortcut>
                <MdGroupOff size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                navigateToMetaAds()
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              <span>Meta Ads</span>
              <MenubarShortcut>
                <MdAdsClick size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            {user.role != 'AGENT' && (
              <MenubarItem
                onSelect={(e) => {
                  e.preventDefault()
                  openWhatsappDashboard()
                }}
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
              >
                <span>Dashboard & statistics</span>

                <MenubarShortcut>
                  <MdSpaceDashboard size={15} className="text-gray-700" />
                </MenubarShortcut>
              </MenubarItem>
            )}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      {/* Have to follow this approach and not put Init Conversation directly in the MenubarItem depending on its trigger to solve the space issue */}
      <InitConversation
        selectedAccountId={selectedAccountId}
        open={openInitConversation}
        setOpen={setOpenInitConversation}
      />
      <CreateTemplate
        selectedAccountId={selectedAccountId}
        open={openCreateTemplate}
        setOpen={setOpenCreateTemplate}
      />
      <CreateCampaign
        selectedAccountId={selectedAccountId}
        open={openCreateCampaign}
        setOpen={setOpenCreateCampaign}
      />

      <ViewTemplates
        selectedAccountId={selectedAccountId}
        open={openViewTemplates}
        setOpen={setOpenViewTemplates}
      />
      <ListCampaigns
        open={openListCampaigns}
        setOpen={setOpenListCampaigns}
        whatsappAccount={params.accountId || ''}
      />

      <ListUnsubscribedNUmbers
        open={unsubscribedNumbers}
        setOpen={openUnsubscribedNumbers}
      />
    </>
  )
}
