import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from 'recharts'
import { useGetCampaignsStatsQuery } from 'app/features/whatsappStats'
import { useEffect } from 'react'
import dayjs from 'lib/dayjs'
import { toast } from 'components/ui/use-toast'
import { WhatsappStatsProps } from '../index'
import Loader from 'components/shared/loader'

export const CampaignStatistics = ({
  startDate,
  endDate,
  whatsappAccount,
  setLoading,
}: WhatsappStatsProps) => {
  const {
    refetch,
    isLoading,
    isFetching,
    data: campaigns,
    isError: isError,
  } = useGetCampaignsStatsQuery({
    whatsappAccount,
    ...(startDate && {
      from: dayjs(startDate).toISOString(),
    }),
    ...(endDate && {
      to: dayjs(endDate).toISOString(),
    }),
  })

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching campaigns',
      })
    }
  }, [isError])

  useEffect(() => {
    setLoading(isLoading || isFetching)
  }, [isLoading, isFetching])

  return (
    <>
      <h1 className="text-2xl font-bold mb-2">Campaigns</h1>
      <div className="text-sm mb-8">
        This chart visualizes the performance of our campaigns, including the
        number of successful, failed deliveries, and unsubscribed numbers
      </div>

      <div className="relative">
        <div>
          <LineChart
            width={1200}
            height={200}
            data={campaigns}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="createdAt"
              tickFormatter={(tick) => {
                return dayjs(tick).isValid() ? dayjs(tick).format('DD') : ''
              }}
            />
            <YAxis interval={1} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="succeeded" stroke="#82ca9d" />
            <Line type="monotone" dataKey="failed" stroke="#de3769" />
            <Line type="monotone" dataKey="unsubscribed" stroke="#fbbf24" />
          </LineChart>
        </div>
        <div>
          {isLoading && (
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
