import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useState, useEffect, useRef } from 'react'
import { TbCloudUpload, TbX } from 'react-icons/tb'
import { useAddBulkLeadsMutation } from 'app/features/lead'
import { useSelector } from 'react-redux'
import { useToast } from 'components/ui/use-toast'
import { Link } from 'react-router-dom'
import { HeadersMatcher } from './components/matchHeaders'
import useApp from 'hooks/useApp'
import { cleanPhoneNumber, validateEmail } from 'lib/utils'
import LeadInterest from 'pages/lead/components/lead-interest'
import { IoMdClose } from 'react-icons/io'
import { LuFileSpreadsheet } from 'react-icons/lu'

const MAX_FILE_SIZE = 10 * 1024 * 1024
interface LeadDataType {
  firstName: string
  phone: string
  sourceId: string
  email: string
}

export default function BulkUpload({ open, setOpen }: any) {
  const meta = useSelector((state: any) => state.app.meta)
  const { sources, branches, departments, specialists } = useApp()
  const { toast } = useToast()
  const { user, token } = useSelector((state: any) => state.user)
  const [jsonData, setJsonData] = useState<any>(null)
  const [leadsData, setLeadsData] = useState<LeadDataType[]>([])
  const [error, setError] = useState<string>('')
  const [notConfigured, setNotConfigured] = useState(false)
  const [columnMapping, setColumnMapping] = useState<Map<string, string>>(
    new Map()
  )
  const [reload, setReload] = useState(false)
  const [csvColumnHeaders, setCsvColumnHeaders] = useState<string[]>([])
  const columnNames = ['first_name', 'phone', 'source', 'email']
  const fileRef = useRef<any>()
  const [fileType, setFileType] = useState('')

  /**
   * Updates the column mapping with the given firstItem and secondItem
   * @param {string} firstItem - The column name in the CSV file
   * @param {string} secondItem - The column name in the lead form
   */
  const onColumnMatch = (firstItem: string, secondItem: string) => {
    const updatedColumnMapping = new Map(columnMapping)
    updatedColumnMapping.set(firstItem, secondItem)
    setColumnMapping(updatedColumnMapping)
  }

  const [addBulkLeads, { isLoading }] = useAddBulkLeadsMutation()

  const convertCSVToJson = (csvData: any) => {
    try {
      const lines = csvData.split('\n')
      const headers = lines[0].split(',')
      /* email is optional */
      if (headers.length < 3)
        throw new Error(
          'Please review the csv file, invalid columns, columns must be at least 3 ( first_name, source, phone )'
        )

      for (let i = 0; i < headers.length; i++) {
        if (!headers[i].trim()) {
          throw new Error('Please review the csv file, some headers are empty')
        }
      }

      setCsvColumnHeaders(headers)
      const result = []

      const notValidRows = []

      for (let i = 1; i < lines.length; i++) {
        const obj: any = {}
        const currentLine = lines[i].split(',')

        /* Validate the complete row*/
        if (!lines[i] || lines[i].trim() == '') continue

        if (currentLine.length != headers.length) {
          notValidRows.push(`(${i})`)
          continue
        }

        /* Validate each cell*/
        let isValid = true
        for (let j = 0; j < headers.length; j++) {
          if (
            headers[j].trim().toLowerCase() != 'email' &&
            !currentLine[j]?.trim()
          ) {
            isValid = false
            notValidRows.push(`(${i}, ${headers[j].trim()})`)
            break
            // throw new Error(
            //   `invalid CSV file, some ${headers[j].trim()} entries are empty!`
            // )
          }
          if (
            headers[j].trim().toLowerCase() == 'email' &&
            currentLine[j]?.trim() &&
            currentLine[j]?.trim() != '' &&
            !validateEmail(currentLine[j].trim())
          ) {
            isValid = false
            notValidRows.push(`(${i}, ${headers[j].trim()})`)
            break
            //throw new Error(`invalid CSV file, some email entries are invalid!`)
          }

          obj[headers[j].trim()] = currentLine[j].trim()
        }
        if (isValid) result.push(obj)
      }

      if (notValidRows.length > 0) {
        throw new Error(
          `invalid CSV file, rows [${notValidRows.join(', ')}] have invalid data`
        )
      }
      return result
    } catch (error: any) {
      setError(error.message || 'Invalid CSV file')
    }
  }

  const convertXLSXToJson = (data: any) => {
    try {
      if (data?.length == 0)
        throw new Error('Please review the xlsx file, data required')

      const headers = Object.keys(data[0])
      if (headers.includes('__EMPTY'))
        throw new Error('Please review the xlsx file, some headers are empty )')

      /* email is optional */
      if (headers.length < 3)
        throw new Error(
          'Please review the xlsx file, invalid columns, columns must be at least 3 ( first_name, source, phone )'
        )

      setCsvColumnHeaders(headers)
      const result = []

      const notValidRows = []

      for (let i = 0; i < data.length; i++) {
        const headersI = Object.keys(data[i])
        const values: any[] = Object.values(data[i])

        /* Validate the complete row*/
        if (headersI.includes('__EMPTY') || headersI.length > headers.length) {
          notValidRows.push(`(${i + 2})`)
          continue

          // empty rows are excluded by default
        }

        const obj: any = {}

        /* Validate each cell*/
        let isValid = true
        for (let j = 0; j < headers.length; j++) {
          if (
            headers[j].trim().toLowerCase() != 'email' &&
            !values[j]?.toString()?.trim()
          ) {
            isValid = false
            notValidRows.push(`(${i + 2}, ${headers[j].trim()})`)
            break
            // throw new Error(
            //   `invalid CSV file, some ${headers[j].trim()} entries are empty!`
            // )
          }
          if (
            headers[j].trim().toLowerCase() == 'email' &&
            values[j]?.toString()?.trim() &&
            values[j]?.toString()?.trim() != '' &&
            !validateEmail(values[j]?.toString()?.trim())
          ) {
            isValid = false
            notValidRows.push(`(${i + 2}, ${headers[j].trim()})`)
            break
            //throw new Error(`invalid CSV file, some email entries are invalid!`)
          }

          obj[headers[j].trim()] = values[j]?.toString()?.trim()
        }
        if (isValid) result.push(obj)
      }

      if (notValidRows.length > 0) {
        throw new Error(
          `invalid xlsx file, rows [${notValidRows.join(', ')}] have invalid data`
        )
      }
      return result
    } catch (error: any) {
      setError(error.message || 'Invalid xlsx file')
    }
  }

  const handleCSVInputChange = (event: any) => {
    event.preventDefault()
    setColumnMapping(new Map())
    setReload(true)
    setJsonData(null)
    setLeadsData([])
    setInterestData({
      branchIDs: [],
      departmentIDs: [],
      specialistIDs: [],
    })

    try {
      const file = event.target.files[0]
      if (file.size > MAX_FILE_SIZE) {
        return setError('Too large file! File exceeded 10 mb limits')
      }

      const reader = new FileReader()

      if (file.type == 'text/csv') {
        reader.onload = (e: any) => {
          const csvData = e.target.result
          const jsonData: any = convertCSVToJson(csvData)
          setJsonData(jsonData)
          setFileType('csv')
        }
        reader.readAsText(file)
      } else {
        reader.onload = (event: any) => {
          const workbook = XLSX.read(event.target.result, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const sheetData = XLSX.utils.sheet_to_json(sheet)
          const jsonData: any = convertXLSXToJson(sheetData)
          setJsonData(jsonData)
          setFileType('xlsx')
        }

        reader.readAsBinaryString(file)
      }
    } catch (error) {
      setError('Invalid  file')
    }
  }

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setColumnMapping(new Map())
    setReload(true)
    setLeadsData([])
    setJsonData(null)
    setError('')
    setInterestData({
      branchIDs: [],
      departmentIDs: [],
      specialistIDs: [],
    })
  }

  useEffect(() => {
    try {
      if (jsonData) {
        setReload(false)

        /* email is optional */
        if (csvColumnHeaders.length >= 3) setError('')

        /* the file has 4 columns */
        if (hasRequiredColumns(columnMapping) && columnMapping.size == 4)
          setLeadsData(
            jsonData.map((jd: any) => ({
              firstName: jd[columnMapping.get('first_name')!.toString().trim()],
              email: jd[columnMapping.get('email')!.toString().trim()],
              phone: jd[columnMapping.get('phone')!.toString().trim()],
              sourceId: sources.find(
                (source: any) =>
                  source.name.trim().toLowerCase() ===
                  jd[
                    columnMapping.get('source')!.toString().trim()
                  ].toLowerCase()
              )?.id,
              userId: user.id,
            }))
          )
        /* the file has 3 columns */ else if (
          hasRequiredColumns(columnMapping) &&
          columnMapping.size == 3
        )
          setLeadsData(
            jsonData.map((jd: any) => ({
              firstName: jd[columnMapping.get('first_name')!.toString().trim()],
              phone: jd[columnMapping.get('phone')!.toString().trim()],
              sourceId: sources.find(
                (source: any) =>
                  source.name.toLowerCase() ===
                  jd[
                    columnMapping.get('source')!.toString().trim()
                  ].toLowerCase()
              )?.id,
              userId: user.id,
            }))
          )
      }
    } catch (error) {}
  }, [columnMapping, jsonData])

  const [interestData, setInterestData] = useState<{
    branchIDs: string[]
    departmentIDs: string[]
    specialistIDs: string[]
  }>({
    branchIDs: [],
    departmentIDs: [],
    specialistIDs: [],
  })

  const hasRequiredColumns = (mapping: Map<string, string>) => {
    return (
      mapping.get('first_name') && mapping.get('phone') && mapping.get('source')
    )
  }

  function addBranch(id: any) {
    if (interestData.branchIDs.includes(id)) {
      const newBranches = interestData.branchIDs.filter(
        (branch: any) => branch !== id
      )
      setInterestData({ ...interestData, branchIDs: newBranches })
    } else {
      setInterestData({
        ...interestData,
        branchIDs: [...interestData.branchIDs, id],
      })
    }
  }

  function addDepartment(id: any) {
    if (interestData.departmentIDs.includes(id)) {
      const newDepartments = interestData.departmentIDs.filter(
        (department: any) => department !== id
      )
      setInterestData({ ...interestData, departmentIDs: newDepartments })
    } else {
      setInterestData({
        ...interestData,
        departmentIDs: [...interestData.departmentIDs, id],
      })
    }
  }

  function addSpecialist(id: any) {
    if (interestData.specialistIDs.includes(id)) {
      const newSpecialists = interestData.specialistIDs.filter(
        (specialist: any) => specialist !== id
      )
      setInterestData({ ...interestData, specialistIDs: newSpecialists })
    } else {
      setInterestData({
        ...interestData,
        specialistIDs: [...interestData.specialistIDs, id],
      })
    }
  }

  const upload = async () => {
    const notValidLeads = []
    setError('')

    for (let i = 0; i < leadsData.length; i++) {
      if (
        !leadsData[i].sourceId ||
        leadsData[i].sourceId === undefined ||
        leadsData[i].sourceId === null
      ) {
        notValidLeads.push(`(${fileType == 'csv' ? i + 1 : i + 2}, source)`)
        continue
        //return setError('Please review the csv file, invalid source')
      }

      if (
        leadsData[i].email &&
        leadsData[i].email != '' &&
        !validateEmail(leadsData[i].email)
      ) {
        notValidLeads.push(`(${fileType == 'csv' ? i + 1 : i + 2}, email)`)
        continue
        // return setError(
        //   'Please review the csv file, some emails are invalid!, OR please match a valid email column with the email field!'
        // )
      }
      if (leadsData[i].firstName.length < 1) {
        notValidLeads.push(`(${fileType == 'csv' ? i + 1 : i + 2}, first name)`)
        continue
        // return setError('some first names are missed!')
      }

      if (!cleanPhoneNumber(leadsData[i].phone)) {
        notValidLeads.push(`(${fileType == 'csv' ? i + 1 : i + 2}, phone)`)
        continue
        // return setError('some phone numbers are invalid!')
      }
    }

    if (notValidLeads.length > 0) {
      return setError(
        `invalid file, rows [${notValidLeads.join(', ')}] have invalid data`
      )
    }

    if (leadsData && leadsData.length > 0) {
      const body = leadsData.map(({ phone, ...item }) => {
        return {
          phone: cleanPhoneNumber(phone),
          whatsappNumber: cleanPhoneNumber(phone),
          branchIDs: interestData.branchIDs,
          departmentIDs: interestData.departmentIDs,
          specialistIDs: interestData.specialistIDs,
          ...item,
        }
      })
      const result: any = await addBulkLeads(body)

      if (result?.error?.originalStatus === 200) {
        toast({
          title: 'Leads are being created',
          description: 'This may take a while',
        })
        setOpen(false)
      } else {
        setError('server error, please try again')
      }
    }
  }

  useEffect(() => {
    setJsonData(null)
    setLeadsData([])
    setColumnMapping(new Map())
    setCsvColumnHeaders([])
    setInterestData({
      branchIDs: [],
      departmentIDs: [],
      specialistIDs: [],
    })
    setError('')
  }, [open])

  useEffect(() => {
    if (meta) {
      setNotConfigured(sources?.length < 1)
    }
  }, [meta])

  const downloadCSVExample = async () => {
    try {
      const hiddenElement = document.createElement('a')
      hiddenElement.href =
        'data:text/plain;charset=utf-8,' +
        encodeURIComponent(
          `first_name,phone,source,email
          John,+971123456789,Google Ads,john.doe@doctrona.com
          Jane,+971123456789,WhatsApp,`
        )
      hiddenElement.download = `leads-template.csv`
      hiddenElement.click()
    } catch (error: any) {
      toast({
        title: 'Error occurred',
      })
    }
  }

  const downloadXLSXExample = () => {
    try {
      const data = [
        {
          first_name: 'John',
          phone: '971123456789',
          source: 'Google Ads',
          email: 'john.doe@doctrona.com',
        },
        { first_name: 'Jane', phone: '971123456789', source: 'WhatsApp' },
      ]
      const worksheet = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
      saveAs(blob, `${'leads-template'}.xlsx`)
    } catch (error: any) {
      toast({
        title: 'Error occurred',
      })
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent
        className={`sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 ${jsonData && !reload && ' flex-1 h-full '} overflow-y-scroll`}
      >
        <div className="flex w-full justify-end">
          <Button variant="ghost" size="icon" onClick={() => setOpen(false)}>
            <TbX size={18} />
          </Button>
        </div>
        <div>
          {!isLoading && notConfigured && (
            <div className="p-3">
              <div className="grid h-96 w-full place-content-center">
                <div className="text-center">
                  <h1 className="text-lg font-semibold">
                    Please configure your sources, branches, and specialties
                    first
                  </h1>
                  <div className="mt-10">
                    <Link
                      className="bg-indigo-600 text-white py-2 px-5 rounded-md"
                      to="/settings"
                    >
                      Go to settings
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isLoading && !notConfigured && (
            <>
              <h1 className="font-semibold text-lg">Bulk Upload</h1>
              <p className="text-sm">
                Upload multiple leads, make sure to{' '}
                <i>
                  match the columns of the csv file to be similar to the
                  structure shown in the example below{' '}
                </i>
              </p>
              <p className="mt-5 text-sm max-md:hidden">Example CSV file</p>
              <div className="mt-2 text-xs p-3 border bg-gray-100 rounded-md max-md:hidden">
                <pre>
                  first_name,phone,source,email
                  <br />
                  John,+971123456789,Google Ads,john.doe@doctrona.com
                  <br />
                  Jane,+971123456789,WhatsApp,
                </pre>
              </div>

              {jsonData && !reload && (
                <>
                  <p className="my-3 text-md ">Headers Matching</p>
                  <HeadersMatcher
                    onColumnMatch={onColumnMatch}
                    columnNames={columnNames}
                    csvColumnHeaders={csvColumnHeaders}
                    columnMapping={columnMapping}
                  />

                  <div className="flex justify-end">
                    <LeadInterest
                      data={interestData}
                      setData={setInterestData}
                      addBranch={addBranch}
                      addDepartment={addDepartment}
                      addSpecialist={addSpecialist}
                      isDetailsPage={false}
                      isCreatePage={true}
                      branches={branches}
                      departments={departments}
                      specialists={specialists}
                      isInModal={true}
                    />
                  </div>
                </>
              )}
              <div className="mt-5 items-center flex relative">
                {/* <Label>CSV File</Label> */}
                <Input
                  id="leads"
                  type="file"
                  ref={fileRef}
                  accept=".csv, .xlsx"
                  className="cursor-pointer"
                  onChange={handleCSVInputChange}
                />
                <IoMdClose
                  size={20}
                  className="text-gray-600 hover:cursor-pointer hover:text-gray-900 absolute right-2"
                  onClick={replaceFileHandler}
                />
              </div>
              <div className="w-full items-center text-xs font-bold  text-rose-600 mt-2  max-h-24 overflow-y-scroll">
                {error}
              </div>
              <div className="mt-5 flex gap-1 justify-end">
                <Button
                  onClick={downloadCSVExample}
                  size="sm"
                  className="flex items-center justify-center gap-1 p-2"
                >
                  <LuFileSpreadsheet />
                  <span>csv</span>
                </Button>
                <Button
                  onClick={downloadXLSXExample}
                  size="sm"
                  className="flex items-center justify-center gap-1 p-2"
                >
                  <LuFileSpreadsheet />

                  <span>xlsx</span>
                </Button>

                <Button
                  onClick={upload}
                  disabled={
                    jsonData === null ||
                    isLoading ||
                    !hasRequiredColumns(columnMapping)
                  }
                  size="sm"
                >
                  Upload
                </Button>
              </div>
            </>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
