import { useState } from 'react'
import Header, { availableMetaSections, SocialTabs } from './header'
import Messages from './messages'
import Sidebar from './sidebar/sidebar'
import { useEffect } from 'react'
import { IOEvents } from 'lib/socket'
import { useLocation, useParams } from 'react-router-dom'
import { ContactSection } from './contact/contact-section'
import { Button } from 'components/ui/button'
import { useGetProfileQuery } from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import MobileSidebar from './sidebar/mobile-sidebar'
import { Actions } from './components/actions'
import SocialSidebar from './sidebar'
import useSocket from 'hooks/useSocket'

export default function Social() {
  const location = useLocation()
  const params = useParams()
  const { socket } = useSocket()
  const [activeTab, setActiveTab] = useState(SocialTabs.WhatsApp)
  const [selectedAccountId, setSelectedAccountId] = useState<any>(
    params?.accountId
  )
  const [selectedConversation, setSelectedConversation] = useState<any>(null)
  const [conversationLeads, setConversationLeads] = useState<any>([])
  const [selectedLead, setSelectedLead] = useState<any>(null)

  const locationState = location?.state

  const [refreshContacts, setRefreshContacts] = useState(false)

  // TODO: add accountId
  const {
    data: profile,
    isLoading: isLoadingProfile,
    isError: isProfileError,
  } = useGetProfileQuery(selectedAccountId)

  useEffect(() => {
    setSelectedAccountId(params?.accountId)
  }, [params?.accountId])

  useEffect(() => {
    setSelectedConversation(null)
  }, [selectedAccountId])

  useEffect(() => {
    if (!locationState) setSelectedConversation(null)
  }, [activeTab])

  useEffect(() => {
    if (socket) {
      const handleNewMetaMessage = (data: any) => {
        if (data?.savedMessage.selectedUser === selectedConversation?.id) {
          setSelectedConversation(selectedConversation)
        }
      }
      const handleNewWhatsAppMessage = (data: any) => {
        if (data?.selectedUser === selectedConversation?.id) {
          setSelectedConversation(selectedConversation)
        }
      }
      socket.on(IOEvents.NEW_META_MESSAGE, handleNewMetaMessage)
      socket.on(IOEvents.NEW_WHATSAPP_MESSAGE, handleNewWhatsAppMessage)
      return () => {
        socket.off(IOEvents.NEW_META_MESSAGE, handleNewMetaMessage)
        socket.off(IOEvents.NEW_WHATSAPP_MESSAGE, handleNewWhatsAppMessage)
      }
    }
  }, [socket, selectedConversation])

  const initialLead = selectedLead
    ? selectedLead
    : {
        firstName: selectedConversation?.name?.split(' ')[0],
        lastName: selectedConversation?.name?.split(' ')[1],
      }

  const ExpiredTokenAlert = () => (
    <div className="dialog-box-sm">
      <div className="col-4">
        To start using WhatsApp Business, log in with your Meta account
        <div className="flex justify-end mt-10">
          <Button
            variant={'outline'}
            size={'sm'}
            className="destructive"
            onClick={() => {
              window.location.href = '/settings?screen=social'
            }}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  )

  if (isLoadingProfile)
    return (
      <div className="w-screen h-screen grid place-content-center">
        <Loader />
      </div>
    )
  return (
    <>
      <div>
        <div className="relative p-3 w-full h-screen max-md:h-[80%] max-md:p-0 max-md:pb-1 overflow-hidden">
          <div className="flex flex-col w-full h-full overflow-hidden md:p-0">
            <div className="flex flex-1 gap-2 px-1 items-start ">
              <SocialSidebar
                activeTab={activeTab}
                selectedAccountId={selectedAccountId}
                selectedConversation={selectedConversation}
                setSelectedConversation={setSelectedConversation}
                selectedLead={selectedLead}
                setSelectedLead={setSelectedLead}
                conversationLeads={conversationLeads}
                setConversationLeads={setConversationLeads}
                refreshContacts={refreshContacts}
              />
              <Header
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                selectedAccountId={selectedAccountId}
              />
            </div>
            {(!profile || isProfileError) &&
              activeTab == SocialTabs.WhatsApp && <ExpiredTokenAlert />}
            <div
              className={`flex mt-3 w-full h-full overflow-hidden gap-3 ${
                (!profile || isProfileError) && activeTab == SocialTabs.WhatsApp
                  ? 'dialog-overlay'
                  : ''
              }`}
            >
              <div className="flex-1 flex gap-2 px-1">
                {availableMetaSections.includes(activeTab) ? (
                  <>
                    <div className="hidden sm:block">
                      <Sidebar
                        activeTab={activeTab}
                        selectedAccountId={selectedAccountId}
                        selectedConversation={selectedConversation}
                        setSelectedConversation={setSelectedConversation}
                        selectedLead={selectedLead}
                        setSelectedLead={setSelectedLead}
                        conversationLeads={conversationLeads}
                        setConversationLeads={setConversationLeads}
                        refreshContacts={refreshContacts}
                        mobileView={false}
                      />
                    </div>
                    {!selectedConversation && (
                      <div className="relative overflow-hidden w-full border rounded-xl min-h-[calc(100vh-8rem)] max-md:max-h-[calc(100vh-8rem)] ">
                        <div className="p-20 grid w-full h-full place-content-center">
                          Start by selecting a user
                        </div>
                      </div>
                    )}
                    {selectedConversation && (
                      <Messages
                        selectedAccountId={selectedAccountId}
                        selectedConversationId={selectedConversation.id}
                        selectedLead={selectedLead}
                        setSelectedLead={setSelectedLead}
                        conversationLeads={conversationLeads}
                        setConversationLeads={setConversationLeads}
                        lastReply={selectedConversation.lastReply}
                        initialLead={initialLead}
                        isDetailsPage={!selectedLead}
                        metaConversationId={selectedConversation.id}
                        activeTab={activeTab}
                        setRefreshContacts={setRefreshContacts}
                      />
                    )}
                  </>
                ) : (
                  <div className="h-full w-full flex justify-center items-center">
                    <h1>Coming Soon</h1>
                  </div>
                )}
              </div>
              {selectedConversation && (
                <div className="hidden xl:block overflow-auto w-[300px] ">
                  <ContactSection
                    conversationLeads={conversationLeads}
                    setSelectedLead={setSelectedLead}
                    selectedLead={selectedLead}
                    initialLead={initialLead}
                    isDetailsPage={!selectedLead}
                    metaConversationId={selectedConversation?.id}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
