import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { Button } from 'components/ui/button'
import { TbWindowMaximize, TbX } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import dayjs from 'lib/dayjs'
import { useGetCustomerByIdQuery } from 'app/features/customers'
import { LeadHistory } from 'pages/lead/components/lead-history'
import { sentenceCase } from 'lib/utils'
import { Label, Pie, PieChart } from 'recharts'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from 'components/ui/chart'
import { useEffect, useState } from 'react'
import randomcolor from 'randomcolor'
import CountUp from 'react-countup'

const statusChartConfig: {
  [status: string]: {
    label: string
    color: string
  }
} = {
  NOT_QUALIFIED: {
    label: 'Not Qualified',
    color: '#E2366F',
  },
  NEW: {
    label: 'New',
    color: '#AF56DB',
  },
  SHOW: {
    label: 'Show',
    color: '#2DB78A',
  },
  NO_SHOW: {
    label: 'No Show',
    color: '#E78D2F',
  },
  FOLLOW_UP: {
    label: 'Follow Up',
    color: '#E9C368',
  },
  BOOKED: {
    label: 'Booked',
    color: '#2761D8',
  },
  IN_INSURANCE: {
    label: 'In Insurance',
    color: '#AF56DB',
  },
  END_INSURANCE: {
    label: 'End Insurance',
    color: '#2DB78A',
  },
  CANCELED_BOOKING: {
    label: 'Canceled Booking',
    color: '#E2366F',
  },
} satisfies ChartConfig

const chartConfig = {} satisfies ChartConfig

/**
 * A component to view an customer's details
 * @param props.id - the customer id to view
 * @param props.closeDialog - a function to close the dialog
 */
export default function ViewCustomer(props: {
  id: string
  closeDialog: () => void
}) {
  const { id } = props

  const { data, isLoading, isError } = useGetCustomerByIdQuery(id)

  const [statusChartData, setStatusChartData] = useState<
    {
      name: string
      value: number
      fill: string
    }[]
  >()

  const [sourceChartData, setSourceChartData] = useState<
    {
      name: string
      value: number
      fill: string
    }[]
  >()

  const [branchChartData, setBranchChartData] = useState<
    {
      name: string
      value: number
      fill: string
    }[]
  >()

  const [departmentsChartData, setDepartmentsChartData] = useState<
    {
      name: string
      value: number
      fill: string
    }[]
  >()

  const [specialistChartData, setSpecialistChartData] = useState<
    {
      name: string
      value: number
      fill: string
    }[]
  >()

  useEffect(() => {
    if (data) {
      const statusCounts = data.statusCounts
      const sourceCounts = data.sourceCounts

      const statusArray = Object.keys(statusCounts).map((status) => ({
        name: status,
        value: statusCounts[status],
        fill: statusChartConfig[status]?.color,
      }))

      const sourceArray = Object.keys(sourceCounts).map((source) => ({
        name: source,
        value: sourceCounts[source],
        fill: randomcolor(),
      }))

      const branchArray = Object.keys(data.branchesCounts).map((branch) => ({
        name: branch,
        value: data.branchesCounts[branch],
        fill: randomcolor(),
      }))

      const departmentsArray = Object.keys(data.departmentsCounts).map(
        (department) => ({
          name: department,
          value: data.departmentsCounts[department],
          fill: randomcolor(),
        })
      )

      const specialistArray = Object.keys(data.specialistsCounts).map(
        (specialist) => ({
          name: specialist,
          value: data.specialistsCounts[specialist],
          fill: randomcolor(),
        })
      )

      setStatusChartData(statusArray)
      setSourceChartData(sourceArray)
      setBranchChartData(branchArray)
      setDepartmentsChartData(departmentsArray)
      setSpecialistChartData(specialistArray)
    }
  }, [data])

  /**
   * A label component for charts
   * @returns a label component that can be used to display text in the center of a chart
   * @example
   * <ChartLabel />
   */
  const ChartLabel = () => (
    <Label
      content={({ viewBox }) => {
        if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
          return (
            <text
              x={viewBox.cx}
              y={viewBox.cy}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              <tspan
                x={viewBox.cx}
                y={viewBox.cy}
                className="fill-foreground text-3xl font-bold"
              ></tspan>
              <tspan
                x={viewBox.cx}
                y={(viewBox.cy || 0) + 24}
                className="fill-muted-foreground"
              ></tspan>
            </text>
          )
        }
      }}
    />
  )

  return (
    <div>
      <div className="flex justify-between mb-6">
        <div>
          <h1 className="text-lg font-medium">View Leads of Customer</h1>
        </div>
        <div>
          <Button variant="outline" onClick={props.closeDialog}>
            <TbX />
          </Button>
        </div>
      </div>

      <div className="text-sm flex flex-col gap-1 mb-4">
        Name: {data?.firstName || ''} {data?.lastName || ''}
      </div>

      <Tabs defaultValue="leads" className="w-full">
        <TabsList className=" w-full bg-white px-1 h-10 border ">
          <TabsTrigger
            className="w-full font-semi  text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
            value="leads"
          >
            Leads
          </TabsTrigger>
          <TabsTrigger
            className="w-full font-semi  text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
            value="history"
          >
            History
          </TabsTrigger>
        </TabsList>

        <TabsContent value="leads">
          <div className="flex justify-center mb-8">
            <div style={{ height: '150px', width: '150px' }}>
              <ChartContainer
                config={statusChartConfig}
                className="mx-auto aspect-square max-h-[130px]"
              >
                <PieChart>
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                  />
                  <Pie
                    data={statusChartData}
                    dataKey="value"
                    nameKey="name"
                    innerRadius={30}
                    strokeWidth={5}
                  >
                    <ChartLabel />
                  </Pie>
                </PieChart>
              </ChartContainer>
              <div className="text-center text-sm my-1">
                Leads distribution by status
              </div>
            </div>
            <div style={{ height: '150px', width: '150px' }}>
              <ChartContainer
                config={statusChartConfig}
                className="mx-auto aspect-square max-h-[130px]"
              >
                <PieChart>
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                  />
                  <Pie
                    data={sourceChartData}
                    dataKey="value"
                    nameKey="name"
                    innerRadius={30}
                    strokeWidth={5}
                  >
                    <ChartLabel />
                  </Pie>
                </PieChart>
              </ChartContainer>
              <div className="text-center text-sm my-1">
                Leads distribution by source
              </div>
            </div>
            <div style={{ height: '150px', width: '150px' }}>
              <ChartContainer
                config={chartConfig}
                className="mx-auto aspect-square max-h-[130px]"
              >
                <PieChart>
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                  />
                  <Pie
                    data={branchChartData}
                    dataKey="value"
                    nameKey="name"
                    innerRadius={30}
                    strokeWidth={5}
                  >
                    <ChartLabel />
                  </Pie>
                </PieChart>
              </ChartContainer>
              <div className="text-center text-sm my-1">
                Leads distribution by branch
              </div>
            </div>
            <div style={{ height: '150px', width: '150px' }}>
              <ChartContainer
                config={chartConfig}
                className="mx-auto aspect-square max-h-[130px]"
              >
                <PieChart>
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                  />
                  <Pie
                    data={departmentsChartData}
                    dataKey="value"
                    nameKey="name"
                    innerRadius={30}
                    strokeWidth={5}
                  >
                    <ChartLabel />
                  </Pie>
                </PieChart>
              </ChartContainer>
              <div className="text-center text-sm my-1">
                Leads distribution by department
              </div>
            </div>
            <div style={{ height: '150px', width: '150px' }}>
              <ChartContainer
                config={chartConfig}
                className="mx-auto aspect-square max-h-[130px]"
              >
                <PieChart>
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                  />
                  <Pie
                    data={specialistChartData}
                    dataKey="value"
                    nameKey="name"
                    innerRadius={30}
                    strokeWidth={5}
                  >
                    <ChartLabel />
                  </Pie>
                </PieChart>
              </ChartContainer>
              <div className="text-center text-sm my-1">
                Leads distribution by specialist
              </div>
            </div>
            <div className="flex flex-col w-[150px] h-[150px]">
              <div className="flex justify-center items-center w-[130px] h-[130px]">
                <CountUp end={data?.bookingsAmount || 0} prefix="AED " />
              </div>
              <div className="text-center text-sm mt-4">Bookings Amount</div>
            </div>
          </div>

          <div className="max-h-[250px] border rounded-md bg-white overflow-x-scroll ">
            <table className="w-full text-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Alternative Phone</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.leads?.map((lead, index) => (
                  <tr key={index} className="border-t">
                    <td className="text-center">{`${lead?.firstName || ''} ${lead.lastName || ''}`}</td>
                    <td className="text-center">{lead.phone}</td>
                    <td className="text-center">{lead.alternativePhone}</td>
                    <td className="text-center">{lead.email}</td>
                    <td className="text-center">{sentenceCase(lead.status)}</td>
                    <td className="text-center">
                      {dayjs(lead.createdAt).format('DD MMM HH:mm')}
                    </td>
                    <td className="text-center">
                      <Link to={`/lead-details/${lead.id}`}>
                        <TbWindowMaximize />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </TabsContent>
        <TabsContent value="history">
          <div className="max-h-[400px] border overflow-x-scroll">
            <LeadHistory
              isLoading={isLoading}
              loadingLead={isLoading}
              loading={isLoading}
              lead={{ ...data, leadActions: data?.history }}
            />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}
