import { useEffect, useState } from 'react'
import { TbChevronLeft } from 'react-icons/tb'
import { Button } from 'components/ui/button'
import { useNavigate } from 'react-router-dom'
import { ResponseReport } from './components/response-report'
import { ConversationsStatistics } from './components/conversations-statistics'
import { WhatsappAnalytics } from './components/whatsapp-analytics'
import { TemplatesAnalytics } from './components/templates-analytics'
import { DateFilters } from './components/date-filters'
import { CampaignStatistics } from './components/campaign-statistics'
import { useParams } from 'react-router-dom'
import dayjs from 'lib/dayjs'

export type WhatsappStatsProps = {
  startDate: string | undefined
  endDate: string | undefined
  whatsappAccount: string
  setLoading?: any
}

export default function WhatsappDashboard() {
  const navigate = useNavigate()
  const params = useParams()

  const [loadingConversationsStatistics, setLoadingConversationsStatistics] =
    useState(false)
  const [loadingWhatsappAnalytics, setLoadingWhatsappAnalytics] =
    useState(false)
  const [loadingTemplatesAnalytics, setLoadingTemplatesAnalytics] =
    useState(false)
  const [loadingCampaignStatistics, setLoadingCampaignStatistics] =
    useState(false)
  const [loadingResponseReport, setLoadingResponseReport] = useState(false)

  const whatsappAccount = params.accountId || ''

  /* Filters */
  const [startDate, setStartDate] = useState<string | undefined>(
    dayjs().subtract(30, 'day').toISOString()
  )
  const [endDate, setEndDate] = useState<string | undefined>(
    dayjs().toISOString()
  )

  return (
    <>
      <div className="p-6">
        <div className="flex justify-between">
          <div>
            <Button variant={'outline'} onClick={() => navigate(-1)}>
              <TbChevronLeft />
            </Button>
          </div>
          <DateFilters
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            disabled={
              loadingConversationsStatistics ||
              loadingWhatsappAnalytics ||
              loadingTemplatesAnalytics ||
              loadingCampaignStatistics ||
              loadingResponseReport
            }
          />
        </div>
        <ConversationsStatistics
          setLoading={setLoadingConversationsStatistics}
          startDate={startDate}
          endDate={endDate}
          whatsappAccount={whatsappAccount}
        />

        <WhatsappAnalytics
          setLoading={setLoadingWhatsappAnalytics}
          startDate={startDate}
          endDate={endDate}
          whatsappAccount={whatsappAccount}
        />

        <TemplatesAnalytics
          setLoading={setLoadingTemplatesAnalytics}
          startDate={startDate}
          endDate={endDate}
          whatsappAccount={whatsappAccount}
        />

        <CampaignStatistics
          setLoading={setLoadingCampaignStatistics}
          startDate={startDate}
          endDate={endDate}
          whatsappAccount={whatsappAccount}
        />

        <ResponseReport
          setLoading={setLoadingResponseReport}
          startDate={startDate}
          endDate={endDate}
          whatsappAccount={whatsappAccount}
        />
      </div>
    </>
  )
}
