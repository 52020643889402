import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useAddConversationTagMutation,
  useGetConversationTagsQuery,
  useUpdateAssignedConversationTagsMutation,
} from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { toast } from 'components/ui/use-toast'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'
import { IoAddCircleOutline, IoPricetagSharp } from 'react-icons/io5'

export const WhatsappTags = ({
  open,
  setOpen,
  conversationId,
  tags,
  selectedAccountId,
}: any) => {
  const [addError, setAddError] = useState('')
  const [assignError, setAssignError] = useState('')
  const [name, setName] = useState('')
  const [selectedTags, setSelectedTags] = useState<any>([])

  const {
    data: conversationTags,
    isLoading: dataLoading,
    isError: dataError,
  } = useGetConversationTagsQuery(selectedAccountId)
  const [addConversationTag, { isLoading: createLoading }] =
    useAddConversationTagMutation()

  const [updateAssignedTags, { isLoading: assignLoading }] =
    useUpdateAssignedConversationTagsMutation()

  useEffect(() => {
    setSelectedTags(tags.map((tag: any) => tag.id))
  }, [tags])

  const handleSelectTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      const newTags = selectedTags.filter((item: any) => item !== tag)
      setSelectedTags(newTags)
    } else {
      setSelectedTags([...selectedTags, tag])
    }
  }

  const handleAddWhatsappTag = async (e: any) => {
    e.preventDefault()
    setAddError('')
    if (!name || name?.trim() === '') return setAddError('Tag is required')
    try {
      const result: any = await addConversationTag({
        tag: { name },
        accountId: selectedAccountId,
      })
      if (result?.error) return setAddError('Failed to add tag')
      setName('')
      setAddError('')
    } catch (err) {
      setAddError('Failed to add tag')
    }
  }

  const handleUpdateAssigneeTags = async (e: any) => {
    e.preventDefault()
    try {
      const result: any = await updateAssignedTags({
        conversationId,
        tags: selectedTags,
      })
      if (result?.error) return setAssignError('Failed to assign tags')
      setAssignError('')
      toast({
        title: 'Updated successfully',
        description: 'Conversation tags updated successfully',
      })
      setOpen(false)
    } catch (err) {
      setAssignError('Failed to assign tag')
    }
  }
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      {/* <AlertDialogTrigger className="disabled:text-gray-300 hover:cursor-pointer      p-0    disabled={!isDetailsPage}">
        <Button
          variant="ghost"
          className="flex h-8 w-8   p-0 data-[state=open]:bg-muted relative"
        >
          <IoPricetagSharp size={18} />
        </Button>
      </AlertDialogTrigger> */}
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <>
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  {' '}
                  Manage Tags
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="space-y-3 my-5">
                <div className="w-full space-y-2 border-b pb-6">
                  <Label>Assigned Tags</Label>
                  {dataError && (
                    <div className="h-[200px] grid place-content-center">
                      <Error message="Server error, contact administrator please" />
                    </div>
                  )}
                  {dataLoading && (
                    <div className="h-[200px] grid place-content-center">
                      <Loader />
                    </div>
                  )}
                  {!dataLoading && !dataError && (
                    <>
                      {conversationTags?.length > 0 ? (
                        <>
                          <div className="flex gap-3 flex-wrap pb-3">
                            {conversationTags?.map((tag: any) => {
                              return (
                                <div
                                  key={tag}
                                  onClick={() => {
                                    handleSelectTag(tag.id)
                                  }}
                                  className={cn(
                                    selectedTags.includes(tag.id)
                                      ? 'border-indigo-600'
                                      : 'border',
                                    'px-2 py-[2px]  rounded-md cursor-pointer border-2  select-none   gap-2 max-w-[10rem] whitespace-nowrap text-ellipsis overflow-hidden'
                                  )}
                                >
                                  {tag.name}
                                </div>
                              )
                            })}
                          </div>
                          <div className="flex items-center gap-3 justify-between pt-5">
                            <div className="flex-1"></div>
                            <Button
                              size="sm"
                              onClick={handleUpdateAssigneeTags}
                              disabled={assignLoading}
                            >
                              Update
                            </Button>
                          </div>
                          {assignError !== '' && (
                            <div className="text-rose-600 text-sm">
                              {assignError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="h-[200px] grid place-content-center">
                          Please add some tags
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="flex flex-col gap-2 p-1 py-4   w-1/2 max-md:w-full">
                  <Label>Add New Tag</Label>

                  <div className="flex items-center gap-3 ">
                    <Input
                      name="name"
                      value={name}
                      placeholder={'Tag'}
                      onChange={(e: any) => setName(e.target.value)}
                      type="text"
                      required
                      disabled={createLoading}
                    />

                    <Button
                      onClick={handleAddWhatsappTag}
                      disabled={createLoading}
                      variant={'outline'}
                      className="text-xs w-20 flex items-center gap-1 justify-center"
                    >
                      Add
                      <IoAddCircleOutline size={16} />
                    </Button>
                  </div>
                  {addError !== '' && (
                    <div className="text-rose-600 text-sm">{addError}</div>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
