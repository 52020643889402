import { useGetTemplatesAnalyticsQuery } from 'app/features/whatsappStats'
import { toast } from 'components/ui/use-toast'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { WhatsappStatsProps } from '../index'
import { SelectTemplate } from '../../social/components/actions/select-template'
import Loader from 'components/shared/loader'
import { TbInfoSquareRounded } from 'react-icons/tb'

export const TemplatesAnalytics = ({
  startDate,
  endDate,
  whatsappAccount,
  setLoading,
}: WhatsappStatsProps) => {
  const [template, setTemplate] = useState<any>(null)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  /**
   * Given a start and end date, return a limited date range with
   * the following rules:
   *
   * - If no start or end date is given, return the last 90 days
   * - If the time difference between the start and end date is greater than 90 days,
   *   return the last 90 days
   * - Otherwise, return the given start and end date
   *
   * @param {string} startDate - The start date
   * @param {string} endDate - The end date
   * @returns The limited date range object with `from` and `to` properties
   */
  const getLimitedDateRange = useMemo(() => {
    if (!startDate || !endDate) {
      const today = dayjs()
      const from = dayjs().subtract(60, 'day')
      return {
        from: from.toISOString(),
        to: today.toISOString(),
      }
    }

    const start = dayjs(startDate)
    const end = dayjs(endDate)

    if (
      end.isAfter(dayjs()) ||
      end.diff(start, 'days') > 60 ||
      start.isBefore(dayjs().subtract(60, 'days'))
    ) {
      const now = dayjs()
      const from = dayjs().subtract(60, 'day')
      return {
        from: from.toISOString(),
        to: now.toISOString(),
      }
    }

    return {
      from: start.toISOString(),
      to: end.toISOString(),
    }
  }, [startDate, endDate])

  const { refetch, isLoading, isFetching, data, isError } =
    useGetTemplatesAnalyticsQuery(
      {
        whatsappAccount,
        from: getLimitedDateRange.from,
        to: getLimitedDateRange.to,
        template: template?.id,
      },
      {
        skip: !template,
      }
    )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching analytics',
      })
    }
  }, [isError])

  const formatXAxisAnalytics = (tick: any) => {
    return dayjs(tick).format('DD')
  }

  /**
   * Custom tooltip for chart.
   *
   * @param {Object} param
   * @param {boolean} param.active - Whether the tooltip is active.
   * @param {Array} param.payload - Data payload for the tooltip.
   * @param {string} param.label - Label for the tooltip.
   *
   * @returns {ReactNode} - JSX for the tooltip.
   */
  const customTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="card bg-white dark:bg-gray-900 p-2 shadow">
          <p>
            {dayjs(label).format('YYYY/MM/DD')}
            &nbsp;to&nbsp;
            {dayjs(label).add(1, 'D').format('YYYY/MM/DD')}
          </p>
          <div className="flex">
            <div className="mr-2" style={{ color: '#34C759' }}>
              Sent: {payload[0].value}
            </div>
            <div className="mr-2" style={{ color: '#F7DC6F' }}>
              Delivered: {payload[1].value}
            </div>
            <div className="" style={{ color: '#03A9F4' }}>
              Read: {payload[2].value}
            </div>
          </div>
          <div>
            {payload[0]?.payload?.cost?.map(
              (item: { type: string; value: number }, index: number) => (
                <div key={index}>
                  <span>
                    {item.type}: {item.value || 0}
                  </span>
                </div>
              )
            )}
          </div>
          <div>
            {payload[0]?.payload?.clicked?.map(
              (item: { type: string; value: number }, index: number) => (
                <div key={index}>
                  <span>
                    {item.type}: {item.value || 0}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      )
    }

    return null
  }

  useEffect(() => {
    setLoading(isLoading || isFetching)
  }, [isLoading, isFetching])

  return (
    <div className="mb-6">
      <h1 className="text-2xl font-bold mt-10 mb-4">Template Analytics </h1>
      <div className="text-sm mb-2">
        This chart visualizes number of times a template has been sent,
        delivered, and read, and the number of times URL buttons or Quick Reply
        buttons in the template have been clicked the date range selected by
        user. Note that the start timestamp for the date range is corrected to
        the prior 0:00 UTC, as template analytics are provided with a daily
        granularity in the UTC timezone.
      </div>

      <Accordion type="single" collapsible className="mb-4">
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex items-center">
              <TbInfoSquareRounded
                strokeWidth={1.5}
                size={20}
                className="mr-2"
              />
              What are the metrics displayed?
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="text-sm mb-2">
              <ul className="list-disc ml-8">
                <div className="mb-2">
                  <div>Cost Metrics</div>
                </div>
                <li className="mb-2">
                  <span style={{ color: '#42b72a' }}>Amount Spent</span> - Total
                  amount spent on conversations opened within the start and end
                  timeframe as a result of sending the template.
                </li>
                <li className="mb-2">
                  <span style={{ color: '#42b72a' }}>Cost per Delivered</span> -
                  The amount spent value divided by the number of times the
                  template was delivered within the start and end timeframe.
                </li>
                <li className="mb-2">
                  <span style={{ color: '#42b72a' }}>
                    Cost per URL Button Click
                  </span>{' '}
                  - The amount spent value divided by the number of times the
                  template's URL button was clicked, within the start and end
                  timeframe. Quick reply button clicks are not included.
                </li>
                <div className="my-2">
                  <div>Click Metrics</div>
                </div>
                <li className="mb-2">
                  <span style={{ color: '#42b72a' }}>URL button</span> - The
                  total number of clicks on the url button.
                </li>
                <li className="mb-2">
                  <span style={{ color: '#42b72a' }}>Quick reply button</span> -
                  The total number of clicks on the quick reply button.
                </li>
                <li className="mb-2">
                  <span style={{ color: '#42b72a' }}>Unique URL button</span> -
                  Unique clicks track the number of distinct WhatsApp accounts
                  that have clicked on a button. This metric helps you
                  understand how many individual users are engaging with your
                  CTAs, while eliminating duplicate clicks from the same
                  recipient and providing an accurate measurement of engagement.
                </li>
              </ul>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <div className="flex justify-end">
        <SelectTemplate
          selectedAccountId={whatsappAccount}
          selectTemplate={(template: any) => {
            setTemplate(template)
          }}
          selectedTemplate={template}
          displayLabel={false}
        />
      </div>

      <div className="flex justify-between relative">
        <div className={isLoading || isFetching ? 'blur-md' : ''}>
          <BarChart
            width={1200}
            height={200}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="start" tickFormatter={formatXAxisAnalytics} />
            <YAxis interval={1} />
            <Tooltip content={customTooltip} />
            <Legend />
            <Bar
              dataKey="sent"
              fill="#34C759"
              name="Number of times a template has been sent"
            />
            <Bar
              dataKey="delivered"
              fill="#F7DC6F"
              name="Number of times a template has been delivered"
            />
            <Bar
              dataKey="read"
              fill="#03A9F4"
              name="Messages number of times a template has been read"
            />
          </BarChart>
        </div>

        {(isLoading || isFetching) && (
          <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}
