import { cn } from 'lib/utils'
import { useGetUnreadConversationsCountQuery } from 'app/features/whatsapp'
import { useGetUnreadInstagramConversationsCountQuery } from 'app/features/meta'
import { useEffect, useMemo, useState } from 'react'

type HeaderButtonProps = {
  activeTab: string
  label: string
  unreadConversationsCount: number
  unreadInstagramConversationsCount: number
  onClick: () => void
}

export const enum SocialTabs {
  WhatsApp = 'WhatsApp',
  Messenger = 'Messenger',
  Instagram = 'Instagram',
}

export const availableMetaSections = ['WhatsApp', 'Instagram']

export default function Header({
  activeTab,
  setActiveTab,
  selectedAccountId,
}: any) {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    checkScreenWidth()
    window.addEventListener('resize', checkScreenWidth)
    return () => {
      window.removeEventListener('resize', checkScreenWidth)
    }
  }, [])

  const headers = useMemo(() => {
    if (isMobile) {
      return ['WhatsApp']
    }
    return [
      'WhatsApp',
      'Messenger',
      'Instagram',
      'Facebook Comments',
      'Instagram Comments',
    ]
  }, [isMobile])

  const unreadConversationsCount =
    useGetUnreadConversationsCountQuery(selectedAccountId)

  const unreadInstagramConversationsCount =
    useGetUnreadInstagramConversationsCountQuery()

  return (
    <div className="text-base w-full flex gap-3 items-center py-3 overflow-x-auto overflow-y-hidden">
      {headers.map((header: string) => (
        <HeaderButton
          key={header}
          activeTab={activeTab}
          label={header}
          unreadConversationsCount={unreadConversationsCount?.data!}
          unreadInstagramConversationsCount={
            unreadInstagramConversationsCount?.data!
          }
          onClick={() => setActiveTab(header)}
        />
      ))}
    </div>
  )
}

const HeaderButton = ({
  activeTab,
  label,
  unreadConversationsCount,
  unreadInstagramConversationsCount,
  onClick,
}: HeaderButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        activeTab === label ? 'bg-gray-200' : 'bg-transparent',
        'ml-3 md:ml-0 px-3 py-1 rounded-md flex items-center gap-3 cursor-pointer hover:bg-gray-100 duration-300'
      )}
    >
      <span className="whitespace-nowrap">{label}</span>
      {label === 'WhatsApp' && unreadConversationsCount > 0 && (
        <div className="text-[11px] w-4 h-4 rounded-full bg-rose-600 text-white grid place-content-center">
          {unreadConversationsCount < 99 ? unreadConversationsCount : '99+'}
        </div>
      )}
      {label === 'Instagram' && unreadInstagramConversationsCount > 0 && (
        <div className="text-[11px] w-4 h-4 rounded-full bg-rose-600 text-white grid place-content-center">
          {unreadInstagramConversationsCount < 99
            ? unreadInstagramConversationsCount
            : '99+'}
        </div>
      )}
    </div>
  )
}
