import { api } from '../api'
import { removeCredentials, setCredentials } from '../slices/user'

type AuthDeviceRequestsFilters = {
  take: number
  skip: number
  isApproved?: boolean
  userId?: string
}

type ApproveAuthDeviceRequest = {
  requestId: string
  logoutFromOtherDevices?: boolean
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials: any) => ({
        url: '/auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    verify: builder.mutation({
      query: (credentials: any) => ({
        url: '/auth/verify',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          setTimeout(() => {
            dispatch(api.util.resetApiState())
            dispatch(removeCredentials())
          }, 1000)
        } catch (err) {
          console.log(err)
        }
      },
    }),
    refresh: builder.mutation<any, void>({
      query: () => ({
        url: '/auth/refresh',
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { accessToken, employee } = data
          // dispatch(setCredentials({ accessToken, employee }))
        } catch (err) {
          console.log(err)
        }
      },
    }),
    getAuthDeviceRequests: builder.query<any, any>({
      query: (filters: AuthDeviceRequestsFilters) =>
        `/auth/auth-devices-requests?filters=${JSON.stringify(filters)}`,
      providesTags: ['AuthDevices'],
    }),
    getPendingAuthDeviceRequestsCount: builder.query<any, any>({
      query: (filters: AuthDeviceRequestsFilters) =>
        `/auth/auth-devices-requests-pending-count?filters=${JSON.stringify(filters)}`,
      providesTags: ['AuthDevices'],
    }),
    approveAuthDeviceRequest: builder.mutation({
      query: (data: ApproveAuthDeviceRequest) => ({
        url: `/auth/approve-auth-device-request/${data.requestId}`,
        method: 'PUT',
        body: { logoutFromOtherDevices: data.logoutFromOtherDevices },
      }),
      invalidatesTags: ['AuthDevices'],
    }),
    rejectAuthDeviceRequest: builder.mutation({
      query: (data: ApproveAuthDeviceRequest) => ({
        url: `/auth/reject-auth-device-request/${data.requestId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['AuthDevices'],
    }),
    deleteAuthDeviceRequest: builder.mutation({
      query: (data: ApproveAuthDeviceRequest) => ({
        url: `/auth/auth-device-request/${data.requestId}`,
        method: 'Delete',
      }),
      invalidatesTags: ['AuthDevices'],
    }),
  }),
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useVerifyMutation,
  useRefreshMutation,
  useGetAuthDeviceRequestsQuery,
  useLazyGetAuthDeviceRequestsQuery,
  useGetPendingAuthDeviceRequestsCountQuery,
  useApproveAuthDeviceRequestMutation,
  useRejectAuthDeviceRequestMutation,
  useDeleteAuthDeviceRequestMutation,
} = authApi
