import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import { Button } from 'components/ui/button'
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'
import { cn } from 'lib/utils'
import { useState } from 'react'

export const SelectAccount = ({
  accounts,
  setSelectedAccount,
  selectedAccount,
}: any) => {
  const [openSelect, setOpenSelect] = useState(false)

  return (
    <Popover open={openSelect} onOpenChange={setOpenSelect} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={openSelect}
          className=" w-full max-w-[10rem]  whitespace-normal flex justify-between text-left  items-center    border rounded  p-2 my-2   border-gray-300 shadow-sm dark:border-gray-800  dark:bg-gray-900"
        >
          {selectedAccount?.phoneNumber
            ? selectedAccount.phoneNumber
            : 'Select account'}{' '}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command className="w-[300px] overflow-y-auto ">
          <CommandInput placeholder={`Search Accounts`} className="h-9" />
          <CommandEmpty>List is empty</CommandEmpty>
          <CommandGroup>
            <ScrollArea className="h-52 w-full">
              {accounts?.map((item: any) => (
                <>
                  <CommandItem
                    id={item.id}
                    key={`${item.id}`}
                    value={item}
                    onSelect={(currentValue) => {
                      setSelectedAccount(item)
                      setOpenSelect(false)
                    }}
                    className="capitalize hover:bg-white"
                  >
                    <div className="flex flex-col w-full ">
                      <span className="flex justify-between items-center w-full">
                        {item.phoneNumber}
                        <CheckIcon
                          className={cn(
                            'ml-auto h-4 w-4',
                            selectedAccount?.id === item?.id
                              ? 'opacity-100'
                              : 'opacity-0'
                          )}
                        />
                      </span>
                    </div>
                  </CommandItem>
                </>
              ))}
            </ScrollArea>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
