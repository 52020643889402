import {
  useLazyGetContactByPhoneNumberQuery,
  useLazyGetContactsQuery,
} from 'app/features/whatsapp'
import List from './list'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import InitConversation from '../components/init-conversation'
import Loader from 'components/shared/loader'
import { IOEvents, SocketClient } from 'lib/socket'
import { useSelector } from 'react-redux'
import useApp from 'hooks/useApp'
import useSocket from 'hooks/useSocket'

export default function WhatsApp({
  keyword,
  filters,
  selectedAccountId,
  selectedConversation,
  setSelectedConversation,
  setSelectedLead,
  setConversationLeads,
  refreshContacts,
}: any) {
  /* Optimized search */
  const [previousKeyword, setPreviousKeyword] = useState('')

  /* Infinite scroll related states */
  const [contacts, setContacts] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState(true)

  /* Refresh contacts states */
  const { refetchWhatsappContacts, setRefetchWhatsappContacts } = useApp()
  const { socket } = useSocket()
  const [readConversations, setReadConversations] = useState<string[]>([])

  /* Start messaging from lead page related states */
  const location = useLocation()
  const navigate = useNavigate()
  const locationState = location?.state
  const [openInitConversation, setOpenInitConversation] = useState(false)
  const [initLead, setInitLead] = useState<any>({
    id: locationState?.leadId,
    firstName: locationState?.leadFirstName,
    whatsappNumber: locationState?.leadWhatsappNumber,
  })

  /* RTK Query*/
  const [fetchContacts, { isFetching: isFetching }] = useLazyGetContactsQuery()
  const [
    fetchContactByPhoneNumber,
    { isFetching: isFetchingByPhoneNumberLazy },
  ] = useLazyGetContactByPhoneNumberQuery()

  /* Refresh Contacts */

  useEffect(() => {
    const newSentMessageHandler = async (data: any) => {
      refetchData()
    }
    const newMessageHandler = async (data: any) => {
      if (data?.socialAccountId === selectedAccountId) refetchData()
    }

    if (socket) {
      socket.on(IOEvents.NEW_SENT_WHATSAPP_MESSAGE, newSentMessageHandler)
      socket.on(IOEvents.NEW_WHATSAPP_MESSAGE, newMessageHandler)
    }
    return () => {
      if (socket) {
        socket.off(IOEvents.NEW_SENT_WHATSAPP_MESSAGE, newSentMessageHandler)
        socket.off(IOEvents.NEW_WHATSAPP_MESSAGE, newMessageHandler)
      }
    }
  }, [socket, selectedAccountId])

  // TODO: this is faster than socket, but not always return the most recent data
  // useEffect(() => {
  //   if (refetchWhatsappContacts) {
  //     refetchData()
  //     setRefetchWhatsappContacts(false)
  //   }
  // }, [refetchWhatsappContacts])

  /* Infinite scroll related functionalities */

  const fetchData = async () => {
    const newContacts: any = await fetchContacts({
      keyword,
      accountId: selectedAccountId,
      skip: Math.abs((currentPage! - 1) * 10),
      take: 10,
      ...(filters && filters),
    })
    if (newContacts.data) {
      setContacts((prev: any) => {
        return [...prev, ...newContacts.data]
      })
      setCurrentPage((prevPage) => prevPage! + 1)
    }
    if (!newContacts.data || newContacts.data.length == 0) setHasMore(false)
  }

  const refetchData = async () => {
    const newContacts: any = await fetchContacts({
      keyword,
      accountId: selectedAccountId,
      skip: 0,
      take: 10,
      ...(filters && filters),
    })
    if (newContacts.data) {
      setContacts((prev: any) => {
        return [...newContacts.data]
      })
      setReadConversations([])
      setCurrentPage(2)
    }
    if (!newContacts.data || newContacts.data.length == 0) setHasMore(false)
    else setHasMore(true)
  }

  useEffect(() => {
    if (selectedAccountId) {
      setContacts([])
      setReadConversations([])
      setCurrentPage(1)
      setHasMore(true)
    }
  }, [selectedAccountId])

  useEffect(() => {
    refetchData()
  }, [filters, refreshContacts])

  useEffect(() => {
    if (keyword?.length >= 2) refetchData()
    if (keyword?.length < 2 && previousKeyword?.length > keyword?.length)
      refetchData()
    setPreviousKeyword(keyword)
  }, [keyword])

  /* Start messaging from lead page related functionality */

  useEffect(() => {
    const checkIfNavigatedFromLead = async () => {
      if (locationState) {
        const accountId = locationState?.accountId
        const contact = await fetchContactByPhoneNumber({
          phoneNumber: locationState?.leadWhatsappNumber,
          accountId,
        })
        const leadContact = contact?.data
        if (leadContact) {
          setSelectedConversation(leadContact)
          setSelectedLead(
            leadContact?.leads?.find(
              (item: any) => item.id == locationState?.leadId
            ) || {}
          )
          setConversationLeads(leadContact?.leads)
        } else {
          setOpenInitConversation(true)
        }
        location.state = null
        navigate(`/social/${accountId}`, { replace: true })
      }
    }
    checkIfNavigatedFromLead()
  }, [])

  if (isFetchingByPhoneNumberLazy)
    return (
      <div className="w-full h-[350px] grid place-content-center">
        <Loader />
      </div>
    )

  return (
    <div>
      <List
        contacts={contacts}
        hasMore={hasMore}
        fetchData={fetchData}
        selectedAccountId={selectedAccountId}
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
        loading={isFetching}
        readConversations={readConversations}
        setReadConversations={setReadConversations}
      />
      <InitConversation
        selectedAccountId={selectedAccountId}
        open={openInitConversation}
        setOpen={setOpenInitConversation}
        startMessagingFirstName={initLead.firstName}
        startMessagingWhatsappNumber={initLead.whatsappNumber}
        startMessagingLeadId={initLead.id}
      />
    </div>
  )
}
