import { Button } from 'components/ui/button'

import { Sheet, SheetContent, SheetTrigger } from 'components/ui/sheet'
import { ContactSection } from './contact-section'
import { IoPersonCircleOutline } from 'react-icons/io5'

export default function ContactSheet({
  selectedLead,
  conversationLeads,
  setSelectedLead,
  initialLead,
  isDetailsPage,
  metaConversationId,
}: any) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size={'icon'}>
          <IoPersonCircleOutline size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className="pr-0 mt-14 sm:mt-0 pb-12  overflow-auto ">
        <ContactSection
          conversationLeads={conversationLeads}
          setSelectedLead={setSelectedLead}
          selectedLead={selectedLead}
          initialLead={initialLead}
          isDetailsPage={isDetailsPage}
          metaConversationId={metaConversationId}
          displayActions={false}
        />
      </SheetContent>
    </Sheet>
  )
}
