import { useEffect, useState } from 'react'
import { useGetCategoriesQuery, useUploadImageMutation } from 'app/features/kb'
import { Button } from 'components/ui/button'
import { TbDeviceFloppy, TbPhoto, TbX } from 'react-icons/tb'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Label } from 'components/ui/label'
import { Input } from 'components/ui/input'
import { toast } from 'components/ui/use-toast'
import { Textarea } from 'components/ui/textarea'

interface EditAnswerProps {
  existingAnswerData?: {
    index: number
    id: string
    fields: { key: string; value: string }[]
  }
  addNewAnswer: (data: {
    category: string
    fields: { key: string; value: string }[]
  }) => void
  updateAnswer: (
    index: number,
    id: string,
    data: { fields: { key: string; value: string }[] }
  ) => void
  onClose: () => void
  selectedCategory: string | null
}

/**
 * A modal dialog for adding or editing a category in the knowledge base.
 * @param existingAnswerData If provided, the category that is being edited.
 * @param addNewCategory A function that adds a new category to the knowledge base.
 * @param updateAnswer A function that updates an existing category in the knowledge base.
 * @param onClose A function that is called when the dialog is closed.
 */
export default function EditAnswer({
  existingAnswerData,
  addNewAnswer,
  updateAnswer,
  onClose,
  selectedCategory,
}: EditAnswerProps) {
  const [category, setCategory] = useState<string>()
  const [fields, setFields] = useState<{ key: string; value: string }[]>([])
  const { data: categories } = useGetCategoriesQuery()
  const [imageFieldIndex, setImageFieldIndex] = useState<number>()
  const [uploadImage, { isLoading: isUploadingImage }] =
    useUploadImageMutation()

  /**
   * Sets the fields based on the existingAnswerData prop
   */
  useEffect(() => {
    if (existingAnswerData) {
      setCategory(existingAnswerData.id)

      setFields(existingAnswerData.fields)
    } else if (!existingAnswerData) {
      if (selectedCategory) {
        setCategory(selectedCategory)

        const index = categories?.findIndex(
          (cat) => cat.id === selectedCategory
        )
        if (index !== undefined && index > -1 && categories) {
          const addNewAnswer = categories[index].fields.map((field) => ({
            key: field,
            value: '',
          }))
          setFields(addNewAnswer)
        }
      } else {
        setCategory('')
        setFields([])
      }
    }
  }, [existingAnswerData, selectedCategory])

  useEffect(() => {
    if (category) {
      const index = categories?.findIndex((cat) => cat.id === category)

      if (index !== undefined && index > -1 && categories) {
        const addNewAnswer = categories[index].fields.map((field) => ({
          key: field,
          value: '',
        }))
        setFields(addNewAnswer)
      }
    } else if (!category && !existingAnswerData) {
      setFields([])
    }
  }, [category])

  /**
   * Uploads a image file to the server
   */
  const onUploadImage = async (e: any) => {
    const file = e.target.files[0]

    if (file?.size > 2 * 1024 * 1024) {
      toast({
        title: 'Error',
        description: 'Image size must be less than 2MB',
      })
      return
    }

    if (file) {
      const formData = new FormData()
      formData.append('image', file)

      toast({
        title: 'Uploading image...',
        description: '',
      })

      const result: { url: string; name: string } = await uploadImage({
        formData: formData,
      }).unwrap()

      if (result?.url) {
        const newFields = fields.map((field, index) => {
          if (index === imageFieldIndex) {
            return {
              ...field,
              value: result?.url,
            }
          }
          return field
        })

        toast({
          title: 'Image uploaded successfully',
          description: '',
        })

        setFields(newFields)
      }
    }
  }

  /**
   * Validates the current state of the form and either adds a new answer or updates an existing one.
   */
  const onAddNewAnswer = () => {
    if (!category) {
      toast({
        title: "Data can't be saved",
        description: 'Category is required',
      })
      return
    }

    if (fields.length === 0) {
      toast({
        title: "Data can't be saved",
        description: 'At least one field is required',
      })
      return
    }

    if (fields.slice(1).some((field) => field.value === '')) {
      toast({
        title: "Data can't be saved",
        description: 'All fields are required',
      })
      return
    }

    if (existingAnswerData) {
      updateAnswer(existingAnswerData.index, existingAnswerData.id, {
        fields: fields,
      })
    } else {
      addNewAnswer({ category: category!, fields: fields })
    }
  }

  return (
    <div className="grid gap-4">
      <div className="space-y-2">
        <div className="flex justify-between mb-4 ">
          <div>
            <h4 className="font-medium leading-none">
              {existingAnswerData ? 'Edit' : 'Add'} data of category
            </h4>
          </div>
          <div>
            <Button
              variant={'ghost'}
              size="icon"
              onClick={onClose}
              disabled={isUploadingImage}
            >
              <TbX />
            </Button>
          </div>
        </div>
        {!existingAnswerData && (
          <>
            <p className="text-sm text-muted-foreground">Category:</p>
            <Select
              value={category}
              defaultValue={category}
              onValueChange={(value) => setCategory(value)}
              required
            >
              <SelectTrigger>
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent>
                {categories?.map((category) => (
                  <SelectItem key={category.id} value={category.id!}>
                    {category.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </>
        )}
      </div>

      <div className="grid gap-2 max-h-60 overflow-y-scroll">
        {fields.map((el, index) => (
          <div key={index} className="grid grid-cols-6 gap-2 ml-4 mb-1">
            <Label className="col-span-1 mr-2 mt-2">{el.key}</Label>
            {index === 0 && (
              <Input
                id={`field-${index}`}
                value={el.value}
                onChange={(e) => {
                  setFields((prev) =>
                    prev.map((item, i) =>
                      i === index
                        ? { key: item.key, value: e.target.value }
                        : item
                    )
                  )
                }}
                disabled
                className="col-span-4"
                required
                placeholder={'Select Image'}
              />
            )}
            {index > 0 && (
              <Textarea
                id={`field-${index}`}
                value={el.value}
                onChange={(e) => {
                  setFields((prev) =>
                    prev.map((item, i) =>
                      i === index
                        ? { key: item.key, value: e.target.value }
                        : item
                    )
                  )
                }}
                className="col-span-4"
                required
                placeholder={'Answer'}
                rows={3}
              />
            )}
            {index === 0 ? (
              <Button
                size={'icon'}
                variant={'ghost'}
                className="col-span-1"
                onClick={() => {
                  setImageFieldIndex(index)
                  document.getElementById('file-input')?.click()
                }}
                disabled={isUploadingImage}
              >
                <TbPhoto />
              </Button>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <Button onClick={onAddNewAnswer} disabled={isUploadingImage}>
          <TbDeviceFloppy className="mr-2" size={20} />
          Save
        </Button>
      </div>

      {/* hidden file input */}
      <input
        type="file"
        id="file-input"
        onChange={onUploadImage}
        style={{ display: 'none' }}
        accept="image/jpeg, image/png, image/jpg"
      />
    </div>
  )
}
