import {
  useAddConversationTagMutation,
  useGetAccountsQuery,
  useGetConversationTagsQuery,
  useUpdateConversationTagMutation,
} from 'app/features/whatsapp'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useEffect, useState } from 'react'
import { TbEdit } from 'react-icons/tb'
import { SelectAccount } from './select-account'
import { MAX_WHATSAPP_ACCOUNTS_NUMBER } from './whatsapp-login'

export default function WhatsappTags() {
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [selectedTag, setSelectedTag] = useState('')
  const [mode, setMode] = useState('add')
  const [selectedAccount, setSelectedAccount] = useState<any>()

  const { data: accounts, isLoading: isLoadingAccounts } = useGetAccountsQuery()

  const {
    data: conversationTags,
    isError,
    isLoading: dataLoading,
  } = useGetConversationTagsQuery(selectedAccount?.id)
  const [
    addConversationTag,
    { isLoading: createLoading, isError: createError },
  ] = useAddConversationTagMutation()
  const [
    updateConversationTag,
    { isLoading: updateLoading, isError: updateError },
  ] = useUpdateConversationTagMutation()

  const isLoading = dataLoading || createLoading || updateLoading

  useEffect(() => {
    if (MAX_WHATSAPP_ACCOUNTS_NUMBER == 1 && accounts?.length! > 0)
      setSelectedAccount(accounts![0])
  }, [accounts])

  useEffect(() => {
    if (createError || updateError) setError('Tag is already exist')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setName('')
      setSelectedTag('')
      setError('')
      setMode('add')
    }
  }, [mode])
  useEffect(() => {
    setName('')
    setSelectedTag('')
    setError('')
    setMode('add')
  }, [selectedAccount])

  const handleWhatsappTag = async (e: any) => {
    e.preventDefault()
    setError('')
    if (!name || name.trim() == '') return setError('Name is required')
    if (mode === 'add')
      await addConversationTag({
        tag: { name },
        accountId: selectedAccount?.id,
      })
    else
      await updateConversationTag({
        tag: {
          id: selectedTag,
          data: { name },
        },
        accountId: selectedAccount?.id,
      })
    setName('')
    setMode('add')
  }

  if (isLoading || isLoadingAccounts)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      {accounts?.length! > 0 && MAX_WHATSAPP_ACCOUNTS_NUMBER > 1 && (
        <SelectAccount
          accounts={accounts}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      )}
      {accounts?.length! > 0 && !selectedAccount && (
        <div className="p-20 grid w-full h-full place-content-center">
          Please select account
        </div>
      )}
      {accounts?.length! == 0 && (
        <div className="p-20 grid w-full h-full place-content-center">
          Please add an account
        </div>
      )}
      {selectedAccount && (
        <>
          {' '}
          {/* Form */}
          <form
            className="flex items-center gap-3 mt-5 max-md:flex-col"
            onSubmit={handleWhatsappTag}
          >
            <Input
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              type="text"
              placeholder="Whatsapp Tag"
              disabled={isLoading || createLoading}
            />
            <Button
              type="submit"
              disabled={isLoading || createLoading}
              size="sm"
            >
              {mode === 'add' ? 'Add Tag' : 'Update Tag'}
            </Button>
            {mode === 'update' && (
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setMode('add')}
              >
                Cancel update
              </Button>
            )}
          </form>
          {error !== '' && (
            <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
          )}
          {/* List */}
          <div className="mt-5 flex flex-col gap-3  min-h-[100px] max-h-[250px] overflow-y-scroll border rounded-md p-2">
            {conversationTags?.length == 0 && (
              <div className="flex items-center justify-center p-10 w-full">
                No tags added
              </div>
            )}
            {conversationTags?.map((tag: any) => (
              <div
                key={tag.id}
                className="flex items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800"
              >
                <div>{tag.name}</div>
                <div className="flex-1" />
                <div className="flex items-center gap-3">
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      setMode('update')
                      setName(tag.name)
                      setSelectedTag(tag.id)
                    }}
                  >
                    <TbEdit size={18} />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
