import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useToast } from 'components/ui/use-toast'
import { HeadersMatcher } from '../../../../leads/components/matchHeaders'
import { cleanPhoneNumber } from 'lib/utils'
import { IoMdClose } from 'react-icons/io'
import { LuFileSpreadsheet } from 'react-icons/lu'

export const MAX_FILE_SIZE = 10 * 1024 * 1024

export default function BulkUpload({ open, setLeads }: any) {
  const { toast } = useToast()
  const { user, token } = useSelector((state: any) => state.user)
  const [jsonData, setJsonData] = useState<any>(null)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<string>('')
  const [matcher, setMatcher] = useState<Map<string, string>>(new Map())
  const [reload, setReload] = useState(false)
  const [csvHeaders, setCsvHeaders] = useState<string[]>([])
  const tableColumns = ['first_name', 'phone']
  const fileRef = useRef<any>()
  const [fileType, setFileType] = useState('')

  const handleMatch = (firstItem: string, secondItem: string) => {
    const newmatcher = new Map(matcher)
    newmatcher.set(firstItem, secondItem)
    setMatcher(newmatcher)
  }

  const convertCSVToJson = (csvData: any) => {
    try {
      const lines = csvData.split('\n')
      const headers = lines[0].split(',')
      if (headers.length < 2)
        throw new Error(
          'Please review the csv file, invalid columns, columns must be  ( first_name, phone )'
        )

      for (let i = 0; i < headers.length; i++) {
        if (!headers[i].trim()) {
          throw new Error('Please review the csv file, some headers are empty')
        }
      }

      setCsvHeaders(headers)
      const result = []
      const notValidRows = []

      for (let i = 1; i < lines.length; i++) {
        const obj: any = {}
        const currentLine = lines[i].split(',')

        /* Validate the complete row*/
        if (!lines[i] || lines[i].trim() == '') continue
        if (currentLine.length != headers.length) {
          notValidRows.push(`(${i})`)
          continue
        }

        /* Validate each cell*/
        let isValid = true
        for (let j = 0; j < headers.length; j++) {
          if (!currentLine[j]?.trim()) {
            isValid = false
            notValidRows.push(`(${i}, ${headers[j].trim()})`)
            break
          }
          obj[headers[j].trim()] = currentLine[j].trim()
        }

        if (isValid) result.push(obj)
      }

      if (notValidRows.length > 0) {
        throw new Error(
          `invalid CSV file, rows [${notValidRows.join(', ')}] have invalid data`
        )
      }
      return result
    } catch (error: any) {
      setError(error.message || 'Invalid CSV file')
    }
  }

  const convertXLSXToJson = (data: any) => {
    try {
      if (data?.length == 0)
        throw new Error('Please review the xlsx file, data required')

      const headers = Object.keys(data[0])
      if (headers.includes('__EMPTY'))
        throw new Error('Please review the xlsx file, some headers are empty )')

      if (headers.length < 2)
        throw new Error(
          'Please review the xlsx file, invalid columns, columns must be at least 2 ( first_name, phone )'
        )

      setCsvHeaders(headers)
      const result = []

      const notValidRows = []

      for (let i = 0; i < data.length; i++) {
        const headersI = Object.keys(data[i])
        const values: any[] = Object.values(data[i])

        /* Validate the complete row*/
        if (headersI.includes('__EMPTY') || headersI.length > headers.length) {
          notValidRows.push(`(${i + 2})`)
          continue

          // empty rows are excluded by default
        }

        const obj: any = {}

        /* Validate each cell*/
        let isValid = true
        for (let j = 0; j < headers.length; j++) {
          if (!values[j]?.toString()?.trim()) {
            isValid = false
            notValidRows.push(`(${i + 2}, ${headers[j].trim()})`)
            break
            // throw new Error(
            //   `invalid CSV file, some ${headers[j].trim()} entries are empty!`
            // )
          }

          obj[headers[j].trim()] = values[j].toString()?.trim()
        }
        if (isValid) result.push(obj)
      }

      if (notValidRows.length > 0) {
        throw new Error(
          `invalid xlsx file, rows [${notValidRows.join(', ')}] have invalid data`
        )
      }
      return result
    } catch (error: any) {
      setError(error.message || 'Invalid xlsx file')
    }
  }

  const handleCSVInputChange = (event: any) => {
    event.preventDefault()
    setMatcher(new Map())
    setReload(true)
    setJsonData(null)
    setData(null)
    setLeads([])

    try {
      const file = event.target.files[0]
      if (file.size > MAX_FILE_SIZE) {
        return setError('Too large file! File exceeded 10 mb limits')
      }

      setError('Invalid CSV file')

      const reader = new FileReader()

      if (file.type == 'text/csv') {
        reader.onload = (e: any) => {
          const csvData = e.target.result
          const jsonData: any = convertCSVToJson(csvData)
          setJsonData(jsonData)
          setFileType('csv')
        }
        reader.readAsText(file)
      } else {
        reader.onload = (event: any) => {
          const workbook = XLSX.read(event.target.result, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const sheetData = XLSX.utils.sheet_to_json(sheet)
          const jsonData: any = convertXLSXToJson(sheetData)
          setJsonData(jsonData)
          setFileType('xlsx')
        }

        reader.readAsBinaryString(file)
      }
    } catch (error) {
      setError('Invalid file')
    }
  }

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setMatcher(new Map())
    setReload(true)
    setData(null)
    setJsonData(null)
    setError('')
    setLeads([])
  }

  useEffect(() => {
    try {
      if (jsonData) {
        setReload(false)

        if (csvHeaders.length >= 2) setError('')

        if (validateMatcher(matcher) && matcher.size == 2)
          setData(
            jsonData.map((jd: any) => ({
              firstName: jd[matcher.get('first_name')!.toString().trim()],
              phone: jd[matcher.get('phone')!.toString().trim()],
              userId: user.id,
            }))
          )
      }
    } catch (error) {}
  }, [matcher, jsonData])

  const validateMatcher = (matcher: any) => {
    return matcher.get('first_name') && matcher.get('phone')
  }

  useEffect(() => {
    const notValidLeads = []
    setError('')
    for (let i = 0; i < data?.length; i++) {
      if (data[i].firstName.length < 1) {
        notValidLeads.push(`(${fileType == 'csv' ? i + 1 : i + 2}, first name)`)
        continue
        //return setError('some first names are missed!')
      }

      if (!cleanPhoneNumber(data[i].phone)) {
        notValidLeads.push(`(${fileType == 'csv' ? i + 1 : i + 2}, phone)`)
        continue
        // return setError('some phone numbers are invalid!')
      }
    }

    if (notValidLeads.length > 0) {
      return setError(
        `invalid  file, rows [${notValidLeads.join(', ')}] have invalid data`
      )
    }

    if (data && data.length > 0) {
      const body = data.map(({ phone, ...item }: any) => {
        return {
          phone: cleanPhoneNumber(phone),
          ...item,
        }
      })
      setLeads(body)
    } else {
      if (jsonData) setError('Please review the file, invalid columns')
    }
  }, [data])

  useEffect(() => {
    setJsonData(null)
    setData(null)
    setMatcher(new Map())
    setCsvHeaders([])
    setLeads([])

    setError('')
  }, [open])

  const exportAsCsvTemplateHandler = async () => {
    try {
      const hiddenElement = document.createElement('a')
      hiddenElement.href =
        'data:text/plain;charset=utf-8,' +
        encodeURIComponent(
          `first_name,phone
  John,+971123456789
  Jane,+971123456780`
        )
      hiddenElement.download = `leads-template.csv`
      hiddenElement.click()
    } catch (error: any) {
      toast({
        title: 'Error occured',
      })
    }
  }
  const exportAsXlsxTemplateHandler = async () => {
    try {
      const data = [
        { first_name: 'John', phone: '971123456789' },
        { first_name: 'Jane', phone: '971123456789' },
      ]
      const worksheet = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
      saveAs(blob, `${'leads-template'}.xlsx`)
    } catch (error: any) {
      toast({
        title: 'Error occured',
      })
    }
  }

  return (
    <div
      className={`sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 ${jsonData && !reload && ' flex-1 h-full '} overflow-y-scroll`}
    >
      <div>
        <>
          <h1 className="font-semibold text-lg">Bulk Upload</h1>
          <p className="text-sm">
            Upload multiple leads, make sure to{' '}
            <i>
              match the columns of the csv file to be similar to the structure
              shown in the example below{' '}
            </i>
          </p>
          <p className="mt-5 text-sm max-md:hidden">Example CSV file</p>
          <div className="mt-2 text-xs p-3 border bg-gray-100 rounded-md max-md:hidden">
            <pre>
              first_name,phone
              <br />
              John,+971123456789
              <br />
              Jane,+971123456780
            </pre>
          </div>
          <div className="flex  mt-5 w-full  items-center gap-4 max-md:gap-2">
            <div className=" items-center flex relative w-full">
              {/* <Label>CSV File</Label> */}
              <Input
                id="leads"
                type="file"
                ref={fileRef}
                accept=".csv, .xlsx"
                className="cursor-pointer"
                onChange={handleCSVInputChange}
              />
              <IoMdClose
                size={20}
                className="text-gray-600 hover:cursor-pointer hover:text-gray-900 absolute right-2"
                onClick={replaceFileHandler}
              />
            </div>
            <div className="flex gap-1 justify-end">
              <Button
                onClick={exportAsCsvTemplateHandler}
                size="sm"
                className="flex items-center justify-center gap-1 p-2"
              >
                <LuFileSpreadsheet />
                <span>csv</span>
              </Button>
              <Button
                onClick={exportAsXlsxTemplateHandler}
                size="sm"
                className="flex items-center justify-center gap-1 p-2"
              >
                <LuFileSpreadsheet />

                <span>xlsx</span>
              </Button>
            </div>
          </div>

          {jsonData && !reload && (
            <>
              <p className="mb-2 mt-5 text-md ">Headers Matching</p>
              <HeadersMatcher
                onColumnMatch={handleMatch}
                columnNames={tableColumns}
                csvColumnHeaders={csvHeaders}
                columnMapping={matcher}
              />
            </>
          )}

          <div className="flex h-5 w-full items-center text-xs font-bold text-rose-600 mt-">
            {error}
          </div>
        </>
      </div>
    </div>
  )
}
