import {
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from 'app/features/kb'
import { useToast } from 'components/ui/use-toast'
import { useEffect, useState } from 'react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'

import { TbCircleDot, TbEdit, TbPlus, TbSitemap, TbTrash } from 'react-icons/tb'
import { Dialog, DialogContent } from 'components/ui/dialog'
import EditCategory from './edit-category'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from 'components/ui/card'

/**
 * KnowledgeBank component manages the display and modification of categories and fields
 * within an organization's hierarchy. It allows users to add, update, and delete categories,
 * and save these changes to the backend structure. The component also handles loading state
 * and error notifications related to structure data.
 */
export default function Categories() {
  const { toast } = useToast()
  const [deleteDialog, setDeleteDialog] = useState<{
    id: string | null
    open: boolean
  }>({
    id: null,
    open: false,
  })

  const [categories, setCategories] = useState<KBCategory[]>([])
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [editExistingCategory, setEditExistingCategory] = useState<
    | {
        index: number
        id: string
        name: string
        fields: string[]
      }
    | undefined
  >()

  const {
    data: categoriesData,
    isLoading,
    isError: isErrorLoading,
  } = useGetCategoriesQuery()

  const [
    createCategory,
    { isSuccess: isCategoryCreated, isError: isCreateError },
  ] = useCreateCategoryMutation()

  const [
    updateCategory,
    { isSuccess: isCategoryUpdated, isError: isUpdateError },
  ] = useUpdateCategoryMutation()

  const [
    deleteCategory,
    { isSuccess: isCategoryDeleted, isError: isDeleteError },
  ] = useDeleteCategoryMutation()

  /**
   * Updates the list of categories when the structure data is received.
   */
  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData)
    }
  }, [categoriesData])

  /**
   * Displays an error message if there was an error saving the structure.
   */
  useEffect(() => {
    if (isCreateError) {
      toast({
        title: 'Error occurred',
        description: isCreateError,
      })
    }
  }, [isErrorLoading])

  /**
   * Adds a new category to the existing list of categories and updates the structure.
   * @param data - The category data to be added, which includes a name and an array of fields.
   */
  const onAddNewCategory = async (data: { name: string; fields: string[] }) => {
    if (data.name && data.fields) {
      try {
        await createCategory({
          name: data.name,
          fields: data.fields,
        }).unwrap()

        setEditDialogOpen(false)
      } catch (error) {
        console.log(error)

        if ((error as any).status === 409) {
          toast({
            title: 'Category name already exists',
            description: 'Category name already exists. Use different name.',
          })
          return
        }

        toast({
          title: 'Error occurred',
          description: 'An error occurred while creating the category',
        })
      }
    }
  }

  /**
   * Updates an existing category in the list of categories and saves the updated structure.
   * @param data - An object containing the category details to be updated, including:
   */
  const onUpdateCategory = async (
    index: number,
    id: string,
    data: {
      name: string
      fields: string[]
    }
  ) => {
    if (data.name && data.fields) {
      try {
        await updateCategory({
          id,
          name: data.name,
          fields: data.fields,
        }).unwrap()

        setEditDialogOpen(false)
        setEditExistingCategory(undefined)
      } catch (error) {
        toast({
          title: 'Error occurred',
          description: 'An error occurred while updating the category',
        })
      }
    }
  }

  /**
   * Deletes a category from the list of categories and saves the updated structure.
   * @param index - The index of the category to be deleted.
   */
  const onClickDelete = async (index: number) => {
    const id = categories[index].id!

    setDeleteDialog({ id, open: true })
  }

  /**
   * Confirms deletion of a category and deletes it from the list of categories and saves the updated structure.
   * @returns a Promise that resolves when the operation is done
   */
  const confirmDeleteCategory = async () => {
    if (!deleteDialog.id) return

    try {
      await deleteCategory(deleteDialog.id).unwrap()

      setDeleteDialog({ id: null, open: false })
    } catch (error) {
      if ((error as any).status === 409) {
        toast({
          title: 'Category in use',
          description:
            'Remove all data that are using this category before deleting',
        })
      } else {
        toast({
          title: 'Error occurred',
          description: 'An error occurred while deleting the category',
        })
      }
    }
  }

  return (
    <>
      <div className="flex flex-col p-6">
        <div className="flex justify-end mb-4">
          <Button variant="outline" onClick={() => setEditDialogOpen(true)}>
            <TbPlus className="mr-2" />
            Add Category
          </Button>
        </div>

        <div className="px-20">
          {categories && categories.length > 0 && !isLoading && (
            <div className="flex flex-wrap">
              {categories?.map(
                (
                  category: { name: string; fields: string[] },
                  index: number
                ) => (
                  <div className="w-1/3 mb-4">
                    <Card className="m-2 h-full" key={index}>
                      <CardHeader className="pt-1 pr-1 pb-1">
                        <div className="flex justify-between items-center">
                          <div className="font-bold">{category.name}</div>
                          <div className="flex">
                            <div className="m-2">
                              <Button
                                variant={'ghost'}
                                size="icon"
                                className="mr-1"
                                onClick={() => {
                                  setEditExistingCategory({
                                    index,
                                    id: categories[index].id!,
                                    name: category.name,
                                    fields: category.fields,
                                  })
                                  setEditDialogOpen(true)
                                }}
                              >
                                <TbEdit className="h-4 w-4" />
                              </Button>
                              <Button
                                variant={'ghost'}
                                size="icon"
                                onClick={() => onClickDelete(index)}
                              >
                                <TbTrash className="h-4 w-4" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </CardHeader>
                      <CardContent>
                        {category.fields.map((field: any) => (
                          <CardDescription
                            className="mb-2 flex items-center"
                            key={field}
                          >
                            <TbCircleDot className="m-1 mr-2" size={18} />
                            {field}
                          </CardDescription>
                        ))}
                      </CardContent>
                    </Card>
                  </div>
                )
              )}
            </div>
          )}
          {(!categories || categories.length === 0) && !isLoading && (
            <div className="flex flex-col items-center justify-center h-80">
              <p className="text-center text-gray-400">
                No categories found. You can start by adding a new category
              </p>
            </div>
          )}
        </div>
        <Dialog open={editDialogOpen}>
          <DialogContent className="sm:max-w-[425px] [&>button]:hidden">
            <EditCategory
              addNewCategory={onAddNewCategory}
              updateCategory={onUpdateCategory}
              existingCategoryData={editExistingCategory}
              onClose={() => {
                setEditDialogOpen(false)
                setEditExistingCategory(undefined)
              }}
            />
          </DialogContent>
        </Dialog>

        <AlertDialog open={deleteDialog.open}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This will permanently delete the category
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => setDeleteDialog({ id: null, open: false })}
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction onClick={confirmDeleteCategory}>
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  )
}
