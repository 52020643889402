import { PreviewMedia } from './preview-media'
import dayjs from 'dayjs'
import { replaceAndConvertPlaceholders } from 'lib/utils'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel'
interface PreviewTemplateProps {
  template: any
  headerMediaSample: any
  headerTextSample: string[]
  bodySample: string[]
  carousel?: any
}

export default function PreviewTemplateMessage({
  template,
  headerMediaSample,
  headerTextSample,
  bodySample,
  carousel,
}: PreviewTemplateProps) {
  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const headerFormat = headerComponent?.format

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )
  const footerComponent = template?.components?.find(
    (component: any) => component.type == 'FOOTER'
  )

  const buttonComponent = template?.components?.find(
    (component: any) => component.type == 'BUTTONS'
  )

  const carouselComponent = template?.components?.find(
    (component: any) => component.type == 'CAROUSEL'
  )

  return (
    <div className="sm:max-w-[425px] md:max-w-[600px] bg-white dark:bg-gray-900 pr-3  break-words">
      <div className="h-full w-full">
        <div className="w-full max-h-[500px]  overflow-hidden overflow-y-scroll mt-4 flex flex-col gap-2 p-4 whitespace-pre-wrap">
          <span className="bg-gray-100 text-white py-2 px-4 rounded-md max-w-xs">
            <span className="font-bold text-sm text-black">Sender Name</span>
            {headerComponent && headerFormat == 'TEXT' && (
              <span className="block mt-1 w-full text-black font-bold">
                {replaceAndConvertPlaceholders(
                  headerComponent?.text,
                  headerTextSample
                )}
              </span>
            )}
            {headerComponent && headerFormat != 'TEXT' && (
              <PreviewMedia
                type={headerFormat}
                href={
                  headerMediaSample
                  //headerComponent?.example?.header_handle[0]
                }
                filename={'document'}
              />
            )}
            <span className="block mt-1 w-full text-black">
              {replaceAndConvertPlaceholders(bodyComponent?.text, bodySample)}
            </span>
            {footerComponent && (
              <span className="block mt-1 w-full text-gray-500 text-xs">
                {footerComponent?.text}
              </span>
            )}
            {buttonComponent && (
              <>
                {buttonComponent.buttons.map((button: any) => {
                  return (
                    <div className="flex justify-center items-center p-2 rounded-lg my-2 border shadow bg-white text-indigo-600">
                      {button.text}
                    </div>
                  )
                })}
              </>
            )}
            {carouselComponent && (
              <Carousel className="w-full max-w-xs bg-slate-100 rounded-md   ">
                <CarouselContent>
                  {carouselComponent?.cards?.map((card: any, i: any) => {
                    return (
                      <CarouselItem key={i}>
                        <div className="p-1 pt-0 w-full">
                          <PreviewCarouselMessage
                            template={card}
                            headerMediaSample={carousel[i]?.headerMediaSample}
                            bodySample={carousel[i]?.bodySample}
                          />
                        </div>
                      </CarouselItem>
                    )
                  })}
                </CarouselContent>
                <CarouselPrevious className="translate-x-6 text-indigo-600 hover:text-indigo-900" />
                <CarouselNext className=" -translate-x-6 text-indigo-600 hover:text-indigo-900" />
              </Carousel>
            )}
            <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
              {dayjs(new Date()).format('YYYY-MM-DD HH:mm A')}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

function PreviewCarouselMessage({
  template,
  headerMediaSample,
  bodySample,
}: any) {
  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const headerFormat = headerComponent?.format

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )

  const buttonComponent = template?.components?.find(
    (component: any) => component.type == 'BUTTONS'
  )

  return (
    <div className="max-w-xs bg-white dark:bg-gray-900 flex flex-col ">
      <span className="bg-gray-100 text-white p-2 rounded-md max-w-xs ">
        <PreviewMedia
          type={headerFormat}
          href={headerMediaSample}
          filename={'document'}
        />

        <span className="block mt-1 w-full text-black">
          {replaceAndConvertPlaceholders(bodyComponent?.text, bodySample)}
        </span>

        {buttonComponent.buttons.map((button: any) => {
          return (
            <div className="flex justify-center items-center p-2 rounded-lg my-2 border shadow bg-white text-indigo-600">
              {button.text}
            </div>
          )
        })}
      </span>
    </div>
  )
}
