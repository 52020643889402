import { useState, FormEvent, useEffect } from 'react'
import { Button } from 'components/ui/button'
import { Label } from '@radix-ui/react-label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useGetConversationTagsQuery } from 'app/features/whatsapp'
import MySelect from 'components/shared/select/MySelect'
import { FaFilter } from 'react-icons/fa'
import { FaArrowsRotate } from 'react-icons/fa6'
import { RiFilter2Line } from 'react-icons/ri'
import { useGetAllAgentsNamesQuery } from 'app/features/users'

export default function Filters({
  setFilters,
  setKeyword,
  selectedAccountId,
}: {
  setFilters: any
  setKeyword: any
  selectedAccountId: any
}) {
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [selectedAgents, setSelectedAgents] = useState<any>([])
  const [read, setRead] = useState('All')
  const [campaign, setCampaign] = useState('Not_Campaign')
  const { data: tags, isLoading: isLoadingTags } =
    useGetConversationTagsQuery(selectedAccountId)
  const {
    data: agents,
    isLoading: isAgentsLoading,
    isError: isAgentsError,
  } = useGetAllAgentsNamesQuery()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    handleClearFilters()
  }, [selectedAccountId])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    setFilters({
      agents: selectedAgents.map((agent: { id: any }) => agent.id),
      tags: selectedTags.map((tag: { id: any }) => tag.id),
      ...(read != 'All' && {
        read,
      }),
      ...(campaign != 'All' && {
        campaign,
      }),
    })
    setOpen(false)
  }

  const handleClearFilters = () => {
    setKeyword('')
    setSelectedTags([])
    setSelectedAgents([])
    setFilters({ campaign: 'Not_Campaign', read: 'All', tags: [], agents: [] })
    setRead('All')
    setCampaign('Not_Campaign')
    setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant="ghost"
          className="flex p-2 data-[state=open]:bg-muted relative"
          disabled={!selectedAccountId}
        >
          <RiFilter2Line size={22} />{' '}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="bg-white dark:bg-gray-900 md:min-w-[40rem] sm:w-full  overflow-y-scroll">
        <div className="flex justify-end">
          <Button variant="outline" size="icon" onClick={() => setOpen(false)}>
            <Cross1Icon className="h-3 w-3" />
          </Button>
        </div>
        <div className="w-full rounded-lg  px-3 bg-white dark:bg-gray-900 mb-3">
          <form
            onSubmit={handleSubmit}
            className="flex flex-row flex-wrap items-center max-md:flex-col "
          >
            <div className="w-1/2 p-2 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <Label className="text-sm font-semibold">Agents</Label>
                <MySelect
                  selected={selectedAgents}
                  setSelected={setSelectedAgents}
                  data={agents?.map((agent) => ({
                    name: agent.firstName + ' ' + agent.lastName,
                    id: agent.id,
                  }))}
                  selectMeta="agent"
                />
              </div>
            </div>

            <div className="w-1/2 p-2 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <Label className="text-sm font-semibold">Tags</Label>
                <MySelect
                  selected={selectedTags}
                  setSelected={setSelectedTags}
                  data={tags}
                  selectMeta="tag"
                />
              </div>
            </div>

            <div className="w-1/2 p-2 max-md:w-full">
              <div className="space-y-2">
                <Label className="text-sm font-semibold">Read</Label>
                <Select
                  value={read}
                  onValueChange={(value: any) => setRead(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>{read.replace('_', ' ')}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="Read">Read</SelectItem>
                      <SelectItem value="Not_Read">Unread</SelectItem>
                      <SelectItem value="All">All</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="w-1/2 p-2 max-md:w-full">
              <div className="space-y-2">
                <Label className="text-sm font-semibold">Campaign</Label>
                <Select
                  value={campaign}
                  onValueChange={(value: any) => setCampaign(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>
                      {campaign == 'Not_Campaign'
                        ? 'Select'
                        : campaign.replace('_', ' ')}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="Campaign">Campaign</SelectItem>
                      <SelectItem className="hidden" value="Not_Campaign">
                        Not Campaign
                      </SelectItem>
                      <SelectItem value="All">All</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="w-full p-4 flex justify-end ">
              <div className="space-x-2 mt-2">
                <Button size="sm">Apply</Button>
                <Button
                  size="sm"
                  type="button"
                  variant="outline"
                  className="bg-white"
                  onClick={handleClearFilters}
                >
                  Clear{' '}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
