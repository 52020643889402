import { Button } from 'components/ui/button'
import { TbMenu2 } from 'react-icons/tb'
import { cn } from 'lib/utils'
import { Sheet, SheetContent, SheetTrigger } from 'components/ui/sheet'
import SettingScreenName from 'types/ScreenName'
export const SettingsMobileMenu = ({ screen, setScreen }: any) => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="outline"
          className="shrink-0 md:hidden gap-4 flex items-center"
        >
          Settings
          <TbMenu2 />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent
        side="top"
        className="flex flex-col rounded-lg overflow-scroll  max-h-96 z-[500] "
      >
        <div
          className={cn(
            screen === 'followUp' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('followUp')}
        >
          Follow Up
        </div>

        <div
          className={cn(
            screen === 'hierarchy' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('hierarchy')}
        >
          Hierarchy
        </div>

        <div
          className={cn(
            screen === 'sources' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('sources')}
        >
          Lead Sources
        </div>
        <div
          className={cn(
            screen === 'leadForm' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('leadForm')}
        >
          Lead Form
        </div>

        <div
          className={cn(
            screen === 'not-qualified-reasons'
              ? 'bg-gray-200'
              : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('not-qualified-reasons')}
        >
          Not Qualified
        </div>

        <div
          className={cn(
            screen === 'groups' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('groups')}
        >
          User Groups
        </div>
        <div
          className={cn(
            screen === 'active-tabs' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('active-tabs')}
        >
          Active Tabs
        </div>

        <div
          className={cn(
            screen === 'social' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('social')}
        >
          Social
        </div>

        <div
          className={cn(
            screen === 'social' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('social')}
        >
          Social
        </div>
        <div
          className={cn(
            screen === 'offers' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen(SettingScreenName.offers)}
        >
          Offers & Packages
        </div>

        <div
          className={cn(
            screen === 'knowledge-bank' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen(SettingScreenName.knowledgeBank)}
        >
          Knowledge Bank
        </div>

        <div
          className={cn(
            screen === 'insurance' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('insurance')}
        >
          Insurance
        </div>
      </SheetContent>
    </Sheet>
  )
}
