import { Input } from 'components/ui/input'
import {
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useGetOfferByIdQuery,
} from 'app/features/offers'
import { Button } from 'components/ui/button'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TbX } from 'react-icons/tb'
import { useEffect, useState } from 'react'
import { toast } from 'components/ui/use-toast'
import { Textarea } from 'components/ui/textarea'

const schema = z.object({
  name: z.string().min(1),
  description: z.string().optional().default(''),
  price: z.coerce.number().int().min(1),
  sessionsNumber: z.coerce.number().int().min(1).max(100),
})
type FormValues = z.infer<typeof schema>

/**
 * A helper function to display an error message for a input field
 * @param data - an object containing the error message
 * @returns a JSX element displaying the error message
 */
const InputError = (data: { text?: string }) => {
  return (
    <>
      <div style={{ height: '20px' }}>
        <p
          className={`text-red-500 text-xs flex items-center ${
            data.text ? 'animate-slide-in' : 'hidden'
          }`}
        >
          {data.text}
        </p>
      </div>
    </>
  )
}

/**
 * A component to create or update an offer
 * @param props.offerId - the offer id to update, if none is provided, a new offer will be created
 * @param props.closeDialog - a function to close the dialog
 */

export default function EditOffer(props: {
  offerId?: string
  closeDialog: () => void
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  })

  const { offerId } = props
  const [saveError, setSaveError] = useState('')

  const [
    createOffer,
    {
      isSuccess: isOfferCreated,
      isError: isOfferCreateError,
      isLoading: isCreating,
    },
  ] = useCreateOfferMutation()
  const [
    updateOffer,
    {
      isSuccess: isOfferUpdated,
      isError: isOfferUpdateError,
      isLoading: isUpdating,
    },
  ] = useUpdateOfferMutation()

  const { data } = useGetOfferByIdQuery(offerId, {
    skip: !offerId,
  })
  const [lockSessions, setLockSessions] = useState(false)

  useEffect(() => {
    if (data && data?.sales) setLockSessions(data?.sales?.length > 0)
  }, [data])

  /**
   * Sets the form values based on the offer data
   */
  useEffect(() => {
    if (data) {
      const { name, description, price, sessionsNumber } = data

      setValue('name', name)
      setValue('description', description)
      setValue('price', price)
      setValue('sessionsNumber', sessionsNumber)
    }
  }, [data])

  /**
   * Closes the dialog if the offer is created or updated
   */
  useEffect(() => {
    if (isOfferCreated || isOfferUpdated) {
      props.closeDialog()
    }
  }, [isOfferCreated, isOfferUpdated])

  /**
   * Displays an error toast if the offer creation or update fails
   */
  useEffect(() => {
    if (isOfferCreateError || isOfferUpdateError) {
      toast({
        title: 'Error',
        description: 'Error saving offer',
        variant: 'destructive',
      })
    }
  }, [isOfferCreateError, isOfferUpdateError])

  /**
   * Handles form submission by either creating a new offer or updating an existing one
   * @param data - the form data
   * @returns a Promise that resolves when the operation is done
   */
  const onSubmit = async (data: FormValues) => {
    try {
      if (!offerId) {
        await createOffer(data).unwrap()
      } else if (offerId) {
        await updateOffer({
          id: offerId,
          ...data,
        }).unwrap()
      }
    } catch (error) {
      setSaveError((error as any).data)
    }
  }

  const RequiredMark = () => <span className="mx-1 text-rose-500">*</span>

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h1 className="text-lg font-medium">Offer</h1>
          <p className="text-xs text-gray-500 mb-4">
            {offerId ? 'Edit offer details' : 'Create a new offer'}
          </p>
        </div>
        <div>
          <Button variant="outline" onClick={props.closeDialog}>
            <TbX />
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <div className="">
            <label htmlFor="name">
              Name <RequiredMark />{' '}
            </label>

            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            <InputError text={errors?.name?.message} />
          </div>
        </div>

        <div>
          <div className="flex flex-col gap-1">
            <label htmlFor="description">Description</label>

            <Controller
              name="description"
              control={control}
              render={({ field }) => <Textarea {...field} rows={4} />}
            />
            <InputError text={errors?.description?.message} />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="price">
              Price <RequiredMark />
            </label>
            <Controller
              name="price"
              control={control}
              render={({ field }) => <Input {...field} type="number" min={1} />}
            />
            <InputError text={errors?.price?.message} />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="sessionsNumber">
              Sessions Number <RequiredMark />
            </label>
            <Controller
              name="sessionsNumber"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  min={1}
                  readOnly={lockSessions}
                />
              )}
            />
            {lockSessions && (
              <small className="text-xs text-gray-500">
                Can not be changed because the offer is used in sales
              </small>
            )}
            <InputError text={errors?.sessionsNumber?.message} />
          </div>
        </div>

        <div className="flex justify-between">
          <div>
            {saveError !== '' && (
              <p className="text-rose-500 text-sm">{saveError}</p>
            )}
          </div>
          <Button type="submit" disabled={isCreating || isUpdating}>
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
