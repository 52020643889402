import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  useGetBranchesCountQuery,
  useGetBranchesQuery,
} from 'app/features/branch'
import {
  useGetDepartmentsQuery,
  useGetDepartmentsCountQuery,
} from 'app/features/department'
import {
  useGetSpecialistsCountQuery,
  useGetSpecialistsQuery,
} from 'app/features/specialist'
import { useGetGroupsQuery } from 'app/features/group'
import { useGetSourcesQuery } from 'app/features/source'
import {
  useGetSettingsQuery,
  useGetAccessPeriodQuery,
  useGetNotQualifiedReasonsQuery,
  useGetInsuranceProvidersQuery,
  useGetActiveTabsQuery,
} from 'app/features/settings'
import {
  useGetCanBeClaimedInsuranceLeadsCountQuery,
  useGetCanBeFollowedUpLeadsCountQuery,
  useGetLeadsCountQuery,
} from 'app/features/lead'
import { useGetPendingAuthDeviceRequestsCountQuery } from 'app/features/auth'
import { useSelector } from 'react-redux'
import { useGetPendingTasksQuery } from 'app/features/task'
import { useGetAccountsQuery } from 'app/features/whatsapp'

export const AppContext = createContext<any | null>(null)

export const AppProvider = ({ children }: { children: any }) => {
  const { user } = useSelector((state: any) => state.user)

  let isAppLoading = true
  const [filters, setFilters] = useState<DashboardFilters | null>(null)
  const branches = useGetBranchesQuery()
  const departments = useGetDepartmentsQuery()
  const specialists = useGetSpecialistsQuery()
  const groups = useGetGroupsQuery()
  const sources = useGetSourcesQuery()
  const settings = useGetSettingsQuery()
  const leadsCount = useGetLeadsCountQuery(filters)
  const branchesCount = useGetBranchesCountQuery(filters)
  const departmentsCount = useGetDepartmentsCountQuery(filters)
  const specialistsCount = useGetSpecialistsCountQuery(filters)
  const allLeadsCount = useGetLeadsCountQuery(null)
  const allBranchesCount = useGetBranchesCountQuery(null)
  const allDepartmentsCount = useGetDepartmentsCountQuery(null)
  const allSpecialistsCount = useGetSpecialistsCountQuery(null)
  const accessPeriod = useGetAccessPeriodQuery()
  const notQualifiedReasons = useGetNotQualifiedReasonsQuery()
  const insuranceProviders = useGetInsuranceProvidersQuery()
  const activeTabs = useGetActiveTabsQuery()

  const canBeFollowedUpLeadsCount =
    useGetCanBeFollowedUpLeadsCountQuery(filters)

  const canBeClaimedInsuranceLeadsCount =
    useGetCanBeClaimedInsuranceLeadsCountQuery(filters)
  const loginRequests = useGetPendingAuthDeviceRequestsCountQuery(filters)
  const pendingTasks = useGetPendingTasksQuery(null)
  const socialAccounts = useGetAccountsQuery()
  const [refetchWhatsappContacts, setRefetchWhatsappContacts] = useState(false)

  isAppLoading = useMemo(
    () =>
      branches.isLoading ||
      departments.isLoading ||
      specialists.isLoading ||
      groups.isLoading ||
      sources.isLoading ||
      settings.isLoading ||
      activeTabs.isLoading ||
      notQualifiedReasons.isLoading ||
      insuranceProviders.isLoading ||
      canBeClaimedInsuranceLeadsCount.isLoading ||
      canBeFollowedUpLeadsCount.isLoading ||
      loginRequests.isLoading ||
      pendingTasks.isLoading ||
      socialAccounts.isLoading,

    [
      branches.isLoading,
      departments.isLoading,
      specialists.isLoading,
      groups.isLoading,
      sources.isLoading,
      settings.isLoading,
      activeTabs.isLoading,
      notQualifiedReasons.isLoading,
      insuranceProviders.isLoading,
      canBeClaimedInsuranceLeadsCount.isLoading,
      canBeFollowedUpLeadsCount.isLoading,
      loginRequests.isLoading,
      pendingTasks.isLoading,
      socialAccounts.isLoading,
    ]
  )

  useEffect(() => {
    canBeFollowedUpLeadsCount.refetch()
    canBeClaimedInsuranceLeadsCount.refetch()
    loginRequests.refetch()
    insuranceProviders.refetch()
    pendingTasks.refetch()
    socialAccounts.refetch()
  }, [user])
  return (
    <AppContext.Provider
      value={{
        branches: branches.data,
        departments: departments.data,
        specialists: specialists.data,
        groups: groups.data,
        sources: sources.data,
        settings: settings.data,
        activeTabs: activeTabs.data,
        leadsCount: leadsCount?.data?.count,
        allLeadsCount: allLeadsCount?.data?.count,
        isAppLoading: isAppLoading,
        accessPeriod: accessPeriod.data,
        filters: filters,
        setFilters: setFilters,
        branchesCount: branchesCount?.data?.count,
        allBranchesCount: allBranchesCount?.data?.count,
        allDepartmentsCount: allDepartmentsCount?.data?.count,
        allSpecialistsCount: allSpecialistsCount?.data?.count,
        notQualifiedReasons: notQualifiedReasons?.data,
        insuranceProviders: insuranceProviders?.data,
        canBeFollowedUpLeadsCount: canBeFollowedUpLeadsCount?.data?.count,
        canBeClaimedInsuranceLeadsCount:
          canBeClaimedInsuranceLeadsCount?.data?.count,
        loginRequestsCount: loginRequests?.data?.count,
        pendingTasksCount: pendingTasks?.data?.count,
        socialAccounts: socialAccounts?.data,
        refetchWhatsappContacts: refetchWhatsappContacts,
        setRefetchWhatsappContacts: setRefetchWhatsappContacts,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default function useApp() {
  return useContext(AppContext)
}
