import { Cross1Icon } from '@radix-ui/react-icons'
import { useGetUnsubscribedNumbersQuery } from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Link } from 'react-router-dom'

export default function ListUnsubscribedNUmbers({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: any) => void
}) {
  const { data, isLoading, isError } = useGetUnsubscribedNumbersQuery()

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild></AlertDialogTrigger>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[600px] bg-white dark:bg-gray-900 pr-3">
          {isLoading && (
            <div className="h-40 grid place-content-center">
              <Loader />
            </div>
          )}
          {isError && (
            <div className="h-40 grid place-content-center">
              <Error message="Server error, contact administrator please" />
            </div>
          )}
          {!isError && !isLoading && (
            <div className="h-full w-full">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  Un-subscribed numbers
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>
              <div className="text-sm mt-2 mb-6">
                View list of phone numbers who were unsubscribed to campaigns in
                WhatsApp
              </div>

              <div className="w-full max-h-[550px] overflow-hidden overflow-y-scroll mt-4">
                <div className="w-full overflow-hidden overflow-x-scroll mb-10">
                  <table className="w-full table-fixed">
                    <thead>
                      <tr className="text-center border-b w-full ">
                        <th className="xl:w-52">Name</th>
                        <th className="xl:w-52">Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((numberEntry: any) => (
                        <tr
                          key={numberEntry.id}
                          className="w-full border-b border-gray-100 last:border-b-0 py-1"
                        >
                          <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            <Link
                              to={`/lead-details/${numberEntry.leadId}`}
                              className="text-blue-600 underline"
                            >
                              {numberEntry?.name}
                            </Link>
                          </td>
                          <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {numberEntry?.number}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {data?.length == 0 && (
                    <div className="h-40 w-full grid place-content-center text-sm">
                      No data found
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
