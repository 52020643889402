import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { sentenceCase } from 'lib/utils'

type MatchHeadersMap = Map<string, string>

type FirstColumnProps = {
  onColumnMatch: (firstItem: string, secondItem: string) => void
  columnMapping: MatchHeadersMap
  columnNames: string[]
  csvColumnHeaders: string[]
}

export const HeadersMatcher: React.FC<FirstColumnProps> = ({
  onColumnMatch: onColumnMatch,
  columnMapping,
  columnNames,
  csvColumnHeaders,
}) => {
  return (
    <div className="flex flex-wrap  gap-x-10 gap-y-2 ">
      {columnNames.map((column: any) => (
        <div className="w-1/3 max-md:w-full" key={column}>
          <MatchingSelector
            columnMapping={columnMapping}
            label={column}
            name={column}
            required={true}
            list={csvColumnHeaders}
            setValue={(v: string) => {
              onColumnMatch(column, v)
            }}
            value={columnMapping.get(column)}
            // allowDisabling={
            //   ['phone', 'whatsapp_number'].includes(column) === false
            // }
            allowDisabling={true}
          />
        </div>
      ))}
    </div>
  )
}

const MatchingSelector = ({
  columnMapping,
  label,
  name,
  required,
  list,
  value,
  setValue,
  allowDisabling,
}: {
  columnMapping: MatchHeadersMap
  label: string
  name: string
  required: boolean
  list: string[]
  value: string | undefined
  setValue: (v: string) => void
  allowDisabling: boolean
}) => {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={name}>
        {sentenceCase(label)}
        {required && label != 'email' && (
          <span className="ml-1 text-rose-500">*</span>
        )}
      </Label>
      <Select value={value} onValueChange={setValue}>
        <SelectTrigger>
          <SelectValue placeholder={'Select matching column'} />
        </SelectTrigger>
        <SelectContent>
          {list.map((item: any) => (
            <SelectItem
              key={item}
              value={item}
              disabled={
                allowDisabling &&
                new Array(...columnMapping?.values()).includes(item)
              }
            >
              {item}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
