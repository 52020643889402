import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { setCredentials, removeCredentials, updateUser } from './slices/user'
import { setReauthenticateMeta } from './slices/app'

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_API_URL
      : process.env.REACT_APP_PRD_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().user.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithRefresh = async (
  args: string | FetchArgs,
  api: any,
  extraOptions: {}
) => {
  let result: any = await baseQuery(args, api, extraOptions)

  // if (result?.error?.originalStatus === 401) {
  //   // OR NAVIGATE TO Unauthorized PAGE
  //   api.dispatch(removeCredentials())
  // }

  if (result?.error?.originalStatus === 401) {
    const refreshResult: any = await baseQuery(
      '/auth/refresh',
      api,
      extraOptions
    )

    if (refreshResult?.data) {
      const user = api.getState().user.user
      const socketServerToken = api.getState().user.socketServerToken
      api.dispatch(
        setCredentials({
          user,
          token: refreshResult.data.accessToken,
          socketServerToken,
        })
      )
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(removeCredentials())
    }
  }
  if (result?.error?.originalStatus === 405) {
    const refreshResult: any = await baseQuery(
      '/users/refresh-profile',
      api,
      extraOptions
    )
    if (refreshResult?.data) {
      api.dispatch(updateUser(refreshResult?.data))
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(removeCredentials())
    }
  }
  if (result?.error?.originalStatus === 409) {
    //
  }

  if (result?.error?.originalStatus === 419) {
    const currentRoute = location.pathname

    if (currentRoute.includes('social')) {
      api.dispatch(setReauthenticateMeta(true))
    }
  } else if (result?.error?.originalStatus !== 419) {
    api.dispatch(setReauthenticateMeta(false))
  }
  return result
}

export const api = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({}),
  tagTypes: [
    'Dashboard',
    'Settings',
    'Leads',
    'Branches',
    'AccessPeriod',
    'Departments',
    'Specialists',
    'Activities',
    'Sources',
    'Groups',
    'Users',
    'Subscriptions',
    'AuthDevices',
    'Lead',
    'Contacts',
    'Accounts',
    'Conversations',
    'WhatsappProfile',
    'Templates',
    'Tasks',
    'MetaConversations',
    'MetaContacts',
    'WhatsappStats',
    'Offers',
    'Sales',
    'KB',
    'MetaProfile',
    'Customers',
    'Campaigns',
  ],
})
