import { api } from '../api'

interface StatisticsReturnedData {
  totalConversations: number
  totalNewConversations: number
  totalOpenConversations: number
  totalEngagedConversations: number
  totalSentMessages: number
  totalDeliveredMessages: number
  totalReadMessages: number
  totalFailedMessages: number
  totalTemplateMessages: number
  totalMetaAdMessages: number
  totalLeadsViaMetaAds: number
}

interface StatisticsArguments {
  whatsappAccount: string
  from?: string
  to?: string
}

interface AnalyticsReturnedData {
  start: string
  end: string
  sent: number
  delivered: number
}

interface AnalyticsArguments {
  whatsappAccount: string
  from?: string
  to?: string
  granularity: string
}

interface ResponseReportReturnedData {
  system: {
    avgFirstResponseTime: number
    avgOverallResponseTime: number
    missedMessages: number
  }
  agents: {
    id: string
    name: string
    avgFirstResponseTime: number
    avgOverallResponseTime: number
  }[]
}

interface ResponseReportArguments {
  whatsappAccount: string
  from?: string
  to?: string
}

interface CampingsReturnedData {
  createdAt: string
  succeeded: number
  failed: number
}

interface CampingsArguments {
  whatsappAccount: string
  from?: string
  to?: string
}

interface TemplatesAnalyticsReturnedData {
  template_id: string
  start: string
  end: string
  sent: number
  delivered: number
  read: number
  clicked?: [
    {
      type: string
      count: number
    },
  ]
  cost: [
    {
      type: 'amount_spent' | 'cost_per_delivered' | 'cost_per_url_button_click'
      value: number
    },
  ]
}

interface TemplateAnalyticsArguments {
  whatsappAccount: string
  from: string
  to: string
  template: number | string
}

export const whatsappStats = api.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get Whatsapp Statistics.
     */
    getStatistics: builder.query<StatisticsReturnedData, StatisticsArguments>({
      query: (filters) => {
        const searchParams = new URLSearchParams()

        searchParams.set('whatsappAccount', filters.whatsappAccount)
        if (filters.from) searchParams.set('from', filters.from)
        if (filters.to) searchParams.set('to', filters.to)
        const queryString = searchParams.toString()

        return `/whatsapp/whatsapp-statistics?${queryString}`
      },
      providesTags: ['WhatsappStats'],
    }),

    /**
     * Get Whatsapp Analytics.
     */
    getAnalytics: builder.query<AnalyticsReturnedData[], AnalyticsArguments>({
      query: (filters) => {
        const searchParams = new URLSearchParams()

        searchParams.set('whatsappAccount', filters.whatsappAccount)
        if (filters.from) searchParams.set('from', filters.from)
        if (filters.to) searchParams.set('to', filters.to)
        if (filters.granularity)
          searchParams.set('granularity', filters.granularity)

        const queryString = searchParams.toString()
        return `/whatsapp/whatsapp-analytics?${queryString}`
      },
      providesTags: ['WhatsappStats'],
    }),

    /**
     * Get Templates Analytics.
     */
    getTemplatesAnalytics: builder.query<
      TemplatesAnalyticsReturnedData[],
      TemplateAnalyticsArguments
    >({
      query: (filters) => {
        const searchParams = new URLSearchParams()

        searchParams.set('whatsappAccount', filters.whatsappAccount)
        searchParams.set('from', filters.from)
        searchParams.set('to', filters.to)
        searchParams.set('template', String(filters.template))

        const queryString = searchParams.toString()
        return `/whatsapp/template-analytics?${queryString}`
      },
      providesTags: ['WhatsappStats'],
    }),

    /**
     * Get Whatsapp Response Time Report.
     */
    getResponseTimeReport: builder.query<
      ResponseReportReturnedData,
      ResponseReportArguments
    >({
      query: (filters) => {
        const params = new URLSearchParams()

        params.set('whatsappAccount', filters.whatsappAccount)
        if (filters.from) params.set('from', filters.from)
        if (filters.to) params.set('to', filters.to)

        const queryString = params.toString()
        return `/whatsapp/responses-report?${queryString}`
      },
      providesTags: ['WhatsappStats'],
    }),

    /**
     * Get Whatsapp Campaigns.
     */
    getCampaignsStats: builder.query<CampingsReturnedData[], CampingsArguments>(
      {
        query: (filters) => {
          const searchParams = new URLSearchParams()

          searchParams.set('whatsappAccount', filters.whatsappAccount)
          if (filters.from) searchParams.set('from', filters.from)
          if (filters.to) searchParams.set('to', filters.to)
          const queryString = searchParams.toString()

          return `/whatsapp/campaigns-stats?${queryString}`
        },
        providesTags: ['WhatsappStats'],
      }
    ),

    /**
     * Get Business initiated Conversations count.
     */
    getBusinessInitiatedConversationsCount: builder.query<
      any,
      { accountId: string }
    >({
      query: ({ accountId }) => {
        return `/whatsapp/business-initiated-conversations-count?accountId=${accountId}`
      },
      providesTags: ['WhatsappStats', { type: 'Conversations' }],
    }),
  }),
})

export const {
  useGetStatisticsQuery,
  useGetAnalyticsQuery,
  useGetTemplatesAnalyticsQuery,
  useGetResponseTimeReportQuery,
  useGetCampaignsStatsQuery,
  useGetBusinessInitiatedConversationsCountQuery,
} = whatsappStats
