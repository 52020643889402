import { ChatMedia } from './bubble-messages/media'
import { ReferralMessage } from './bubble-messages/referral-message'
import { TemplateMessage } from './bubble-messages/template-message'
import { TextMessage } from './bubble-messages/text-message'

interface ChatListProps {
  accountId?: string
  content?: any
  type: string
  senderName?: string
  mediaId?: string
  filename?: string
  templateHeaderFormat?: string
  templateHeaderText?: string
  templateHeaderMediaId?: string
  templateBodyText?: string
  templateFooterText?: string
  templateButtonsTexts?: string[]
  carousel?: any[]
  referral?: any
  timestamp?: any
  created_time?: string
  mediaURL?: string
  fetchMedia?: boolean
}

export default function Bubble({
  accountId,
  content,
  type,
  senderName,
  mediaId,
  filename,
  templateHeaderFormat,
  templateHeaderText,
  templateHeaderMediaId,
  templateBodyText,
  templateFooterText,
  templateButtonsTexts,
  carousel,
  referral,
  timestamp,
  created_time,
  mediaURL,
  fetchMedia,
}: ChatListProps) {
  return (
    <>
      {type === 'TEXT' && (
        <TextMessage
          senderName={senderName}
          content={content}
          timestamp={timestamp}
          created_time={created_time}
        />
      )}
      {mediaId && fetchMedia && (
        <ChatMedia
          accountId={accountId!}
          type={type}
          mediaId={mediaId}
          content={content}
          filename={filename}
          senderName={senderName}
          timestamp={timestamp}
        />
      )}
      {type === 'TEMPLATE' && fetchMedia && (
        <TemplateMessage
          accountId={accountId!}
          senderName={senderName}
          templateHeaderFormat={templateHeaderFormat}
          templateHeaderText={templateHeaderText}
          templateBodyText={templateBodyText}
          templateButtonsTexts={templateButtonsTexts}
          templateFooterText={templateFooterText}
          templateHeaderMediaId={templateHeaderMediaId}
          carousel={carousel}
          timestamp={timestamp}
        />
      )}

      {type === 'REFERRAL' && (
        <ReferralMessage
          referral={referral}
          content={content}
          timestamp={timestamp}
        />
      )}

      {mediaURL && (
        <div>
          {senderName && (
            <span
              className={`font-bold text-[11px] text-gray-500 block max-md:max-w-[14rem]`}
            >
              {senderName}
            </span>
          )}
          <img
            className={`w-80 my-2 max-md:max-w-[14rem]`}
            src={mediaURL}
            alt="Image"
          />
        </div>
      )}
    </>
  )
}
