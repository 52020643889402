import {
  useCreateAnswerMutation,
  useUpdateAnswerMutation,
  useDeleteAnswerMutation,
  useGetAnswersGroupedQuery,
} from 'app/features/kb'
import { useToast } from 'components/ui/use-toast'
import { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import { Button } from 'components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { TbEdit, TbPdf, TbWindowMaximize } from 'react-icons/tb'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { isURL } from 'lib/validation'
import axios from '../../lib/axios'
import dayjs from 'lib/dayjs'
import ViewAnswer from './view-answer'

type GroupedAnswersResponse = {
  category: KBCategory
  categoryId: string
  answers: KBAnswer[]
}[]

export default function KnowledgeBank() {
  const { toast } = useToast()
  const [loadingPDF, setLoadingPDF] = useState(false)

  const [groupedAnswers, setGroupedAnswers] = useState<GroupedAnswersResponse>(
    []
  )
  const [viewDialogO, setViewDialog] = useState<{
    answer: KBAnswer | null
    open: boolean
  }>({
    answer: null,
    open: false,
  })

  const {
    data: answersData,
    isLoading,
    isError: isErrorLoading,
  } = useGetAnswersGroupedQuery()

  const [
    createAnswer,
    { isSuccess: isCategoryCreated, isError: isCreateError },
  ] = useCreateAnswerMutation()

  const [
    updateAnswer,
    { isSuccess: isCategoryUpdated, isError: isUpdateError },
  ] = useUpdateAnswerMutation()

  const [
    deleteAnswer,
    { isSuccess: isCategoryDeleted, isError: isDeleteError },
  ] = useDeleteAnswerMutation()

  /**
   * Updates the list of categories when the structure data is received.
   */
  useEffect(() => {
    if (answersData) {
      setGroupedAnswers(answersData)
    }
  }, [answersData])

  /**
   * Displays an error message if there was an error saving the structure.
   */
  useEffect(() => {
    if (isCreateError) {
      toast({
        title: 'Error occurred',
        description: isCreateError,
      })
    }
  }, [isCreateError, isErrorLoading])

  const defaultImage = '/logo.jpeg'

  /**
   * Generates a PDF and download it
   * @returns {Promise<void>}
   */
  const requestPDF = async (selectedLead: any) => {
    if (loadingPDF) return

    setLoadingPDF(true)

    const { dismiss } = toast({
      title: 'Generating PDF',
      description:
        'Please wait, PDF is being generated. This may take a few seconds.',
      duration: 30 * 1000,
    })

    try {
      const res = await axios.get('/kb/print', {
        params: {},
        responseType: 'blob',
      })

      downloadPDF(res.data)

      toast({
        title: 'Success',
        description: 'PDF generated successfully',
      })

      dismiss()
    } catch (error) {
      toast({
        title: 'Error occurred',
        description: 'An error occurred while generating dashboard report',
      })
    }

    setLoadingPDF(false)
  }

  /**
   * Downloads the given PDF data as a file
   * @param {any} data The PDF data to download
   */
  function downloadPDF(data: string) {
    const pdfBlob = new Blob([data], { type: 'application/pdf' })

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(pdfBlob)
    link.href = url
    link.download = `Doctorna - Knowledge Bank ${dayjs().format('YYYYMMDD HH:mm')}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <div className="flex flex-col p-6">
        <div className="flex justify-between mb-6">
          <div>
            <h1 className="text-lg font-medium">Knowledge Bank</h1>
            <p className="text-xs text-gray-500">
              View knowledge bank answers. Tabs represent different categories.
              Each category can have multiple cards of answers.
            </p>
          </div>
        </div>

        <div className="px-28">
          <div className="flex justify-end mb-4">
            <Button
              variant="outline"
              className="mr-2"
              onClick={requestPDF}
              disabled={loadingPDF}
            >
              <TbPdf className="mr-2" />
              Print as PDF
            </Button>
          </div>

          {groupedAnswers && groupedAnswers.length > 0 && !isLoading && (
            <Tabs
              defaultValue={
                groupedAnswers.length ? groupedAnswers[0].categoryId : ''
              }
            >
              <TabsList className="flex items-center justify-start flex-wrap h-auto space-y-1">
                {groupedAnswers?.map((el) => (
                  <TabsTrigger value={el.categoryId}>
                    {el.category.name}
                  </TabsTrigger>
                ))}
              </TabsList>
              {groupedAnswers?.map((group, index) => (
                <TabsContent
                  value={group.categoryId}
                  className="flex justify-between flex-wrap mt-2"
                >
                  {group.answers.map((answer) => (
                    <Card
                      className="mb-4 cursor-pointer"
                      onClick={() => {
                        setViewDialog({
                          answer: answer,
                          open: true,
                        })
                      }}
                    >
                      <CardHeader className="p-2">
                        <img
                          src={
                            isURL(answer.fields[0]?.value)
                              ? answer.fields[0].value
                              : defaultImage
                          }
                          style={{
                            width: 200,
                            height: 200,
                            objectFit: 'contain',
                          }}
                        />
                      </CardHeader>
                      <CardContent>
                        <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                          {answer.fields[1]?.value ?? ''}
                        </CardDescription>
                        <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                          {answer.fields[2]?.value ?? ''}
                        </CardDescription>
                        <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                          {answer.fields[3]?.value ?? ''}
                        </CardDescription>
                        <CardDescription className="h-6 w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
                          {answer.fields[4]?.value ?? ''}
                        </CardDescription>
                      </CardContent>
                    </Card>
                  ))}

                  {!group.answers ||
                    (group.answers?.length === 0 && (
                      <div className="flex flex-col items-center justify-center w-full h-80">
                        <p className="text-center text-gray-400">
                          No data found.
                        </p>
                      </div>
                    ))}
                </TabsContent>
              ))}
            </Tabs>
          )}

          {(!groupedAnswers || groupedAnswers.length === 0) && !isLoading && (
            <div className="flex flex-col items-center justify-center h-80">
              <p className="text-center text-gray-400">No data found.</p>
            </div>
          )}

          <Dialog open={viewDialogO.open}>
            <DialogContent className="max-w-[80vw] h-[80vh]  [&>button]:hidden">
              <ViewAnswer
                answer={viewDialogO.answer}
                close={() => setViewDialog({ answer: null, open: false })}
              />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  )
}
