import { useGetMetaMessageQuery } from 'app/features/meta'
import { cn } from 'lib/utils'
import { Avatar, AvatarImage } from 'components/ui/avatar'
import Bubble from '../components/bubble'

interface ChatListProps {
  index: number
  message: {
    id: string
    senderId: string
    text: string
    type: string
    mediaURL: string
  }
  selectedUserName: string
  selectedUserId: string
  created_time: string
}

export function MessengerMessage({
  index,
  message,
  selectedUserName,
  selectedUserId,
  created_time,
}: ChatListProps) {
  return (
    <>
      {((message.text?.length ?? 0) > 0 || message.mediaURL) && (
        <div
          key={index}
          className={cn(
            'flex flex-col gap-2 p-4 px-0 whitespace-pre-wrap',
            message?.senderId !== selectedUserId ? 'items-end' : 'items-start'
          )}
        >
          <div className="flex gap-1 items-center">
            {message?.senderId === selectedUserId && (
              <Avatar className="flex justify-center items-center">
                <AvatarImage
                  src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                  alt="@shadcn"
                  width={6}
                  height={6}
                />
              </Avatar>
            )}
            <Bubble
              content={message.text}
              type={message.type}
              senderName={
                message?.senderId === selectedUserId
                  ? selectedUserName
                  : 'Codeguru'
              }
              created_time={created_time}
              mediaURL={message.mediaURL}
            />
            {message.senderId !== selectedUserId && (
              <Avatar className="flex justify-center items-center">
                <AvatarImage
                  src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                  alt="@shadcn"
                  width={6}
                  height={6}
                />
              </Avatar>
            )}
          </div>
        </div>
      )}
    </>
  )
}
