import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import dayjs, { Dayjs } from './dayjs'
import { lowerCase, upperFirst } from 'lodash'

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isExist(value: any) {
  if (!value) return false
  if (typeof value === 'object' && value.length > 0) {
    return (
      value.filter(
        (item: any) => item === undefined || item === null || item === ''
      ).length === 0
    )
  }
  return value !== undefined || value !== null || value !== ''
}

export function convertTimeGap(value: any, type: any): number {
  if (type === 'MINUTE') return value * 60
  if (type === 'HOUR') return value * 60 * 60
  if (type === 'DAY') return value * 24 * 60 * 60
  if (type === 'WEEK') return value * 24 * 7 * 60 * 60
  else return 0
}

export function reconstructTimeGap(seconds: number) {
  const totalMinutes = Math.floor(seconds / 60)
  const hours = Math.floor(totalMinutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)

  const remainingMinutes = totalMinutes % 60
  const remainingHours = hours % 24
  const remainingDays = days % 7
  return `Weeks: ${weeks}, Days: ${remainingDays}, Hours: ${remainingHours}, Minutes: ${remainingMinutes}`
}

export function getStartDateAndEndDate(
  selectedDate: string
): { startDate: Dayjs; endDate: Dayjs } | null {
  if (selectedDate === 'Last 30 days') {
    const endDate = dayjs()
    const startDate = endDate.subtract(29, 'day')
    return { startDate, endDate }
  } else if (selectedDate === 'Last month') {
    const startDate = dayjs().subtract(1, 'month').startOf('month')
    const endDate = dayjs().subtract(1, 'month').endOf('month')
    return { startDate, endDate }
  } else if (selectedDate === 'This week') {
    const startDate = dayjs().startOf('week')
    const endDate = dayjs().endOf('week')
    return { startDate, endDate }
  } else if (selectedDate === 'Last week') {
    const startDate = dayjs().subtract(1, 'week').startOf('week')
    const endDate = dayjs().subtract(1, 'week').endOf('week')
    return { startDate, endDate }
  } else if (selectedDate === 'Yesterday') {
    const startDate = dayjs().subtract(1, 'day').startOf('day')
    const endDate = dayjs().subtract(1, 'day').endOf('day')
    return { startDate, endDate }
  } else if (selectedDate === 'Today') {
    const startDate = dayjs().startOf('day')
    const endDate = dayjs().endOf('day')
    return { startDate, endDate }
  } else return null
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/
  if (!phoneNumberRegex.test(phoneNumber)) {
    return false
  }
  return true
}

export const cleanPhoneNumber = (phoneNumber: string) => {
  // Remove any non-digit characters
  let cleaned = ('' + phoneNumber).replace(/\D/g, '')
  if (cleaned.length == 0 || cleaned.length < 8) return null

  if (
    (cleaned.startsWith('971') || cleaned.startsWith('966')) &&
    cleaned.length < 11
  )
    return null
  if (
    (cleaned.startsWith('00971') || cleaned.startsWith('00966')) &&
    cleaned.length < 13
  )
    return null

  cleaned = ('' + phoneNumber).replace(/\D/g, '')
  if (cleaned.length <= 10) {
    if (cleaned.charAt(0) == '0') cleaned = cleaned.substring(1)
    cleaned = '971' + cleaned
  }

  if (cleaned.startsWith('00971') || cleaned.startsWith('00966'))
    cleaned = cleaned.substring(2)

  return cleaned
}

export const validateName = (name: string) => {
  // Check if the name contains only letters and spaces
  const nameRegex = /^[a-zA-Z\s]+$/
  if (!nameRegex.test(name)) {
    return false
  }

  // Check if the name has at least one letter
  if (!/[a-zA-Z]/.test(name)) {
    return false
  }

  return true
}

export const formatPrice = (price: any) => {
  const formatter = new Intl.NumberFormat('en-AE', {
    style: 'currency',
    currency: 'aed',
    currencySign: 'accounting',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formatter.format(price)
}

export const formatDate = (
  date: any,
  displayDay: boolean = false,
  displayTime: boolean = false
) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzDate = dayjs(date).tz(tz)
  return tzDate.format(
    `${displayDay ? 'dddd, ' : ''}MMMM D, YYYY ${displayTime ? ' h:mm A ' : ''}`
  )
}

export const isDatePastThan = (date: any, minutes: number = 2) => {
  const currentTime = new Date()
  const pastMinutes = new Date(currentTime.getTime() - minutes * 60 * 1000)
  try {
    const dateInMilliseconds = new Date(date)

    return dateInMilliseconds < pastMinutes
  } catch (error) {
    console.error('Error checking date:', error)
    return false
  }
}

export const isTimestampPastThan = (
  timestamp: string,
  minutes: number = 60 * 24
) => {
  const currentTime = new Date()
  const pastMinutes = new Date(currentTime.getTime() - minutes * 60 * 1000)
  const dateInMilliseconds = new Date(parseInt(timestamp))
  try {
    return dateInMilliseconds < pastMinutes
  } catch (error) {
    console.error('Error checking date:', error)
    return false
  }
}

export const prepareMediaFormData = (data: Record<string, any>) => {
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const containsSubstring = (source: string, target: string) => {
  const lowerSource = source.toLowerCase()
  const lowerTarget = target.toLowerCase()
  return lowerSource.includes(lowerTarget)
}

export const replacePlaceholdersByIndices = (str: string) => {
  if (!str) return ''
  let index = 1
  return str.replace(/{{.*?}}/g, () => `{{${index++}}}`)
}

export const extractPlaceholdersIntoArray = (str: string) => {
  if (!str) return []
  const matches = str.match(/{{(.*?)}}/g)
  return matches ? matches.map((match) => match.replace(/{{|}}/g, '')) : []
}

export const convertToCamelCase = (str: string) => {
  if (!str) return ''
  return str
    .toLowerCase()
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word
      }
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export const convertToTitleCase = (str: string) => {
  if (!str) return ''
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const convertCamelCaseToTitleCase = (str: string) => {
  if (!str) return ''
  const result = str.replace(/([A-Z])/g, ' $1').trim()
  return result
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const replaceAndConvertPlaceholders = (
  template: string,
  values: string[]
) => {
  if (!template) return ''
  const placeholders = template.match(/{{.*?}}/g)

  if (!placeholders) return template
  return placeholders.reduce((result, placeholder, index) => {
    return result.replace(placeholder, values[index] || '')
  }, template)
}

export const formatTime = (seconds: number) => {
  if (seconds == 0) return ''
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  return `${minutes} m ${remainingSeconds} s`
}

export const deepEqual = (obj1: any, obj2: any) => {
  if (obj1 === obj2) return true

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false
  }

  const isArray1 = Array.isArray(obj1)
  const isArray2 = Array.isArray(obj2)
  if (isArray1 !== isArray2) return false

  const keys1 = isArray1 ? obj1 : Object.keys(obj1)
  const keys2 = isArray2 ? obj2 : Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

/**
 * Formats a duration given in seconds to a human-readable format.
 *
 * If the duration is greater than an hour, it will be formatted in the
 * following format: 'h[h] m[m] s[s]', otherwise it will be formatted as
 * 'm[m] s[s]'. If the duration is NaN or null, an empty string will be
 * returned.
 *
 * @param number duration - Duration in seconds to be formatted.
 *
 * @returns Formatted duration string.
 */
export const formatDuration = (duration: any) => {
  let formattedDuration = ''
  if ([null, undefined].includes(duration)) {
    return ''
  }
  if (isNaN(duration)) {
    return duration
  }
  if (duration > 60 * 60 * 60) {
    formattedDuration = dayjs
      .duration(duration, 'seconds')
      .format('h[h] m[m] s[s]')
  } else {
    formattedDuration = dayjs.duration(duration, 'seconds').format('m[m] s[s]')
  }

  return formattedDuration
}

/**
 * Sentence case a string.
 * @param {string} s The string to sentence case.
 * @returns {string} The sentence cased string.
 */
export function sentenceCase(s: string): string {
  if (typeof s !== 'string') return ''
  if (s === '') return s

  return upperFirst(lowerCase(s))
}
