import { useInitAccountMutation } from 'app/features/whatsapp'
import { toast } from 'components/ui/use-toast'
import { useEffect } from 'react'

export const NewAccount = () => {
  const [initAccount] = useInitAccountMutation()
  useEffect(() => {
    const handleIframeMessage = (event: any) => {
      // TODO: to be added later
      // const allowedOrigin = new RegExp(
      //   '^https?://([a-z0-9-]+\\.)?doctorna\\.com$'
      // )
      // if (!allowedOrigin.test(event.origin)) return
      if (event?.data?.type == 'success') {
        initAccount({ code: event.data.code })
      } else if (event?.data?.type == 'error') {
        toast({
          title: 'Error occurred',
          description: event?.data?.error,
        })
      }
    }

    window.addEventListener('message', handleIframeMessage)

    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  return (
    <div className=" ml-auto  w-full p-4 pt-10 relative  ">
      <h1 className="text-sm font-medium ">Add new account</h1>
      <p className="text-xs text-gray-500">Add a new whatsapp account</p>
      <iframe
        src={process.env.REACT_APP_WHATSAPP_LOGIN_DOMAIN}
        style={{
          height: '300px',
          border: 'none',
          width: '100%',
        }}
        title="Whatsapp Login"
      />
    </div>
  )
}
