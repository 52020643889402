import { api } from '../api'

export const offersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<
      { items: Offer[]; count: number },
      { skip?: number; take?: number; disabled?: boolean }
    >({
      query: (params: { skip?: number; take?: number; disabled?: boolean }) => {
        const searchParams = new URLSearchParams()

        if (params.skip !== undefined)
          searchParams.set('skip', params.skip.toString())
        if (params.take !== undefined)
          searchParams.set('take', params.take.toString())
        if (params.disabled !== undefined)
          searchParams.set('disabled', params.disabled.toString())

        const queryString = searchParams.toString()
        return `/offers?${queryString}`
      },
      providesTags: ['Offers'],
    }),
    getAllOffers: builder.query<any, void>({
      query: () => `/offers/all`,
      providesTags: ['Offers'],
    }),
    getOfferById: builder.query<Offer, string | undefined>({
      query: (id?: string) => (id ? `/offers/${id}` : ''),
      providesTags: ['Offers'],
    }),
    createOffer: builder.mutation({
      query: (data: {
        name: string
        description: string
        price: number
        sessionsNumber: number
      }) => ({
        url: `/offers`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Offers'],
    }),
    updateOffer: builder.mutation({
      query: (data: {
        id: string
        name?: string
        description?: string
        price?: number
        sessionsNumber?: number
        disabled?: boolean
      }) => ({
        url: `/offers/${data.id}`,
        method: 'PUT',
        body: {
          name: data.name,
          description: data.description,
          price: data.price,
          sessionsNumber: data.sessionsNumber,
          ...(data.disabled !== undefined && { disabled: data.disabled }),
        },
      }),
      invalidatesTags: ['Offers'],
    }),
    deleteOffer: builder.mutation({
      query: (id) => ({
        url: `/offers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Offers'],
    }),
  }),
})

export const {
  useGetOffersQuery,
  useGetOfferByIdQuery,
  useGetAllOffersQuery,
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useDeleteOfferMutation,
} = offersApi
